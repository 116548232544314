import {
  DateFormatTypes,
  formatDateByCountryCode,
  GarageService,
  type MovaFormField,
  SlotAlgorithm,
  type Garage,
} from '@movalib/movalib-commons';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { useState, type FunctionComponent } from 'react';
import { setSnackbar } from '../../../slices/snackbarSlice';
import { useDispatch } from 'react-redux';

interface MyAppointmentOnlineProps {
  refreshGarage: () => void;
  garage: Garage;
}
type Form = {
  slotAlgorithm: MovaFormField;
  amVehicleDeposit: { value?: Date | undefined; error?: string; isValid?: boolean };
  pmVehicleDeposit: { value?: Date | undefined; error?: string; isValid?: boolean };
  dayPeriodFastServiceExcluded: MovaFormField;
};
const AppointmentOnline: FunctionComponent<MyAppointmentOnlineProps> = ({ refreshGarage, garage }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const today = new Date().toISOString().substring(0, 10);
  const [error, setError] = useState<string | undefined>(undefined);
  const intialForm: Form = {
    slotAlgorithm: { value: garage.slotAlgorithm, isValid: true },
    amVehicleDeposit: {
      value: garage.zonedAmVehicleDeposit
        ? new Date(`${today}T${garage.zonedAmVehicleDeposit ?? '08:00:00'}`)
        : undefined,
      isValid: true,
    },
    pmVehicleDeposit: {
      value: garage.zonedPmVehicleDeposit
        ? new Date(`${today}T${garage.zonedPmVehicleDeposit ?? '14:00:00'}`)
        : undefined,
      isValid: true,
    },
    dayPeriodFastServiceExcluded: { value: garage.dayPeriodFastServiceExcluded, isValid: true },
  };

  const [form, setForm] = useState<Form>(intialForm);
  function updateFormField(field: string, value: any, additionalUpdates: Record<string, any> = {}) {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: {
        value,
        isValid: true,
      },
      ...additionalUpdates,
    }));
  }

  function buildRequest(overrides = {}) {
    return {
      slotAlgorithm: form.slotAlgorithm.value,
      pmVehicleDeposit: formatDateByCountryCode(form.pmVehicleDeposit?.value, 'FR', DateFormatTypes.TIME_ONLY),
      amVehicleDeposit: formatDateByCountryCode(form.amVehicleDeposit?.value, 'FR', DateFormatTypes.TIME_ONLY),
      dayPeriodFastServiceExcluded: form.dayPeriodFastServiceExcluded.value,
      ...overrides,
    };
  }

  function patchApi(req: any) {
    GarageService.updateSlotAlogrithm(garage.id, req)
      .then((response) => {
        if (response.success) {
          dispatch(setSnackbar({ open: true, message: 'Modifications enregistrées', severity: 'success' }));
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: 'Modifications des données impossible',
              severity: 'error',
            }),
          );
        }
      })
      .catch(() => {
        dispatch(
          setSnackbar({
            open: true,
            message: 'Modifications des données impossible',
            severity: 'error',
          }),
        );
      });
  }

  function handleSlotAlgorithmChange(e: any) {
    const slotAlgorithmValue = e.target.value;
    updateFormField('slotAlgorithm', slotAlgorithmValue);

    const req =
      slotAlgorithmValue === SlotAlgorithm.HOUR
        ? { slotAlgorithm: slotAlgorithmValue }
        : buildRequest({ slotAlgorithm: slotAlgorithmValue });

    patchApi(req);
  }

  function handleDepositTimeChange(field: string, value: Date) {
    const formattedValue = formatDateByCountryCode(value, 'FR', DateFormatTypes.TIME_ONLY);
    // Récupérer les heures existantes
    const amTime = form.amVehicleDeposit.value;
    const pmTime = form.pmVehicleDeposit.value;

    if (field === 'amVehicleDeposit' && pmTime && value > pmTime) {
      setError("L'heure du matin ne peut pas être après celle de l’après-midi !");
      return;
    } else if (field === 'pmVehicleDeposit' && amTime && value < amTime) {
      setError("L'heure de l’après-midi ne peut pas être avant celle du matin !");
      return;
    }
    setError(undefined);
    const updates = {} as any;
    updates[field] = { value, isValid: true } as any;
    updateFormField(field, value);

    const req = buildRequest({
      [field]: formattedValue,
    });

    patchApi(req);
  }

  function handleChangeDayPeriodFastServiceExcluded(checked: boolean) {
    updateFormField('dayPeriodFastServiceExcluded', checked);

    const req = buildRequest({ dayPeriodFastServiceExcluded: checked });
    patchApi(req);
  }

  return (
    <>
      <Grid container columnSpacing={4}>
        <Grid item xs={12}>
          <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mb: 1 }}>
            <b>Mode de prise de rendez-vous</b>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl fullWidth margin='normal' required size='small' sx={{ maxWidth: '500px' }}>
            <InputLabel id='slot-algorithm-label'>CHOIX DES CRÉNEAUX</InputLabel>
            <Select
              labelId='slot-algorithm-label'
              id='slotAlgorithm'
              name='slotAlgorithm'
              size='small'
              label='Détermination des créneaux'
              value={form.slotAlgorithm.value}
              onChange={(e) => handleSlotAlgorithmChange(e)}
            >
              <MenuItem value={SlotAlgorithm.DAY_PERIOD}>
                Le client dépose son véhicule le matin et/ou l'après-midi
              </MenuItem>
              <MenuItem value={SlotAlgorithm.HOUR}>Le client choisit l'heure précise</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {form && form.slotAlgorithm.value === SlotAlgorithm.DAY_PERIOD && (
          <Grid container columnSpacing={4} sx={{ pl: 4 }}>
            <Grid item xs={3}>
              <Typography variant='subtitle1' color={theme.palette.text.secondary} sx={{ mt: 1, mb: 1 }}>
                <b>Heure de dépot matin :</b>
              </Typography>
              <TimePicker
                views={['hours', 'minutes']}
                minutesStep={30} // Valeur par défaut de 30 minutes
                value={form.amVehicleDeposit.value}
                formatDensity='dense'
                onChange={(value, context) => handleDepositTimeChange('amVehicleDeposit', value as Date)}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      width: '150px',
                      minWidth: '100px',
                      padding: 0,
                    },
                  },
                  actionBar: {
                    sx: { display: 'none' },
                  },
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography variant='subtitle1' color={theme.palette.text.secondary} sx={{ mt: 1, mb: 1 }}>
                <b>Heure de dépot après-midi :</b>
              </Typography>
              <TimePicker
                views={['hours', 'minutes']}
                minutesStep={30} // Valeur par défaut de 30 minutes
                value={form.pmVehicleDeposit.value}
                formatDensity='dense'
                onChange={(value, context) => handleDepositTimeChange('pmVehicleDeposit', value as Date)}
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      width: '150px',
                      minWidth: '100px',
                      padding: 0,
                    },
                  },
                  actionBar: {
                    sx: { display: 'none' },
                  },
                }}
              />
            </Grid>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'start',
                //width: '300px',
                pl: 4,
              }}
            >
              {error && (
                <Typography variant='body1' color='error'>
                  {error}
                </Typography>
              )}
              <Typography
                variant='h6'
                color={
                  form.dayPeriodFastServiceExcluded.value ? theme.palette.primary.dark : theme.palette.text.secondary
                }
                sx={{ mb: 1, mt: 2 }}
              >
                <Switch
                  checked={form.dayPeriodFastServiceExcluded.value}
                  onChange={(e) => handleChangeDayPeriodFastServiceExcluded(e.target.checked)}
                  color='primary'
                />
                <b>Exclure les services rapides</b>
              </Typography>
              <Typography variant='body1' color={theme.palette.text.secondary} sx={{ mb: 1 }}>
                Activez cette option pour permettre à vos clients de prendre rendez-vous à l'heure précise pour les
                services rapides.
              </Typography>
              <Typography variant='body1' color={theme.palette.text.secondary}>
                Vous pouvez configurer la durée maximale d'un service rapide dans la section "Mes services"
                (actuellement : <b>{garage.fastServiceThreshold}</b> heure{garage.fastServiceThreshold > 1 ? 's' : ''}).
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default AppointmentOnline;
