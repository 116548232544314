import { memo, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import { Grid, Typography, TextField, Button, Tooltip, Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
  DocumentType,
  ScheduleFields,
  AddressFields,
  GarageService,
  Logger,
  Schedule,
  type DaySchedule,
  type Document,
  type Garage,
} from '@movalib/movalib-commons';
import { format } from 'date-fns';
import { setSnackbar } from '../../../slices/snackbarSlice';
import ImageUpload from '../../../components/ImageUpload';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import type { FormField } from '../../../helpers/Constants';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CustomStyleAppDialog from './CustomStyleAppDialog';
import theme from '../../../theme';
type Form = {
  streetNumber: FormField;
  streetName: FormField;
  additional: FormField;
  postalCode: FormField;
  cityName: FormField;
};
const initialFormState = {
  streetNumber: { value: '', isValid: true },
  streetName: { value: '', isValid: true },
  additional: { value: '', isValid: true },
  postalCode: { value: '', isValid: true },
  cityName: { value: '', isValid: true },
};
interface MyGarageProps {
  saveSetting: (r: any) => void;
  garage: Garage;
  refreshGarage: () => void;
}
const MemoizedScheduleFields = memo(ScheduleFields, (prevProps, nextProps) => {
  return prevProps.schedules === nextProps.schedules; // Re-render seulement si schedules change
});
const MyGarage: FunctionComponent<MyGarageProps> = ({ saveSetting, garage, refreshGarage }) => {
  const dispatch = useDispatch();
  const [formAddress, setFormAddress] = useState<Form>(initialFormState);
  const form = useFormik({
    initialValues: {
      name: garage?.name || '',
      workforce: garage?.workforce || '',
      schedules: garage?.schedules || '',
      contactPhone: garage?.contactPhone || '',
      contactEmail: garage?.contactEmail || '',
    },
    validateOnChange: false,
    enableReinitialize: true, // Mise à jour si les valeurs de `garage` changent
    onSubmit: (values) => {
      saveSetting(values);
    },
  });
  const [garageLogo, setGarageLogo] = useState<Document | undefined>(undefined);
  const [openDialogStyle, setOpenDialogStyle] = useState<boolean>(false);

  useEffect(() => {
    if (garage) {
      setFormAddress((prevForm) => ({
        ...prevForm,
        streetName: { ...prevForm['streetName'], value: garage.address.streetName },
        additional: { ...prevForm['additional'], value: garage.address.additional },
        postalCode: { ...prevForm['postalCode'], value: garage.address.postalCode },
        cityName: { ...prevForm['cityName'], value: garage.address.cityName },
      }));

      // récupération du logo
      setGarageLogo(garage.documents?.filter((doc) => doc.type === DocumentType.GARAGE_LOGO)[0]);
    }
  }, [garage]); // Exécute le hook à chaque fois que le garage change

  const memoizedSchedules = useMemo(() => form.values.schedules, [form.values.schedules]);

  const handleScheduleChange = (schedules: DaySchedule[]) => {
    if (schedules) {
      // On contrôle l'absence d'erreur dans le tableau de schedule
      const hasErrors = schedules.some((day) => day.intervals.some((interval) => interval.error !== null));

      Logger.info(schedules);

      if (!hasErrors) {
        // On crée un objet Schedule sur la base du DaySchedule reçu de ScheduleFields
        const newSchedules = new Array<Schedule>();
        schedules.forEach((s) => {
          // Seuls les journées actives (cochées) sont envoyées
          if (s.checked) {
            newSchedules.push(
              new Schedule(
                s.day,
                s.intervals.map(({ startTime, endTime, countryCode }) => ({
                  startTime: startTime ? format(new Date(startTime), 'HH:mm') : null,
                  endTime: endTime ? format(new Date(endTime), 'HH:mm') : null,
                  countryCode,
                })),
                true,
              ),
            );
          }
        });

        const request = {
          schedules: newSchedules,
        };

        saveSetting(request);
      }
    }
  };
  const handleUploadPhoto = (file: File) => {
    if (file) {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);

        uploadLogo(formData);
      }
    }
  };

  const uploadLogo = (request: FormData) => {
    if (garage) {
      GarageService.uploadLogo(garage.id, request).then((response) => {
        if (response.success) {
          dispatch(
            setSnackbar({ open: true, message: response.data ?? 'Modification enregistrée', severity: 'success' }),
          );
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Modification du logo impossible',
              severity: 'error',
            }),
          );
        }
      });
    }
  };

  const handleAddressChange = (fieldName: keyof Form, newValue: any, save?: boolean) => {
    setFormAddress((prevForm) => ({
      ...prevForm,
      [fieldName]: { ...prevForm[fieldName], value: newValue },
    }));
    // On renvoit l'ensemble de l'adresse
    if (save) {
      const request = {
        address: {
          streetName: fieldName === 'streetName' ? newValue : formAddress.streetName.value,
          additional: fieldName === 'additional' ? newValue : formAddress.additional.value,
          postalCode: fieldName === 'postalCode' ? newValue : formAddress.postalCode.value,
          cityName: fieldName === 'cityName' ? newValue : formAddress.cityName.value,
        },
      };

      saveSetting(request);
    }
  };

  return (
    <Grid container sx={{ pb: 2 }}>
      <Grid item xs={12} sx={{ mr: 2 }}>
        <Alert severity='success' sx={{ mb: 2, borderRadius: 20 }} icon={<InfoIcon />} className='styled-alert'>
          Ces informations sont visibles par vos clients depuis l'application Movalib
        </Alert>
      </Grid>

      <Grid item xs={6}>
        <Typography variant='h6' color='text.secondary'>
          <b>Description</b>
        </Typography>
        <TextField
          id='settings-garage-name'
          label='NOM DU GARAGE'
          name='name'
          value={form.values.name}
          required
          size='small'
          onBlur={(e) => form.submitForm()}
          onChange={(e) => form.setFieldValue('name', e.target.value)}
          sx={{ width: '98%', mt: 2.5 }}
        />
        <Typography variant='h6' color='text.secondary' sx={{ mt: 2 }}>
          <b>Adresse</b>
        </Typography>
        <AddressFields
          form={formAddress}
          size='small'
          handleChange={(fieldName, newValue, save) => handleAddressChange(fieldName, newValue, save)}
        />
      </Grid>

      <Grid
        item
        xs={6}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
      >
        {garageLogo ? (
          <Tooltip title='Modifier votre logo'>
            <Button
              onClick={() => handleUploadPhoto}
              component='label'
              sx={{ border: '1px solid grey', width: '300px', height: '300px', m: 2 }}
            >
              <img src={garageLogo.fileSignedUrl} alt='Logo du Garage' style={{ width: '250px', height: '250px' }} />
              <input type='file' accept='image/*' hidden onChange={(e) => handleUploadPhoto(e.target.files?.[0]!)} />
            </Button>
          </Tooltip>
        ) : (
          <ImageUpload onUpload={handleUploadPhoto} title='AJOUTER VOTRE LOGO' />
        )}
        <Alert severity='success' icon={<InfoIcon />} sx={{ borderRadius: 20, mt: 2 }} className='styled-alert'>
          Conseil pour un rendu optimal : une image carrée et pas trop lourde (moins de 1 Mo).
        </Alert>
        <Button
          startIcon={<AutoAwesomeIcon />}
          variant='outlined'
          sx={{ mt: 2, color: theme.palette.primary.dark }}
          onClick={() => setOpenDialogStyle(true)}
        >
          Personnaliser le style de l'application Movalib
        </Button>
      </Grid>

      <Grid item xs={12} sx={{ mt: 0 }}>
        <Typography variant='h6' color='text.secondary' sx={{ mt: 2, mb: 3 }}>
          <b>Contact</b>
        </Typography>
        <Grid container>
          <Grid item xs={3} sx={{ mb: 2 }}>
            <TextField
              id='settings-garage-contact-email'
              label='EMAIL'
              name='contactEmail'
              value={form.values.contactEmail}
              required
              size='small'
              sx={{ width: '90%' }}
              onBlur={(e) => form.submitForm()}
              onChange={(e) => form.setFieldValue('contactEmail', e.target.value)}
            />
          </Grid>
          <Grid item xs={9} sx={{ float: 'left' }}>
            <TextField
              id='settings-garage-contact-phone'
              label='N° DE TÉLÉPHONE'
              name='contactPhone'
              value={form.values.contactPhone}
              required
              size='small'
              onBlur={(e) => form.submitForm()}
              onChange={(e) => form.setFieldValue('contactPhone', e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Typography variant='h6' color='text.secondary' sx={{ mt: 2, mb: 3 }}>
          <b>Horaires d'ouverture</b>
        </Typography>
        <MemoizedScheduleFields
          schedules={memoizedSchedules}
          size='small'
          onChange={handleScheduleChange}
          timePickerStep={30}
        />
      </Grid>
      {openDialogStyle && (
        <CustomStyleAppDialog
          saveSetting={saveSetting}
          open={openDialogStyle}
          onClose={() => setOpenDialogStyle(false)}
          garage={garage}
        />
      )}
    </Grid>
  );
};

export default MyGarage;
