import {
  type CategoryPrestation,
  type Customer,
  CustomerType,
  DateFormatTypes,
  Document,
  DocumentState,
  DocumentType,
  Event,
  EventState,
  EventType,
  type Garage,
  Logger,
  MovaAppType,
  type MovaFormField,
  type MovaVehicleForm,
  OrderPreference,
  OrderState,
  type PartsApplicationType,
  type Prestation,
  type Product,
  ProductType,
  SlotAlgorithm,
  type Supplier,
  type User,
  VehicleFullCard,
  VehicleGarage,
  type VehicleTire,
  formatDateByCountryCode,
  formatPhoneNumber,
  StyledToggleButton,
  StyledToggleButtonGroup,
  type Vehicle,
} from '@movalib/movalib-commons';
import EventScheduleIcon from '@mui/icons-material/AccessTimeFilledRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import PrestationsIcon from '@mui/icons-material/CategoryRounded';
import CheckIcon from '@mui/icons-material/CheckRounded';
import EditIcon from '@mui/icons-material/EditOutlined';
import InvoiceIcon from '@mui/icons-material/Euro';
import SupplierQuoteRequestIcon from '@mui/icons-material/HelpRounded';
import OperationsIcon from '@mui/icons-material/ListAlt';
import NotesIcon from '@mui/icons-material/NotesTwoTone';
import CustomerAddIcon from '@mui/icons-material/PersonAddRounded';
import CustomerIcon from '@mui/icons-material/PersonRounded';
import CustomerSearchIcon from '@mui/icons-material/PersonSearchRounded';
import SupplierOrderDownloadRequestIcon from '@mui/icons-material/DownloadRounded';
import SupplierOrderRequestIcon from '@mui/icons-material/SendRounded';
import QuoteIcon from '@mui/icons-material/RequestPageRounded';
import SmsIcon from '@mui/icons-material/Sms';
import PrivateIcon from '@mui/icons-material/VisibilityOffRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  type CheckboxProps,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  type TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
  darken,
  debounce,
  lighten,
  useMediaQuery,
} from '@mui/material';
import type React from 'react';
import { Fragment, type FunctionComponent, type ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddVehicleDialog from '../../dialogs/customers/vehicle/AddVehicleDialog';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import UploadDialog from '../../dialogs/UploadDialog';
import {
  colors,
  DEFAULT_EVENT_TIMEPICKER_STEP,
  DEFAULT_EVENT_TYPE,
  PALETTE_THIRD_COLOR_MAIN,
} from '../../helpers/Constants';
import { ButtonType, PrestationCode, SupplierRequestType, VehicleDepositPreference } from '../../helpers/Enums';
import { StyledGroupHeader, StyledGroupItems, StyledEventTypeToggleButtonGroup } from '../../helpers/Styled';
import {
  applicationChoiceRequired,
  base64ToUtf8,
  cleanString,
  flexCenterCol,
  flexCenterRow,
  flexEnd,
  flexLeftRow,
  flexStart,
  formatEurosCurrency,
  formatLocalDateToISOString,
  getCustomerFirstLetter,
  getEventStateColor,
  getEventStateLabel,
  getEventVehicleDepositDate,
  getFormatedEventDate,
  getProductOrderState,
  getVehicleDepositPreferenceFromEvent,
  isActiveEvent,
  isInvalidEmail,
  isInvalidMobileNumber,
  isValidBase64,
  modulesQuill,
  truncateText,
} from '../../helpers/Tools';
import { validateText } from '../../helpers/Validator';
import type Guest from '../../models/Guest';
import CustomerService from '../../services/CustomerService';
import GarageService from '../../services/GarageService';
import VehicleService from '../../services/VehicleService';
import { setSnackbar } from '../../slices/snackbarSlice';
import type { AppDispatch, RootState } from '../../store';
import theme from '../../theme';
import DateTimePickerGroup from '../DateTimePickerGroup';
import MyLoadingButton from '../MyLoadingButton';
import { MyEventDialog, NotificationType } from './MyCalendarTypes';

import { GarageService as CoreGarageService, VehicleService as CoreVehicleService } from '@movalib/movalib-commons';
import {
  flexCenter,
  formatFrenchVehiclePlate,
  formatVehicleTire,
  getApplicationShortLabel,
  validateField,
} from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { AttachFile, Attachment, Euro, FileDownload, Square, Title } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/MobileFriendly';
import PhoneIcon from '@mui/icons-material/Phone';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import OverbookedIcon from '@mui/icons-material/Speed';
import PrevIcon from '@mui/icons-material/Undo';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers';
import { usePDF } from '@react-pdf/renderer';
import { format, isBefore } from 'date-fns';
import { isEqual } from 'lodash';
import TirePicture from '../../assets/images/flanc_pneu.png';
import AddOrEditSupplierDialog from '../../dialogs/AddOrEditSupplierDialog';
import { EventPrestationListDialog } from '../../dialogs/calendar/EventPrestationListDialog';
import { AddCustomer } from '../../dialogs/customers/AddCustomer';
import type { PrestationRequest } from '../../helpers/Types';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import { useAcceptQuoteEvent } from '../../query/event/EventQuery';
import { RepairOrder } from '../pdf/RepairOrder';
import EmployeeChoice from './EmployeeChoice';
import { CustomerDialog } from '../../dialogs/customers/CustomerDialog';
import { BusinessCustomerDialog } from '../../dialogs/customers/BusinessCustomerDialog';
import BusinessIcon from '@mui/icons-material/Business';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DeployedCodeHistory from '../../assets/images/icons/deployed_code_history.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GarageIcon from '@mui/icons-material/Garage';
import CustomTextarea from '../CustomTextArea';
import { ModifyDropOffVehicle } from './dialog/ModifyDropOffVehicle';
import PickerPrestation from './dialog/calendarEvent/PickerPrestation';
import ColorPicker from './dialog/calendarEvent/ColorPicker';
import { UploadDocumentDialog } from './dialog/UploadDocumentDialog';
import ListChipDocumentEvent from './dialog/ListChipDocumentEvent';
import CarRentalRoundedIcon from '@mui/icons-material/CarRentalRounded';
import ConflictLoanVehicleDialog from '../../dialogs/calendar/ConflictLoanVehicleDialog';
import PurchaseOrder from './dialog/calendarEvent/PurchaseOrder';
import generatePDF, { usePDF as usePDFReact } from 'react-to-pdf';
import { fr } from 'date-fns/locale';
import DOMPurify from 'dompurify';

const importIcon = (iconName: string): string => {
  try {
    const lowerCaseIconName = iconName.toLowerCase();
    return require(`../../assets/images/icons/${lowerCaseIconName}.png`);
  } catch (_error) {
    //console.error(`Icon not found: ${iconName}.png`);
    return ''; // or a path to a default icon
  }
};

export function isLoanVehicleAvailable(
  startDate: Date,
  endDate: Date,
  loanVehicleByDate: { [date: string]: VehicleGarage[] },
  vehicleId: number,
): boolean {
  const getDateRange = (start: Date, end: Date): string[] => {
    const dateRange: string[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  };

  const dateRange = getDateRange(startDate, endDate);

  for (const date of dateRange) {
    const reservedVehicles = loanVehicleByDate[date] || [];
    const filtered = reservedVehicles.filter((v: any) => v?.id === vehicleId);
    if (filtered.length > 0) {
      return false;
    }
  }
  return true;
}

interface SupplierRequestOthersDTO {
  type: ProductType;
  product: string;
}

export type EventForm = {
  // Attributs propres à l'event
  type: MovaFormField;
  start: MovaFormField;
  end: MovaFormField;
  title: MovaFormField;
  prestations?: MovaFormField;
  customer?: MovaFormField;
  vehicleId?: MovaFormField;
  vehiclePlate?: MovaFormField;
  notes?: MovaFormField;
  quoteId?: MovaFormField;
  garageVehicleId?: MovaFormField;
  // Préférence de commande (ou devis)
  orderPreference?: MovaFormField;
  // Grossiste destinataire
  supplier?: MovaFormField;
  // Produits sélectionnés pour transmission grossiste
  checkedProducts?: MovaFormField;
  // Commentaire éventuel à transmettre au grossiste
  comment?: MovaFormField;
  // Eventuel montant du devis
  quoteAmount?: MovaFormField;
  // Eventuel devis
  quote?: MovaFormField;
  // Surcharge du planning demandée
  overbooked?: MovaFormField;
  updateSchedulingAlert?: MovaFormField;
  // Eventuel message à adresser au client en cas de modification de planification
  updateSchedulingCustomerMessage?: MovaFormField;
  vehicleDepositPreference?: MovaFormField;
  vehicleDepositDate?: MovaFormField;
  // Si on a besoin d'ajouter des pièces/consommables supplémentaires
  others?: MovaFormField;
  submitEventAlert?: MovaFormField;
  isVehicleReceived?: MovaFormField;
  employee?: MovaFormField;
  // Lorsque la prestation Autres est sélectionnée, alors on précise la prestation avec otherReason
  otherReason?: MovaFormField;
};

export type MyCalendarEventDialogProps = {
  open: boolean;
  event: Event;
  garage: Garage;
  garageId: string;
  onClose: (refresh: boolean) => void;
  onRefresh?: () => void;
  type?: MyEventDialog;
  loanVehicleByDate: { [date: string]: VehicleGarage[] };
};

const initialFormState = {
  type: { value: DEFAULT_EVENT_TYPE },
  start: { value: null },
  end: { value: null },
  title: { value: '' },
  prestations: { value: [] },
  customer: { value: undefined },
  vehicleId: { value: '' },
  vehiclePlate: { value: undefined },
  notes: { value: '' },
  quoteId: { value: '' },
  orderPreference: { value: '' },
  supplier: { value: '' },
  checkedProducts: { value: [] },
  comment: { value: '' },
  quoteAmount: { value: '' },
  quote: { value: '' },
  overbooked: { value: false },
  garageVehicleId: { value: null },
  updateSchedulingAlert: { value: false },
  updateSchedulingCustomerMessage: { value: '' },
  vehicleDepositPreference: { value: VehicleDepositPreference.SAME_DAY },
  vehicleDepositDate: { value: null },
  others: { value: [] },
  isVehicleReceived: { value: false },
  submitEventAlert: { value: [NotificationType.NONE] },
  employee: { value: '' },
  otherReason: { value: '' },
};

const PART_KEYWORD = 'Autre pièce(s) - ';
const CONSUMABLE_KEYWORD = 'Autre consommable(s) - ';

//Fixme: A split le plus granulairement possible dès qu'il y a du temps mort, mise en place de Formik + hooks, ...
const MyCalendarEventDialog: FunctionComponent<MyCalendarEventDialogProps> = ({
  open,
  event,
  type,
  onClose,
  onRefresh,
  garage,
  garageId,
  loanVehicleByDate,
}) => {
  // Si c'est une création, garder que les dates préremplies, sinon garder toutes les valeurs de l'event
  // Par défaut préférence de dépôt de véhicule le jour même, horaire de début de rdv

  const getInitialForm = (): EventForm =>
    MyEventDialog.CREATE === type
      ? {
          ...initialFormState,
          start: { ...initialFormState.start, value: event.start as Date },
          end: { ...initialFormState.end, value: event.end as Date },
          vehicleDepositPreference: {
            ...initialFormState.vehicleDepositPreference,
            value: VehicleDepositPreference.SAME_DAY,
          },
          vehicleDepositDate: {
            ...initialFormState.vehicleDepositDate,
            value: event.start ? getEventVehicleDepositDate(event.start, garage) : null,
          },
          quote: { ...initialFormState.quote, value: '' },
          quoteAmount: { ...initialFormState.quoteAmount, value: '' },
          garageVehicleId: { ...initialFormState.garageVehicleId, value: '' },
          quoteId: { ...initialFormState.quoteId, value: '' },
          employee: { ...initialFormState.employee, value: event.resourceId },
        }
      : event
        ? {
            ...initialFormState,
            type: { ...initialFormState.type, value: event.type },
            start: { ...initialFormState.start, value: new Date(event.start!) as Date },
            end: { ...initialFormState.end, value: new Date(event.end!) as Date },
            orderPreference: { ...initialFormState.orderPreference, value: event.orderPreference },
            quoteAmount: { ...initialFormState.quoteAmount, value: event.quoteAmount },
            quoteId: { ...initialFormState.quoteId, value: event.quoteId },
            notes: { ...initialFormState.notes, value: event.notes },
            garageVehicleId: { ...initialFormState.garageVehicleId, value: event.garageVehicleId },
            vehicleDepositPreference: {
              ...initialFormState.vehicleDepositPreference,
              value: VehicleDepositPreference.SAME_DAY,
            },
            vehicleDepositDate: {
              ...initialFormState.vehicleDepositDate,
              value: event.vehicleDepositDate ? new Date(event.vehicleDepositDate) : null,
            },
            employee: { ...initialFormState.employee, value: event.resourceId },
            otherReason: { ...initialFormState.otherReason, value: event.otherReason },
          }
        : initialFormState;

  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // Récupération des données garage pour l'utilisateur connecté
  const [localGarage, setLocalGarage] = useState<Garage>(garage);

  // Initialisation du state local avec l'événement transmis en prop
  const [loading, setLoading] = useState(false);
  const [currentUpload, setCurrentUpload] = useState<boolean>(false);
  const [form, setForm] = useState<EventForm>(getInitialForm);
  /* DISPO PIECE A CONFIRMER - Champs 'Autres'*/
  const { isOtherPartFieldVisible, toggleOtherPartFieldVisible, setOtherPartFieldVisibleFalse } = useBoolState(
    false,
    'otherPartFieldVisible',
  );
  const { isOtherConsumableFieldVisible, toggleOtherConsumableFieldVisible, setOtherConsumableFieldVisibleFalse } =
    useBoolState(false, 'otherConsumableFieldVisible');

  const { isEventPlannedForAllDay, toggleEventPlannedForAllDay, setEventPlannedForAllDayFalse } = useBoolState(
    false,
    'eventPlannedForAllDay',
  );

  const { isPrestationsDialogOpenned, togglePrestationsDialogOpenned } = useBoolState(
    false,
    'prestationsDialogOpenned',
  );

  const [localOpen, setLocalOpen] = useState<boolean>(open);
  const [localEvent, setLocalEvent] = useState<Event>({
    ...event,
    start: new Date(event.start as Date),
    end: new Date(event.end as Date),
    vehicleDepositDate: event.vehicleDepositDate ? new Date(event.vehicleDepositDate as Date) : undefined,
    vehicleDepositDateRequest: event.vehicleDepositDateRequest
      ? new Date(event.vehicleDepositDateRequest as Date)
      : undefined,
    vehicleReceived: event.vehicleReceived,
  });
  const [shouldValidate, setShouldValidate] = useState(false);
  const [vehicleLabel, setVehicleLabel] = useState<string>('');
  const [vehicleDescription, setVehicleDescription] = useState<string | undefined>('');
  const [customerLabel, setCustomerLabel] = useState<string>('');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>();
  const [showVehicleCard, setShowVehicleCard] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [showCustomerCard, setShowCustomerCard] = useState<boolean>(false);
  // Pour la popup de confirmation d'annulation d'un rdv
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [openNotifyDelete, setOpenNotifyDelete] = useState<boolean>(false);
  // Pour la popup de saisie d'une plaque d'immatricualtion
  const [openAddVehiclePlate, setOpenAddVehiclePlate] = useState<boolean>(false);
  // Pour la popup de transmission facture
  const [openUploadInvoice, setOpenUploadInvoice] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<File | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editVehicleDeposit, setEditVehicleDeposit] = useState<boolean>(false);
  const [vehicleEditMode, setVehicleEditMode] = useState<boolean>(false);
  // Valeur par défaut du nombre de lignes par page
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(event?.vehicle);
  // Référence à la facture que nous pouvons uploader depuis le détail rdv
  const invoiceInputRef = useRef(null);
  // Eventuelle facture rattachée à l'événnement
  const [eventInvoice, setEventInvoice] = useState<Document | undefined>(undefined);
  const [openAddCustomer, setOpenAddCustomer] = useState<boolean>(false);
  const [openEditCustomer, setOpenEditCustomer] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [openAddSupplier, setOpenAddSupplier] = useState<boolean>(false);
  const [openSupplierRequest, setOpenSupplierRequest] = useState<boolean>(false);
  const supplierModified = useRef<Supplier>();
  const [openOrdered, setOpenOrdered] = useState<boolean>(false);
  const [supplierRequestType, setSupplierRequestType] = useState<SupplierRequestType>();
  const [selectedPrestations, setSelectedPrestations] = useState<PrestationRequest[]>([]);
  const [openApplicationChoice, setOpenApplicationChoice] = useState<boolean>(false);
  const [focusedPrestation, setFocusedPrestation] = useState<Prestation | undefined>();
  const [selectedApplications, setSelectedApplications] = useState<PartsApplicationType[] | undefined>(undefined);
  // Référence à l'éventuel Devis transmis au client
  const quoteInputRef = useRef(null);
  const [openCustomerReminder, setOpenCustomerReminder] = useState<boolean>(false);
  const [showSendNewQuoteAction, setShowSendNewQuoteAction] = useState<boolean>(false);
  // Pour contrôler l'affichage des blocs DEVIS & COMMANDE
  const [showQuotePanel, setShowQuotePanel] = useState<boolean>(false);
  const [showOrderPanel, setShowOrderPanel] = useState<boolean>(false);
  const [currentCustomerInput, setCurrentCustomerInput] = useState<string>('');
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [openDepositVehicleChangeDialog, setOpenDepositVehicleChangeDialog] = useState(false);
  const [openSubmitEventConfirmDialog, setOpenSubmitEventConfirmDialog] = useState(false);
  const [currentRepairOrderNumber, setCurrentRepairOrderNumber] = useState<number>(0);
  const connectedUser = useRef<User | null>();
  connectedUser.current = useSelector((state: RootState) => state.user.user); // Récupération du user depuis le state Redux
  const { isEditVehiculeDepositDialogOpened, toggleEditVehiculeDepositDialogOpened } = useBoolState(
    false,
    'editVehiculeDepositDialogOpened',
  );

  const [selectedColor, setSelectedColor] = useState('');
  const movedDepositDate = useRef<Date>();

  // Ordre de réparation PDF
  const { isInternalNoteInjected, toggleInternalNoteInjected, setInternalNoteInjectedFalse } = useBoolState(
    false,
    'internalNoteInjected',
  );
  const { isShowConfirmDownloadRepairOrder, toggleShowConfirmDownloadRepairOrder } = useBoolState(
    false,
    'showConfirmDownloadRepairOrder',
  );

  const { isShowConfirmUploadDocument, toggleShowConfirmUploadDocument } = useBoolState(
    false,
    'showConfirmUploadDocument',
  );

  const [repairOrder, updateRepairOrder] = usePDF({
    document: (
      <RepairOrder garage={garage} event={localEvent} showInternalNote={false} number={currentRepairOrderNumber} />
    ),
  });
  const [editVehicle, setEditVehicle] = useState(false);
  const [loadingEditVehicle, setLoadingEditVehicle] = useState(false);
  const handleChangeColor = (color: string) => {
    setSelectedColor(color);
    if (localEvent.id) {
      CoreGarageService.updateGarageEventColor(garageId, localEvent.id, color);
    }
  };
  const initialLastName = useRef<string>('');

  const toggleInternalNoteInjectedProxy = () => {
    // Update PDF because we inject or not the internal note
    updateRepairOrder(
      <RepairOrder
        garage={garage}
        event={localEvent}
        showInternalNote={!isInternalNoteInjected}
        number={currentRepairOrderNumber}
      />,
    );
    toggleInternalNoteInjected();
  };

  const onCloseConfirmDownloadRepairOrder = () => {
    setInternalNoteInjectedFalse();
    toggleShowConfirmDownloadRepairOrder();
  };

  // Forcer l'acceptation du Devis
  const { isConfirmAcceptQuoteOpened, toggleConfirmAcceptQuoteOpened } = useBoolState(
    false,
    'confirmAcceptQuoteOpened',
  );

  const { mutateAsync: forceAcceptQuote } = useAcceptQuoteEvent();

  const handleAcceptQuote = () => {
    forceAcceptQuote({ eventId: event.id, garageId: garage.id })
      .then(() => {
        dispatch(
          setSnackbar({
            open: true,
            message: 'Devis accepté avec succès',
            severity: 'success',
          }),
        );
        toggleConfirmAcceptQuoteOpened();
        refreshEvent();
      })
      .catch((error) => {
        dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
      });
  };

  const displayAlerteWrongPhoneNumber = () => {
    return (
      <Alert
        severity='info'
        icon={<InfoIcon />}
        sx={{ borderRadius: 20, mt: 1, mr: 'auto', ml: 'auto' }}
        className='styled-alert'
      >
        Le format du numéro enregistré ne permet pas l’envoi de SMS
      </Alert>
    );
  };
  const handleSendMethod = (notification: NotificationType) => {
    let newValue = [...form.submitEventAlert?.value];
    if (newValue.includes(notification)) {
      newValue = newValue.filter((item: NotificationType) => item !== notification);
    } else {
      newValue.push(notification);
    }
    if (notification !== NotificationType.NONE && newValue) {
      newValue = newValue.filter((item: NotificationType) => item !== NotificationType.NONE);
    }
    if (notification === NotificationType.NONE) {
      newValue = [NotificationType.NONE];
    }
    handleChange('submitEventAlert', newValue);
  };

  const displayBtnSendByEmail = (isDisabled = false, form: EventForm) => {
    return (
      <>
        <StyledToggleButton
          size='small'
          sx={{ width: '120px' }}
          disabled={isDisabled}
          customSelectedBackgroundColor={theme.palette.primary.main}
          customHoverColor={
            isMobile
              ? form?.submitEventAlert?.value.includes(NotificationType.MAIL)
                ? theme.palette.primary.light
                : 'none'
              : theme.palette.primary.light
          }
          value={NotificationType.MAIL}
          onClick={() => handleSendMethod(NotificationType.MAIL)}
          selected={form?.submitEventAlert?.value.includes(NotificationType.MAIL)}
          aria-label='submit event MAIL alert'
        >
          <b>Oui, par EMAIL</b>
        </StyledToggleButton>
      </>
    );
  };

  const displayBtnSendBySms = (isDisabled = false, form: EventForm) => {
    return (
      <>
        <StyledToggleButton
          size='small'
          sx={{ width: '120px' }}
          disabled={isDisabled}
          customSelectedBackgroundColor={theme.palette.primary.main}
          customHoverColor={
            isMobile
              ? form?.submitEventAlert?.value.includes(NotificationType.SMS)
                ? theme.palette.primary.light
                : 'none'
              : theme.palette.primary.light
          }
          value={NotificationType.SMS}
          onClick={() => handleSendMethod(NotificationType.SMS)}
          selected={form?.submitEventAlert?.value.includes(NotificationType.SMS)}
          aria-label='submit event SMS alert'
        >
          <b>Oui, par SMS</b>
        </StyledToggleButton>
      </>
    );
  };

  const displayBtnIsVehicleReceived = (isDisabled = false, form: EventForm) => {
    if (isDisabled && form?.isVehicleReceived?.value.toString() === 'true') {
      handleChange('isVehicleReceived', true);
    }
    return (
      <>
        <StyledToggleButton
          size='small'
          sx={{ width: '120px' }}
          disabled={isDisabled}
          customSelectedBackgroundColor={theme.palette.primary.main}
          customHoverColor={
            isMobile
              ? form?.isVehicleReceived?.value.toString() === 'true'
                ? theme.palette.primary.light
                : 'none'
              : theme.palette.primary.light
          }
          value={true}
          onClick={() => handleChange('isVehicleReceived', true)}
          selected={form?.isVehicleReceived?.value.toString() === 'true'}
          aria-label='submit isVehicleReceived'
        >
          <b>Oui</b>
        </StyledToggleButton>
      </>
    );
  };

  /**
   * INITIALISATION DU COMPOSANT AVEC LA VUE PAR DEFAUT
   */
  useEffect(() => {
    try {
      //refreshGarage();
      // Pas de refresh d'event en cas de création
      if (MyEventDialog.CREATE === type) return;

      // Initialisation affichage des blocs DEVIS & COMMANDE
      initShowPanels();
    } catch (e) {
      Logger.error(e);
    }
  }, []);

  useEffect(() => {
    if (!isEqual(localEvent, event)) {
      setLocalEvent({
        ...event,
        start: new Date(event.start as Date),
        end: new Date(event.end as Date),
        vehicleDepositDate: event.vehicleDepositDate ? new Date(event.vehicleDepositDate as Date) : undefined,
        vehicleDepositDateRequest: event.vehicleDepositDateRequest
          ? new Date(event.vehicleDepositDateRequest as Date)
          : undefined,
        vehicleReceived: event.vehicleReceived,
      });
    }
  }, [event]);

  const refreshGarage = () => {
    CoreGarageService.getAdministratedGarages({ garageId }).then(async (response) => {
      if (response.success) {
        // On ne gère que le premier résultat de la liste (MVP : 1 garage)
        Logger.info('Refresh:', response.data ?? null);
        if (response.data) {
          if (response.data.loanerVehicleActive) {
            response.data.vehicles = (await CoreGarageService.getAllGarageVehicles(response.data.id)).data;
          }
          //dispatch(setGarageData(response.data ? response.data[0] : null));
          setLocalGarage(response.data);
          if (onRefresh) onRefresh();
        }
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? 'Chargement des données impossible',
            severity: 'error',
          }),
        );
      }
    });
  };

  const refreshEvent = () => {
    if (event?.id) {
      GarageService.getGarageEvent(dispatch, garageId, event.id)
        .then((data) => {
          if (data) {
            setSelectedColor(data.color ?? '');
            setLocalEvent(data);
            // On force une maj manuelle des champ Date car la conversion n'est pas explicite en React
            setLocalEvent((prevLocalEvent) => ({
              ...prevLocalEvent,
              start: new Date(event.start as Date), // Crée un nouvel objet Date basé sur event.start
              end: new Date(event.end as Date), // Crée un nouvel objet Date basé sur event.end
              vehicleDepositDate: event.vehicleDepositDate ? new Date(event.vehicleDepositDate as Date) : undefined,
              vehicleDepositDateRequest: event.vehicleDepositDateRequest
                ? new Date(event.vehicleDepositDateRequest as Date)
                : undefined,
            }));
            refreshVehicleLabel(data.vehicle);
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        });
    }
  };

  useEffect(() => {
    if (localEvent) {
      // on refresh le PDF avec les nouvelles données de l'event
      updateRepairOrder(
        <RepairOrder
          garage={garage}
          event={localEvent}
          showInternalNote={isInternalNoteInjected}
          number={currentRepairOrderNumber}
        />,
      );

      // Nous prenons le premier document de type "USER_APPOINTMENT_QUOTE", car il ne peut y avoir qu'un devis actif
      setForm((prevForm) => ({
        ...prevForm,
        quote: {
          ...prevForm['quote'],
          value: localEvent.documents?.filter((doc) => doc.type === DocumentType.USER_APPOINTMENT_QUOTE)[0],
        },
      }));

      setForm((prevForm) => ({
        ...prevForm,
        quoteAmount: { ...prevForm['quoteAmount'], value: localEvent.quoteAmount },
      }));
    }

    Logger.info('LocalEvent : ', localEvent);
  }, [localEvent]); // Exécute le hook à chaque fois que l'événement local change (plus détaillé)

  useEffect(() => {
    if (event) {
      setForm(getInitialForm);
      refreshVehicleLabel(event.vehicle);
      //refreshGuestInfos(event.guests);
      refreshSelectedVehicle();

      refreshEvent();
    }
  }, [event]); // Exécute le hook à chaque fois que l'événement change

  // Permet d'actualiser le formulaire à chaque fois qu'une valeur change
  useEffect(() => {
    // Attention le contrôle est essentiel sinon on entre dans une boucle infinie ...
    if (shouldValidate) {
      validateForm();
      setShouldValidate(false); // Reset after validation
    }
  }, [form, shouldValidate]); // This will run validateForm every time `form` changes

  // Réinitilise la valeur de 'title' lors du switch de type dans le formulaire
  useEffect(() => {
    if (event) {
      let eventTitle = '';
      switch (form.type.value) {
        case EventType.NOTE:
          eventTitle = 'NOTE';
          break;
        case EventType.UNAVAILABILITY:
          eventTitle = 'Fermé';
          break;
        default:
          eventTitle = '';
          break;
      }

      setForm((prevForm) => ({
        ...prevForm,
        title: { ...prevForm['title'], value: eventTitle },
      }));
      setEventPlannedForAllDayFalse();
    }
  }, [form.type.value]);

  useEffect(() => {
    if (!garageId) {
      garageId = garage ? garage.id : '';
    }
  }, [open]);

  useEffect(() => {
    if (localGarage) {
      // Si nous n'avons qu'un grossiste nous l'affectons par défaut au formulaire
      Logger.info(Boolean(localGarage.suppliers !== undefined && localGarage.suppliers.length === 1));
      if (localGarage.suppliers && localGarage.suppliers.length === 1) {
        setForm((prevForm) => ({
          ...prevForm,
          supplier: {
            ...prevForm['supplier'],
            value: localGarage.suppliers !== undefined ? (localGarage.suppliers[0] as Supplier).id : '',
          },
        }));
      }
    }
  }, [localGarage]);

  const [availableVehicles, setAvailableVehicles] = useState<VehicleGarage[]>([]);
  // Calculer les véhicules disponibles pour la plage de dates sélectionnée
  useEffect(() => {
    if (localGarage.loanerVehicleActive) {
      const startDate = new Date(form.start.value ?? localEvent.start);
      const endDate = new Date(form.end.value ?? localEvent.end);
      const selectedDates: string[] = [];

      // Collecter toutes les dates entre start et end
      for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        selectedDates.push(date.toISOString().split('T')[0]);
      }

      // Filtrer les véhicules disponibles
      const unavailableVehicles = new Set(
        selectedDates.flatMap((date) => loanVehicleByDate[date] || []).map((v) => v?.id),
      );

      setAvailableVehicles(localGarage?.vehicles?.filter((vehicle) => !unavailableVehicles.has(vehicle.id)) || []);
    }
  }, [form.start.value, form.end.value, loanVehicleByDate, localGarage, localEvent]);

  const initShowPanels = () => {
    if (localEvent && localGarage) {
      // Initialisation affichage des blocs DEVIS & COMMANDE
      if (
        isActiveEvent(localEvent) &&
        EventState.ACCEPTED !== event?.state &&
        EventState.REJECTED !== event?.state &&
        localGarage.supplierOrderActive
      ) {
        setShowQuotePanel(false);
        setShowOrderPanel(true);
      } else if (localGarage.customerQuoteActive && !localGarage.supplierOrderActive) {
        setShowQuotePanel(true);
        setShowOrderPanel(false);
      } else if (localGarage.customerQuoteActive && localGarage.supplierOrderActive) {
        if (EventState.ACCEPTED === event?.state || EventState.REJECTED === event?.state) {
          setShowQuotePanel(true);
          setShowOrderPanel(false);
        } else {
          setShowQuotePanel(false);
          setShowOrderPanel(true);
        }
      }
    }
  };

  const purgeLocalState = () => {
    setForm(initialFormState);
    setShowSendNewQuoteAction(false);
    setShowVehicleCard(false);
    setShowCustomerCard(false);
    setSelectedInvoice(null);
    setOpenConfirmDelete(false);
    setOpenUploadInvoice(false);
    setEditMode(false);
    setEditVehicle(false);
    setEditVehicleDeposit(false);
    setVehicleEditMode(false);
    setCustomers([]);
    setVehicleDescription('');
    setSelectedPrestations([]);
    setFocusedPrestation(undefined);
    setOtherPartFieldVisibleFalse();
    setOtherConsumableFieldVisibleFalse();
    setOpenSubmitEventConfirmDialog(false);
    setAutocompleteOpen(false);
    setSelectedCustomer(null);
    movedDepositDate.current = undefined;
    setSelectedColor('');
    supplierModified.current = undefined;
    initialLastName.current = '';
    setCurrentFocusedUser(null);
  };

  const refreshSelectedVehicle = () => {
    if (localEvent.vehicle?.id) {
      setLoading(true);

      // Récupération des véhicules de l'utilisateur courant
      VehicleService.getVehicleDetails(dispatch, localEvent.vehicle.id)
        .then((vehicle) => {
          Logger.info(vehicle);
          if (vehicle) {
            setSelectedVehicle(vehicle);
            if (vehicle.documents && event.id) {
              // On récupère une éventuelle facture rattachée au véhicule et ayant l'identifiant de l'événement en reference
              setEventInvoice(
                Document.findByTypeAndReference(vehicle.documents, DocumentType.VEHICLE_MAINTENANCE_INVOICE, event.id),
              );
            }
          }
        })
        .catch((error) => {
          Logger.error(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleOnClose = (reason?: string) => {
    if (reason === 'backdropClick' && MyEventDialog.CREATE === type) return;

    if (showVehicleCard || showCustomerCard) {
      // Il s'agit d'une demande de fermeture de la fiche véhicule / client, on reste sur le détail de l'event
      setShowVehicleCard(false);
    } else if (isPrestationsDialogOpenned) {
      // On ferme la fenetre permettant de gérer les prestations
      togglePrestationsDialogOpenned();
    } else {
      // On purge le state local avant d'appeler le callback de fermeture
      purgeLocalState();
      onClose(false);
    }
  };

  const validateForm = () => {
    let newForm: EventForm = form;
    let newField: MovaFormField;

    // Validator 'start'
    if (!(newForm.start.value instanceof Date)) {
      newField = { value: form.start?.value, error: "Date de début de l'événement invalide." };
    } else {
      newField = { value: form.start?.value, error: '' };
    }
    newForm = { ...newForm, ...{ start: newField } };

    // Validator 'end'
    if (!(newForm.end.value instanceof Date)) {
      newField = { value: form.end?.value, error: "Date de début de l'événement invalide." };
    } else {
      newField = { value: form.end?.value, error: '' };
    }
    newForm = { ...newForm, ...{ end: newField } };

    if (garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD) {
      // Préférence de dépôt véhicule
      if (!newForm.vehicleDepositPreference) {
        newField = { value: form.vehicleDepositPreference?.value, error: 'Préférence de dépôt invalide.' };
      } else {
        newField = { value: form.vehicleDepositPreference?.value, error: '' };
      }
      newForm = { ...newForm, ...{ vehicleDepositPreference: newField } };

      // Heure de dépôt véhicule
      if (!(newForm.vehicleDepositDate?.value instanceof Date) || newForm.vehicleDepositDate?.value === undefined) {
        newField = { value: form.vehicleDepositDate?.value, error: 'Heure dépôt invalide.' };
      } else {
        newField = { value: form.vehicleDepositDate?.value, error: '' };
      }
      newForm = { ...newForm, ...{ vehicleDepositDate: newField } };
    }

    // Validator 'prestations'
    if (form.type.value === EventType.APPOINTMENT && form.prestations?.value.length === 0) {
      // Pour un rendez-vous le choix d'une prestation est obligatoire
      newField = { value: form.prestations?.value, error: "Le choix d'au moins une prestation est obligatoire" };
    } else {
      newField = { value: form.prestations?.value, error: '' };
    }
    newForm = { ...newForm, ...{ prestations: newField } };

    // Validator 'otherReason'
    if (
      localGarage.prestations
        .filter((p) => (form.prestations?.value || []).includes(p.id))
        .find((p) => p.code === 'OTHER')
    ) {
      if (!validateText(newForm.otherReason?.value)) {
        newField = {
          value: form.otherReason?.value,
          error: 'La précision de la prestation "Autre..." est obligatoire.',
        };
      } else {
        newField = { value: form.otherReason?.value, error: '' };
      }
      newForm = { ...newForm, ...{ otherReason: newField } };
    }

    // Validator 'title'
    if (form.type.value === EventType.APPOINTMENT) {
      // Pour un rendez-vous le titre est un agrégat des services sélectionnées
      newField = { value: (form.prestations?.value as string[]).join(' | '), error: '' };
    } else {
      // Sinon on prend la valeur du champ titre
      if (!validateText(newForm.title.value)) {
        Logger.info('hey');
        newField = { value: form.title?.value, error: 'Le titre est invalide.' };
      } else {
        newField = { value: form.title.value, error: '' };
      }
    }
    newForm = { ...newForm, ...{ title: newField } };

    // Validator 'customer'
    if (form.type.value === EventType.APPOINTMENT && (!form.customer?.value || form.customer?.value === null)) {
      newField = { value: form.customer?.value, error: 'Le choix du client est obligatoire.' };
    } else {
      newField = { value: form.customer?.value, error: '' };
    }
    newForm = { ...newForm, ...{ customer: newField } };

    setForm(newForm);

    return !Boolean(
      newForm.start?.error ||
        newForm.title.error ||
        newForm.customer?.error ||
        newForm.end?.error ||
        newForm.prestations?.error ||
        newForm.otherReason?.error ||
        (garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD &&
          (newForm.vehicleDepositPreference?.error || newForm.vehicleDepositDate?.error)),
    );
  };

  /*     const getPrestationsRequest = (): PrestationRequest[] => {
        let prestations: PrestationRequest[] = [];

        (form.prestations?.value as number[]).forEach(prestation => {
            console.log(prestation);
            let req: PrestationRequest = {
                id: prestation
            }
            prestations.push(req);
        });

        return prestations;
    } */

  const handleOpenConfirmSubmitEvent = () => {
    // Vaidation du formulaire
    if (validateForm() && garageId) {
      if (form.type.value === EventType.APPOINTMENT) {
        // S'il ne s'agit pas d'un envoi de devis
        if (!(form.quote?.value || form.quoteAmount?.value)) {
          setOpenSubmitEventConfirmDialog(true);
        } else {
          setOpenSubmitEventConfirmDialog(false);
          handleSubmitEvent();
        }
      } else {
        handleSubmitEvent();
      }
    }
  };

  const handleConfirmSubmitEvent = () => {
    setOpenSubmitEventConfirmDialog(false);
    handleSubmitEvent();
  };

  const handleCancelConfirmSubmitEvent = () => {
    setOpenSubmitEventConfirmDialog(false);
  };

  const handleSubmitQuote = () => {
    if (!localGarage?.customerQuoteActive) {
      return;
    }

    // Préparation de la requête
    const formData = new FormData();

    if (form.quote?.value) {
      formData.append('quote', form.quote.value);
    }

    if (form.quoteAmount?.value) {
      formData.append('quoteAmount', form.quoteAmount.value);
    }

    // Transmission du nouveau devis au client
    CoreGarageService.sendNewEventQuote(garageId, event.id, formData)
      .then((response) => {
        if (response.success) {
          // Fermeture de la boite de dialogue (avec refresh des events)
          onClose(true);

          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'Devis envoyé au client avec succès',
              severity: 'success',
            }),
          );
          setShowSendNewQuoteAction(false);
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Echec lors de l'envoi du devis au client",
              severity: 'error',
            }),
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitEvent = () => {
    // Vaidation du formulaire
    if (validateForm() && garageId) {
      // Préparation de la requête
      const formData = new FormData();

      // Ajouter le devis à FormData si présent et si paramétre garage actif
      if (localGarage?.customerQuoteActive) {
        if (form.quote?.value) {
          formData.append('quote', form.quote.value);
        }

        if (form.quoteAmount?.value) {
          formData.append('quoteAmount', form.quoteAmount.value);
        }
      }

      const req = {
        type: form.type.value,
        title: form.title.value,
        //startDate: form.start.value,
        // IMPORTANT : permet de transmettre le TimeZone correspond à l'horaire choisit, l'API réalise des ajustements selon la zone
        startDate: formatLocalDateToISOString(form.start.value),
        endDate: form.end.value,
        prestations: selectedPrestations,
        guestsList: form.customer?.value ? [(form.customer?.value as User).id] : [],
        customerId: form.customer?.value ? (form.customer?.value as User).id : null,
        vehicleId: form.vehicleId?.value,
        vehiclePlate: form.vehiclePlate?.value,
        color: selectedColor,
        notes: form.notes?.value,
        quoteId: form.quoteId?.value,
        overbooked: form.overbooked?.value,
        garageVehicleId: form.garageVehicleId?.value,
        isVehicleReceived: form.isVehicleReceived?.value,
        vehicleDepositPreference:
          garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD &&
          (form.type.value === EventType.APPOINTMENT || form.type.value === EventType.PENDING_APPOINTMENT)
            ? form.vehicleDepositPreference?.value
            : null,
        vehicleDepositDate:
          garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD &&
          (form.type.value === EventType.APPOINTMENT || form.type.value === EventType.PENDING_APPOINTMENT)
            ? formatLocalDateToISOString(form.vehicleDepositDate?.value)
            : null,
        notificationType:
          form.submitEventAlert?.value.length > 1 ? NotificationType.SMS_MAIL : form.submitEventAlert?.value[0],
        employeeId: form.employee?.value,
        otherReason: form.otherReason?.value,
      };

      formData.append('event', JSON.stringify(req));

      // Création de l'événement
      GarageService.createGarageEvent(dispatch, garageId, formData)
        .then((response) => {
          purgeLocalState();

          // Fermeture de la boite de dialogue (avec refresh des events)
          onClose(true);

          // Affichage notification utilisateur
          dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
        })
        .catch((error) => {
          console.error(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        });
    }

    return Promise.resolve();
  };

  /*     const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Vaidation du formulaire
        if(validateForm() && garageId){

            // Création de la requête
            let req = {
                type: form.type.value,
                title: form.title.value,
                startDate: form.start.value,
                endDate: form.end.value,
                prestationsList: form.prestations?.value,
                guestsList: form.customer?.value ? [(form.customer?.value as User).id] : [],
                customerId: form.customer?.value ? (form.customer?.value as User).id : null,
                vehicleId: form.vehicleId?.value,
                vehiclePlate: form.vehiclePlate?.value,
                notes: form.notes?.value,
                quoteId: form.quoteId?.value
            };

            console.log(req);

            // Création de l'événement
            GarageService.createGarageEvent(dispatch, garageId, req)
            .then(() => {
                purgeLocalState();

                let msg = (form.type.value === EventType.APPOINTMENT) ?
                "Le rendez-vous a bien été ajouté" : "L'indisponibilité a bien été ajoutée";

                // Fermeture de la boite de dialogue (avec refresh des events)
                onClose(true);

                // Affichage notification utilisateur
                dispatch(setSnackbar({ open: true, message: msg, severity: 'success' }));

            }).catch(error => {
                console.error(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            });
        }
    } */

  const handleChange = (fieldName: keyof EventForm, newValue: any, validate?: boolean) => {
    // Cas particulier du montant du devis qui n'est pas modifiable s'il est en attente d'acceptation par le client
    if (
      fieldName === 'quoteAmount' &&
      form.quoteAmount?.value !== undefined &&
      !showSendNewQuoteAction &&
      type !== MyEventDialog.CREATE &&
      event.state === EventState.ACCEPTED
    ) {
      return;
    }

    // Dans le cas des rdv dispo pièces / pièces dispo, on affiche le bouton pour envoyer un devis
    if (
      [EventState.SCHEDULED, EventState.COMPLETED].includes(event.state) &&
      ['quote', 'quoteAmount'].includes(fieldName)
    ) {
      const fieldNotTouched = fieldName === 'quote' ? 'quoteAmount' : 'quote';

      if (form[fieldNotTouched]?.value || newValue) {
        setShowSendNewQuoteAction(true);
      } else {
        setShowSendNewQuoteAction(false);
      }
    }

    // Cas particulier du véhicule, si aucun véhicule pour le client nous pouvons saisir une plaque (valeur  de l'id -1 dans ce cas)
    if (fieldName === 'supplier' && newValue === -1) {
      handleAddSupplier();
      return;
    }

    // Cas particulier du véhicule, si aucun véhicule pour le client nous pouvons saisir une plaque (valeur  de l'id -1 dans ce cas)
    if (fieldName === 'vehicleId' && newValue === -1) {
      handleAddVehicleDialog();
      return;
    }
    // Cas particulier du client, en cas de changement on réinitialise l'éventuelle saisie de plaque et l'identifiant du véhicule sélectionné si le client a plusieurs ou aucun vehicules
    if (fieldName === 'customer') {
      if (newValue?.vehicles?.length === 1) {
        handleChange('vehicleId', newValue.vehicles[0].id);
      } else {
        setForm((prevForm) => ({ ...prevForm, vehicleId: { ...prevForm['vehicleId'], value: '' } }));
        setForm((prevForm) => ({ ...prevForm, vehiclePlate: { ...prevForm['vehiclePlate'], value: undefined } }));
        setVehicleDescription('');
      }
    }

    // Cas particulier de la prestation, certaines nécessite de choisir une application, le code prestation "OTHER" indique le choix "Autre"
    if (newValue && fieldName == 'prestations') {
      handleChangePrestations(newValue);
      return;
    }
    if (newValue && fieldName === 'vehicleDepositDate') {
      movedDepositDate.current = newValue;
    }

    // Gestion du changement de type (on reset l'obligation de saisie de la note interne)
    if (fieldName === 'type') {
      handleChangePrestations([]);
    }
    setForm((prevForm) => ({ ...prevForm, [fieldName]: { ...prevForm[fieldName], value: newValue } }));

    if (validate) {
      setShouldValidate(true);
    }
  };

  const handleChangePrestations = (prestationIds: number[]) => {
    if (prestationIds) {
      Logger.info('prestationIds: ', prestationIds);

      const finalPrestationsIds: number[] = [];
      const finalPrestations: PrestationRequest[] = [];

      // 1. On commence par reprendre les prestations qui ne nécessitent pas de choix d'application
      localGarage.prestations
        .filter((p) => prestationIds.includes(p.id))
        .filter((p) => !applicationChoiceRequired(p))
        .forEach((p: Prestation) => {
          Logger.info('1. : ', p);
          // On crée un nouvel objet PrestationRequest (sans application)
          const req: PrestationRequest = {
            id: p.id,
          };
          finalPrestations.push(req);
          finalPrestationsIds.push(p.id);
        });

      // 2. Ensuite on gère le cas de(s) prestation(s) avec choix d'application déjà connue(s) du tableau de sélection et présents dans la liste
      selectedPrestations
        .filter((p) => prestationIds.includes(p.id) && p.applications !== undefined)
        .forEach((p: PrestationRequest) => {
          Logger.info('2. :', p);
          // On ajoute l'objet directement à la liste
          finalPrestations.push(p);
          finalPrestationsIds.push(p.id);
        });

      // MAJ des states avant gestion d'une éventuelle presta nouvelle avec choix d'application
      setSelectedPrestations(finalPrestations);
      setForm((prevForm) => ({ ...prevForm, prestations: { ...prevForm['prestations'], value: finalPrestationsIds } }));

      // 3. Il ne reste plus qu'a gérer l'éventuelle prestation restante (non connue et avec choix d'application)
      localGarage.prestations
        .filter(
          (p) =>
            prestationIds.includes(p.id) &&
            !(selectedPrestations.map((sp) => sp.id).filter((sp) => sp === p.id).length > 0),
        )
        .filter((p) => applicationChoiceRequired(p))
        .forEach((p: Prestation) => {
          Logger.info('3. :', p);
          setFocusedPrestation(p);
          setOpenApplicationChoice(true);
          return;
        });
    }
  };
  const handleMultipleApplicationsChoice = (value: PartsApplicationType[]) => {
    // Toggle the selection of the application
    setSelectedApplications(value);

    // Si la prestation sélectionnée n'est pas en multiple application, on confirme automatiquement le choix
    if (focusedPrestation?.operations && !focusedPrestation.multipleApplication) {
      confirmApplicationsChoice(value);
    }
  };

  /*   const handleConfirmApplicationChoice = (application: PartsApplicationType) => {
    Logger.info(selectedPrestations);
    Logger.info(form.prestations?.value);
    if (focusedPrestation && application) {
      // On crée un nouvel objet PrestationRequest
      const req: PrestationRequest = {
        id: focusedPrestation.id,
        application: application,
      };
      setSelectedPrestations([...selectedPrestations, req]);
      // On maj le formulaire de saisie
      const newValue = [...form.prestations?.value, focusedPrestation.id];
      Logger.info(newValue);
      setForm((prevForm) => ({ ...prevForm, prestations: { ...prevForm['prestations'], value: newValue } }));
    }
    setFocusedPrestation(undefined);
    setOpenApplicationChoice(false);
  }; */

  const confirmApplicationsChoice = (value: PartsApplicationType[] | undefined) => {
    // On choisit en priorité les applications transmises, sinon on exploite la valeur en useState
    const applications = value ?? selectedApplications;
    if (focusedPrestation && applications) {
      // On crée un nouvel objet PrestationRequest
      const req: PrestationRequest = {
        id: focusedPrestation.id,
        applications: applications,
      };
      setSelectedPrestations([...selectedPrestations, req]);
      // On maj le formulaire de saisie
      const newValue = [...form.prestations?.value, focusedPrestation.id];
      Logger.info(newValue);
      setForm((prevForm) => ({ ...prevForm, prestations: { ...prevForm['prestations'], value: newValue } }));
    }
    setFocusedPrestation(undefined);
    setSelectedApplications(undefined);
    setOpenApplicationChoice(false);
  };

  const handleCancelApplicationChoice = () => {
    setOpenApplicationChoice(false);
    setFocusedPrestation(undefined);
    setSelectedApplications(undefined);
  };

  const handleCloseAddVehicleDialog = () => {
    setOpenAddVehiclePlate(false);
    setForm((prevForm) => ({ ...prevForm, vehicleId: { ...prevForm['vehicleId'], value: '' } }));
    setForm((prevForm) => ({ ...prevForm, vehiclePlate: { ...prevForm['vehiclePlate'], value: undefined } }));
  };

  const handleAddVehicleDialog = () => {
    setOpenAddVehiclePlate(true);
  };

  const handleConfirmVehicleDialog = (validVehiclePlate: string, validVehicleDescription: string) => {
    // On intègre la plaque au formulaire avant soumission éventuelle
    handleChange('vehiclePlate', validVehiclePlate, false);

    // Si nous sommes sur le formulaire de création
    if (MyEventDialog.CREATE === type) {
      setOpenAddVehiclePlate(false);
      setVehicleDescription(validVehicleDescription);
      handleChange('vehicleId', '', false);
    } else {
      setLoading(true);
      const req = {
        vehiclePlate: validVehiclePlate,
      };
      // Sinon, ajout d'un véhicule sur un rendez-vous existant
      CoreGarageService.updateVehicleGarageEvent(garageId, localEvent.id, req)
        .then((response) => {
          if (response.success) {
            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? 'Le véhicule a bien été ajouté',
                severity: 'success',
              }),
            );
            setOpenAddVehiclePlate(false);
            setEditVehicle(false);
            // On refresh l'event pour obtenir le véhicule mis à jour
            refreshEvent();
          } else {
            dispatch(
              setSnackbar({ open: true, message: response.error ?? 'Ajout du véhicule impossible', severity: 'error' }),
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const refreshVehicleLabel = (vehicle: Vehicle | undefined) => {
    // On réinitialise le label
    setVehicleLabel('');

    if (vehicle) {
      const label = `${formatFrenchVehiclePlate(vehicle?.plate)} ${vehicle?.brand} ${vehicle?.model} ${
        vehicle?.version
      }`;
      setVehicleLabel(label);
    }
  };

  /*   const refreshGuestInfos = (guests: Guest[] | undefined) => {
    // On réinitialise le label du client et son numéro de tel
    setCustomerLabel('');
    setCustomerPhoneNumber(undefined);

    //MVP : on ne gère qu'un seul guest de type Customer pour l'instant, l'appel API ne doit retourner qu'un Guest au maximum
    if (guests && guests?.length !== 0) {
      for (const guest of guests) {
        if (guest.firstname && guest.lastname) {
          const label = `${guest.lastname.toUpperCase()} ${guest.firstname}`;
          setCustomerLabel(label);
        }

        if (guest.phoneNumber) {
          setCustomerPhoneNumber(guest.phoneNumber);
        }

        //MVP : on ne gère qu'un seul guest de type Customer pour l'instant, l'appel API ne doit retourner qu'un Guest au maximum
        continue;
      }
    }
  }; */

const lostFocus =() => {
  // force update note when the user lost focus on quill note editor.
  if (form?.notes?.value !== localEvent.notes) {
    handlePostEventNotes();    
  }
}


  const handleClickPhoneNumber = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // TODO : Ajoutez ici le code pour gérer le clic sur le numéro de téléphone
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteEvent = () => {
    setOpenConfirmDelete(true);
  };

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(false);

    if (event.id) {
      switch (event.type) {
        case EventType.APPOINTMENT: {
          // S'il s'agit d'un rendez-vous, on annule (suppression logique) si son état n'est pas CANCELLED
          if (event.state !== EventState.CANCELLED) {
            if (event.customer?.email) {
              setOpenNotifyDelete(true);
            } else {
              handleConfirmDeleteNotify(false);
            }
          } else {
            GarageService.deleteGarageEvent(dispatch, garageId, event.id)
              .then((response) => {
                purgeLocalState();
                onClose(true);
                // Affichage notification utilisateur
                dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
              })
              .catch((error) => {
                console.error(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
              });
          }

          break;
        }

        case EventType.UNAVAILABILITY: {
          // S'il s'agit d'une indisponibilité, on supprime l'événement
          GarageService.deleteGarageEvent(dispatch, garageId, event.id)
            .then((response) => {
              purgeLocalState();
              onClose(true);
              // Affichage notification utilisateur
              dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
            })
            .catch((error) => {
              console.error(error);
              dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            });
        }
      }
    }
  };

  const handleConfirmDeleteNotify = (notify: boolean) => {
    setOpenNotifyDelete(false);
    GarageService.cancelGarageEvent(dispatch, garageId, event.id, notify)
      .then((response) => {
        purgeLocalState();
        onClose(true);
        // Affichage notification utilisateur
        dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
      });
  };

  const handleShowVehicleCard = () => {
    refreshSelectedVehicle();
    setShowVehicleCard(true);
  };

  const handleHideVehicleCard = () => {
    setShowVehicleCard(false);
  };

  const handlePartsAvailability = (available: boolean) => {
    if (event && garageId) {
      if (available) {
        GarageService.completeGarageEvent(dispatch, garageId, event.id)
          .then((response) => {
            purgeLocalState();

            // Fermeture de la boite de dialogue (avec refresh des events)
            onClose(true);

            //Affichage notification utilisateur
            dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
          })
          .catch((error) => {
            console.error(error);
            dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
          });
      } else {
        GarageService.uncompleteGarageEvent(dispatch, garageId, event.id)
          .then((response) => {
            purgeLocalState();

            // On ne ferme pas la dialog box mais provoque juste le refresh des events
            if (onRefresh) onRefresh();

            //Affichage notification utilisateur
            dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
          })
          .catch((error) => {
            console.error(error);
            dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
          });
      }
    }
  };

  const handleAcceptEventRequest = async () => {
    if (event && garageId) {
      // Préparation de la requête
      const formData = new FormData();

      // Ajouter le devis à FormData si présent et si paramétre garage actif
      if (localGarage?.customerQuoteActive) {
        if (form.quote?.value) {
          formData.append('quote', form.quote.value);
        }

        if (form.quoteAmount?.value) {
          formData.append('quoteAmount', form.quoteAmount.value);
        }
      }

      GarageService.acceptGarageEvent(dispatch, garageId, event.id, formData)
        .then((response) => {
          purgeLocalState();

          // Fermeture de la boite de dialogue (avec refresh des events)
          onClose(true);

          //Affichage notification utilisateur
          dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

          return Promise.resolve();
        })
        .catch((error) => {
          console.error(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
          //return Promise.reject();
        });
    }
    return Promise.reject();
  };

  const handleCloseUploadInvoice = () => {
    setOpenUploadInvoice(false);
    closeEvent(null);
  };

  const closeEvent = (invoice: File | null) => {
    if (event && garageId) {
      // Utilisation d'un formData pour permettre le trasnfert de fichier vers l'API
      const formData = new FormData();

      // Ajouter la facture à FormData si présente
      if (invoice) {
        formData.append('invoice', invoice);
        // Ajouter l'identifiant de l'événement en référence au document
        formData.append('reference', event.id);
      }

      GarageService.closeGarageEvent(dispatch, garageId, event.id, formData)
        .then((response) => {
          purgeLocalState();

          // Fermeture de la boite de dialogue (avec refresh des events)
          onClose(true);

          //Affichage notification utilisateur
          dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

          // On recharge le véhicule
          refreshSelectedVehicle();
        })
        .catch((error) => {
          console.error(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        });
    }
  };

  const handleConfirmUploadInvoice = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      closeEvent(event.target.files[0]);
    } else {
      closeEvent(null);
    }
  };

  const handleCancelUploadInvoice = () => {
    setOpenUploadInvoice(false);
  };

  const handleEditEvent = () => {
    // On initialise le formulaire de modifications
    setForm((prevForm) => ({
      ...prevForm,
      start: { ...prevForm['start'], value: localEvent.start },
      end: { ...prevForm['end'], value: localEvent.end },
    }));

    setEditMode(true);
  };

  function errorMessage() {
    setSnackbar({
      open: true,
      message: 'Une erreur est survenue lors de la récupération des informations du client',
      severity: 'error',
    });
  }
  const handleEditVehicle = () => {
    setLoadingEditVehicle(true);
    CustomerService.searchCustomers(
      garageId,
      localEvent.customer?.companyName ?? `${localEvent.customer?.lastname} ${localEvent.customer?.firstname}`,
    )
      .then((response) => {
        if (response.success) {
          setForm((prevForm) => ({
            ...prevForm,
            vehicleId: { ...prevForm['vehicleId'], value: localEvent.vehicle?.id },
            vehiclePlate: { ...prevForm['vehiclePlate'], value: localEvent.vehicle?.plate },
            customer: {
              ...prevForm['customer'],
              value: response?.data?.find((c) => c.id === localEvent?.customer?.id),
            },
          }));
          setEditVehicle(true);
        } else {
          errorMessage();
        }
      })
      .catch(() => {
        errorMessage();
      })
      .finally(() => {
        setLoadingEditVehicle(false);
      });
  };

  const onChangeVehicle = () => {
    const req = {
      vehiclePlate: formatFrenchVehiclePlate(
        form.customer?.value.vehicles.find((v: Vehicle) => v.id === form.vehicleId?.value)?.plate,
      ),
    };
    CoreGarageService.updateVehicleGarageEvent(garageId, event.id, req).then((response) => {
      if (response.success) {
        dispatch(
          setSnackbar({
            open: true,
            message: response.data ?? 'Le véhicule a bien été ajouté',
            severity: 'success',
          }),
        );
        refreshEvent();
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: 'Ajout du véhicule impossible',
            severity: 'error',
          }),
        );
      }
      setEditVehicle(false);
    });
  };

  const handleVehicleEdit = () => {
    // On initialise le formulaire de modifications
    setForm((prevForm) => ({
      ...prevForm,
      vehicleDepositPreference: {
        ...prevForm['vehicleDepositPreference'],
        value: getVehicleDepositPreferenceFromEvent(localEvent),
      },
      vehicleDepositDate: { ...prevForm['vehicleDepositDate'], value: localEvent.vehicleDepositDate },
    }));

    setEditVehicleDeposit(true);
  };

  const getDefaultUpdateSchedulingCustomerMessage = () => {
    return `Bonjour, votre rendez-vous chez ${localGarage.name} a été décalé au ${formatDateByCountryCode(
      garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD
        ? (movedDepositDate.current ?? form.vehicleDepositDate?.value)
        : form.start?.value,
      'FR',
      DateFormatTypes.LONG_FORMAT_DATETIME,
    )}. Bonne journée. Suivi : app.movalib.com`;
  };

  const handleValidEditEvent = () => {
    if (form.start.value && form.end.value) {
      // On contrôle le mode de fonctionnement du garage
      if (
        garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD &&
        form.start.value.getTime() !== localEvent.start?.getTime()
      ) {
        movedDepositDate.current = new Date(getEventVehicleDepositDate(new Date(form.start.value), garage));
        toggleEditVehiculeDepositDialogOpened();
      } else {
        // Si un changement d'heure de début est détecté et qu'il s'agit d'un rendez-vous accepté, on demande si le garage souhaite prévenir son client ou non
        if (form.start.value.getTime() !== localEvent.start?.getTime() && localEvent.state !== EventState.NEW) {
          // On initialise le message destiné au client
          setForm((prevForm) => ({
            ...prevForm,
            updateSchedulingCustomerMessage: {
              ...prevForm['updateSchedulingCustomerMessage'],
              value: getDefaultUpdateSchedulingCustomerMessage(),
            },
          }));
          setOpenDepositVehicleChangeDialog(true);
        } else {
          // Sinon on pousse l'update
          updateEvent();
        }
      }
    }
  };

  const isEventChanged = (): boolean => {
    return (
      (form.start.value as Date).getTime() !== (localEvent.start as Date).getTime() ||
      (form.end.value as Date).getTime() !== (localEvent.end as Date).getTime() ||
      (form.vehicleDepositDate?.value as Date).getTime() !== (localEvent.vehicleDepositDate as Date).getTime() ||
      form.quoteId?.value !== localEvent.quoteId ||
      form.notes?.value !== localEvent.notes ||
      form.otherReason?.value !== localEvent.otherReason
    );
  };

  const [openConflictLoanVehicleDialog, setOpenConflictLoanVehicleDialog] = useState(false);
  const oncloseConflictLoanVehicleDialog = () => {
    setOpenConflictLoanVehicleDialog(false);
  };

  const updateEvent = async (force?: boolean) => {
    // On déclenche l'appel API uniquement si une donnée a été modifiée
    if (isEventChanged() || force) {
      setLoading(true);

      //Gestion temporaire de conflit sur les véhicules de prêts
      if (
        garage.loanerVehicleActive &&
        typeof form.garageVehicleId?.value === typeof 666 &&
        (form.start.value.getUTCDate() !== localEvent?.start?.getUTCDate() ||
          form.end.value.getUTCDate() !== localEvent?.end?.getUTCDate())
      ) {
        if (!isLoanVehicleAvailable(form.start.value, form.end.value, loanVehicleByDate, form.garageVehicleId?.value)) {
          setForm((prevForm) => ({ ...prevForm, garageVehicleId: { ...prevForm['garageVehicleId'], value: '' } }));
          setOpenConflictLoanVehicleDialog(true);
          await CoreGarageService.deassignGarageVehicleEvent(garageId, localEvent.id);
        }
      }

      // On repousse les données actuellement gérées par la méthode d'update côté API
      const req = {
        startDate: form.start.value,
        endDate: form.end.value,
        quoteId: form.quoteId?.value,
        notes: form.notes?.value,
        vehicleDepositDate: movedDepositDate?.current ?? form.vehicleDepositDate?.value,
        schedulingUpdateMessage:
          form.updateSchedulingAlert?.value === true ? form.updateSchedulingCustomerMessage?.value : '',
      };

      Logger.info(req);

      CoreGarageService.updateGarageEvent(garageId, event.id, req)
        .then((response) => {
          if (response.success) {
            // Réinitialisation du message client
            setForm((prevForm) => ({
              ...prevForm,
              updateSchedulingCustomerMessage: { ...prevForm['updateSchedulingCustomerMessage'], value: '' },
            }));

            setEditMode(false);
            setEditVehicle(false);
            setEditVehicleDeposit(false);
            setVehicleEditMode(false);

            setLocalEvent((prevEvent) => ({
              ...prevEvent,
              garageVehicleId: form.garageVehicleId?.value,
              start: form.start.value as Date,
              end: form.end.value as Date,
              quoteId: form.quoteId?.value,
              notes: form.notes?.value,
              // Refresh affichage de l'heure de dépôt véhicule uniquement si garage à l'heure fixe et nouveau RDV
              vehicleDepositDate:
                localGarage.slotAlgorithm === SlotAlgorithm.HOUR && localEvent.state === EventState.NEW
                  ? (form.start.value as Date)
                  : (form.vehicleDepositDate?.value as Date),
            }));

            // On ne ferme pas la dialog box mais provoque juste le refresh des events
            if (onRefresh) onRefresh();

            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? 'Votre rendez-vous a bien été mis à jour',
                severity: 'success',
              }),
            );
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? 'Mise à jour du rendez-vous impossible',
                severity: 'error',
              }),
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCancelEditEvent = () => {
    setEditMode(false);
  };

  const handleCancelEditVehicleDeposit = () => {
    setEditVehicleDeposit(false);
  };

  const handleValidEditVehicleDeposit = () => {
    if (form.vehicleDepositDate?.value) {
      // Si un changement d'heure de début est détecté et que nous sommes sur un rendez-vou actif, nous demandons si le garage souhaite prévenir son client ou non
      if (
        (movedDepositDate.current?.getTime() ?? form.vehicleDepositDate.value.getTime()) !==
          localEvent.vehicleDepositDate?.getTime() &&
        localEvent.state !== EventState.NEW
      ) {
        // On initialise le message destiné au client
        setForm((prevForm) => ({
          ...prevForm,
          updateSchedulingCustomerMessage: {
            ...prevForm['updateSchedulingCustomerMessage'],
            value: getDefaultUpdateSchedulingCustomerMessage(),
          },
        }));
        setOpenDepositVehicleChangeDialog(true);
      } else {
        // Sinon on pousse l'update directement
        updateEvent();
      }
    }
  };

  /**
   * Déclenche la recherche clients en utilisant "debounce()" qui permet de décaler l'exécution d'une durée exprimée en MS
   */
  const debouncedCustomersSearch = debounce(
    (
      query: string,
      dispatch: AppDispatch,
      garageId: string,
      setCustomers: Function,
      setSnackbar: Function,
      autoSelect: boolean,
    ) => {
      CustomerService.searchCustomers(garageId, query)
        .then((response) => {
          if (response.success) {
            setCustomers(response.data ? response.data : []);
            if (autoSelect && response.data && response.data.length === 1) {
              setAutocompleteOpen(false);
              handleChange('customer', response.data[0]);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        });
      // Durée du debounce en MS
    },
    300,
  );

  const handleCustomerInputChange = (query: string, autoSelect = false) => {
    if (garageId && query.length >= 2) {
      debouncedCustomersSearch(query, dispatch, garageId, setCustomers, setSnackbar, autoSelect);
    } else {
      setCustomers([]);
    }
  };

  /**
   * Filtre personnalisé pour la zone de recherche du client
   * Permet de filtrer la liste des résultat selon les termes de recherche (nom et/ou prénom)
   * sans tenir compte de l'ordre des termes (nom/prénom ou prénom/nom)
   * @param options
   * @param param1
   * @returns
   */
  /*   const customCustomersFilter = (options: Customer[], { inputValue }: { inputValue: string }) => {
    const matcher = new RegExp(inputValue.replace(/\s+/g, '|'), 'i');
    return options.filter((option) => {
      const label = `${option.lastname.toUpperCase()} ${option.firstname}`;
      return matcher.test(label);
    });
  }; */

  /*   const customFilterOptions = (options: Customer[], { inputValue }: { inputValue: string }) => {
    return options.filter((option) => {
      const searchTerm = inputValue.toLowerCase();
      const lastname = option.lastname ? option.lastname.toLowerCase() : '';
      const firstname = option.firstname ? option.firstname.toLowerCase() : '';
      const phoneNumber = option.phoneNumber ? option.phoneNumber.toLowerCase() : '';
      return lastname.includes(searchTerm) || firstname.includes(searchTerm) || phoneNumber.includes(searchTerm);
    });
  }; */

  const customFilterOptions = (options: Customer[], { inputValue }: { inputValue: string }) => {
    return options.filter((option) => {
      const searchTerm = inputValue.toLowerCase();

      // Vérification des plaques d'immatriculation
      const plateMatch = option.vehicles?.some((vehicle) => vehicle.plate?.toLowerCase().includes(searchTerm));

      // Si le client est professionnel, recherche sur companyName, phoneNumber et companyPhoneNumber
      if (option.type === CustomerType.PROFESSIONAL) {
        const companyName = option.companyName ? option.companyName.toLowerCase() : '';
        const phoneNumber = option.phoneNumber ? option.phoneNumber.toLowerCase() : '';
        const companyPhoneNumber = option.companyPhoneNumber ? option.companyPhoneNumber.toLowerCase() : '';

        return (
          companyName.includes(searchTerm) ||
          phoneNumber.includes(searchTerm) ||
          companyPhoneNumber.includes(searchTerm) ||
          plateMatch // Recherche sur la plaque d'immatriculation
        );
      }

      // Si le client est individuel, recherche sur lastname, firstname, phoneNumber et plaque d'immatriculation
      if (option.type === CustomerType.INDIVIDUAL) {
        const lastname = option.lastname ? option.lastname.toLowerCase() : '';
        const firstname = option.firstname ? option.firstname.toLowerCase() : '';
        const fullName = `${lastname} ${firstname}`; // Nom complet : nom + prénom
        const reversedFullName = `${firstname} ${lastname}`; // Inversé : prénom + nom
        const phoneNumber = option.phoneNumber ? option.phoneNumber.toLowerCase() : '';

        return (
          fullName.includes(searchTerm) || // Recherche nom + prénom
          reversedFullName.includes(searchTerm) || // Recherche prénom + nom
          lastname.includes(searchTerm) || // Recherche sur le nom seul
          firstname.includes(searchTerm) || // Recherche sur le prénom seul
          phoneNumber.includes(searchTerm) || // Recherche sur le numéro de téléphone
          plateMatch // Recherche sur la plaque d'immatriculation
        );
      }

      return false;
    });
  };

  /**
   *  GESTIONNAIRES D'EVENNEMENTS FICHE VEHICLE
   */

  const handleOnVehicleError = (message: string) => {
    if (message) {
      Logger.error(message);
      dispatch(setSnackbar({ open: true, message: message, severity: 'error' }));
    }
  };

  const handleOnVehicleUpdate = (form: MovaVehicleForm) => {
    if (event.vehicle && form) {
      setLoading(true);

      // On crée la requête pour l'API
      const query = {
        currentMileage: form.currentMileage.value,
        averageMileagePerYear: form.averageMileagePerYear.value,
        tireWidth:
          form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).width : undefined,
        tireHeight:
          form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).height : undefined,
        tireDiameter:
          form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).diameter : undefined,
        tireSpeedIndex:
          form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).speedIndex : undefined,
      };

      VehicleService.updateVehicle(dispatch, event.vehicle.id, query)
        .then((response) => {
          Logger.info(response);

          // Affichage notification utilisateur
          dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

          setVehicleEditMode(false);

          // On ne ferme pas la dialog box de l'évènement mais provoque juste le refresh des events
          if (onRefresh) onRefresh();
        })
        .catch((error) => {
          Logger.info(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleOnVehicleUploadDocument = (data: FormData) => {
    if (event.vehicle && data) {
      setLoading(true);
      setCurrentUpload(true);
      // Téléchargement du document
      CoreVehicleService.uploadVehicleDocument(MovaAppType.GARAGE, event.vehicle.id, data)
        .then((response) => {
          // Affichage notification utilisateur
          setSnackbar({ open: true, message: response.data as string, severity: 'success' });

          refreshSelectedVehicle();
        })
        .catch((error: string) => {
          Logger.info(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          setCurrentUpload(false);
          setLoading(false);
        });
    }
  };

  const handleOnVehicleDeleteDocument = (documentId: string) => {
    if (event.vehicle && documentId) {
      setLoading(true);

      // Récupération des documents du véhicule
      CoreVehicleService.deleteVehicleDocument(MovaAppType.GARAGE, event.vehicle.id, documentId)
        .then((response) => {
          Logger.info(response);

          // Affichage notification utilisateur
          dispatch(setSnackbar({ open: true, message: response.data as string, severity: 'success' }));

          refreshSelectedVehicle();
        })
        .catch((error) => {
          Logger.info(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, docType: DocumentType) => {
    event.preventDefault();

    if (event && event.target.files && event.target.files.length > 0 && docType) {
      switch (docType) {
        case DocumentType.VEHICLE_MAINTENANCE_INVOICE:
        case DocumentType.VEHICLE_TIRE_PHOTO:
          // L'upload d'un document véhicule se fait directement lors de l'ajout
          uploadVehicleDocument(event.target.files[0], docType);
          break;
      }
    }
  };

  const uploadVehicleDocument = (document: File, documentType: DocumentType) => {
    if (event.vehicle && document && documentType) {
      setLoading(true);

      // Utilisation d'un formData pour permettre le trasnfert de fichier vers l'API
      const formData = new FormData();
      formData.append('documentType', documentType);
      // Ajouter la facture à FormData
      formData.append('file', document);
      // Ajouter l'identifiant de l'événement en référence au document
      formData.append('reference', event.id);

      // Téléchargement du document
      CoreVehicleService.uploadVehicleDocument(MovaAppType.GARAGE, event.vehicle.id, formData)
        .then((response) => {
          // Affichage notification utilisateur
          dispatch(setSnackbar({ open: true, message: response.data as string, severity: 'success' }));

          // On recharge le véhicule
          refreshSelectedVehicle();
        })
        .catch((error) => {
          Logger.info(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleOpenAddCustomer = () => {
    setLocalOpen(false);
    setOpenAddCustomer(true);
  };

  const handleOpenEditCustomer = (customer: Customer) => {
    setLocalOpen(false);
    setSelectedCustomer(customer);
    setOpenEditCustomer(true);
  };

  const handleCloseEditCustomer = (refresh: boolean) => {
    setOpenEditCustomer(false);
    setLocalOpen(true);

    const name =
      selectedCustomer?.type === CustomerType.INDIVIDUAL ? selectedCustomer?.lastname : selectedCustomer?.companyName;
    reloadCustomer(name);

    setSelectedCustomer(null);
    // On refresh l'event uniquement en cas de non création
    if (refresh && MyEventDialog.DETAILS === type) {
      updateEvent(true);
    }
  };

  const handleCloseAddCustomer = (customerLastName?: string | null | undefined) => {
    setOpenAddCustomer(false);
    setLocalOpen(true);
    if (customerLastName) {
      reloadCustomer(customerLastName, true);
    }
  };

  const reloadCustomer = (customerLastName?: string, autoSelect = false) => {
    // On alimente la zone de recherche client avec le nom du nouveau client si transmis et on déclenche la recherche
    if (customerLastName) {
      setCurrentCustomerInput(customerLastName);
      // On déclenche la recherche
      handleCustomerInputChange(customerLastName, autoSelect);
      // On ouvre la boite de sélection du client
      setAutocompleteOpen(true);
    }
  };

  const handleCheckProduct = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean, productId: string) => {
    if (form.checkedProducts?.value && productId) {
      const array = form.checkedProducts?.value as string[];
      const idExists = array.includes(productId);

      // On doit créer une nouvelle variable pour ensuite la passer dans le setForm ..
      let newArray = array;

      // Si checked est true et que l'ID n'est pas déjà présent, ajoutez-le
      if (checked && !idExists) {
        newArray = array.concat(productId);
      }

      // Si checked est false et que l'ID est déjà présent, supprimez-le
      if (!checked && idExists) {
        newArray = array.filter((existingId) => existingId !== productId);
      }

      setForm((prevForm) => ({ ...prevForm, checkedProducts: { ...prevForm['checkedProducts'], value: newArray } }));
    }
  };

  const handleCheckOtherPart: CheckboxProps['onChange'] = (_, checked) => {
    if (!checked) {
      setForm((prevForm) => ({
        // suppression de l'ancien produit s'il existe grace au filtre
        ...prevForm,
        others: {
          ...prevForm.others,
          value: [...(prevForm['others']?.value as string[]).filter((product) => !product.includes(PART_KEYWORD))],
        },
      }));
    }
    toggleOtherPartFieldVisible();
  };

  const handleCheckOtherConsumable: CheckboxProps['onChange'] = (_, checked) => {
    if (!checked) {
      setForm((prevForm) => ({
        // suppression de l'ancien produit s'il existe grace au filtre,
        // puis Ajout du nouveau produit
        ...prevForm,
        others: {
          ...prevForm.others,
          value: [
            ...(prevForm['others']?.value as string[]).filter((product) => !product.includes(CONSUMABLE_KEYWORD)),
          ],
        },
      }));
    }
    toggleOtherConsumableFieldVisible();
  };

  const handleCheckVehicleReceived: CheckboxProps['onChange'] = (_, checked) => {
    setLoading(true);

    CoreGarageService.toogleEventVehicleReceived(garageId, localEvent.id)
      .then((response) => {
        if (response.success) {
          // On refresh l'event
          refreshEvent();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Une erreur est survenue',
              severity: 'error',
            }),
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeOtherPart = debounce<NonNullable<TextFieldProps['onChange']>>((e) => {
    // Changement de la valeur qui contient le PART_KEYWORD s'il est déjà présent
    setForm((prevForm) => ({
      // suppression de l'ancien produit s'il existe grace au filtre,
      // puis Ajout du nouveau produit
      ...prevForm,
      others: {
        ...prevForm.others,
        value: [
          ...(prevForm['others']?.value as string[]).filter((product) => !product.includes(PART_KEYWORD)),
          `${PART_KEYWORD}${e.target.value}`,
        ],
      },
    }));
  }, 300);

  const handleChangeOtherConsumable = debounce<NonNullable<TextFieldProps['onChange']>>((e) => {
    // Changement de la valeur qui contient le CONSUMABLE_KEYWORD s'il est déjà présent
    setForm((prevForm) => ({
      // suppression de l'ancien produit s'il existe grace au filtre,
      // puis Ajout du nouveau produit
      ...prevForm,
      others: {
        ...prevForm.others,
        value: [
          ...(prevForm['others']?.value as string[]).filter((product) => !product.includes(CONSUMABLE_KEYWORD)),
          `${CONSUMABLE_KEYWORD}${e.target.value}`,
        ],
      },
    }));
  }, 300);

  const isOrderedPossible = (): boolean => {
    return Boolean(
      (isOtherPartFieldVisible && (form.others?.value as string[]).length > 0) ||
        (isOtherConsumableFieldVisible && (form.others?.value as string[]).length > 0) ||
        (form.checkedProducts?.value as string[]).length > 0,
    );
  };

  const haveOrderPortail = (): boolean => {
    return !!localGarage?.suppliers?.find((s) => s.id === form.supplier?.value)?.orderPortalUrl;
  };
  const haveSupplierPhoneNumber = (): boolean => {
    return !!localGarage?.suppliers?.find((s) => s.id === form.supplier?.value)?.phoneNumber;
  };
  const haveSupplierEmail = (): boolean => {
    return !!localGarage?.suppliers?.find((s) => s.id === form.supplier?.value)?.email;
  };

  /**
   * Contrôle l'activation des boutons d'envoie "Demande de devis / Bon de commande"
   * @returns boolean
   */
  const isSupplierRequestPossible = (): boolean => {
    const result = false;

    if (form.checkedProducts && form.orderPreference && form.supplier) {
      return Boolean(form.orderPreference.value !== '' && form.supplier.value !== '');
    }

    return result;
  };

  const defaultTooltipSupplierDisabledButton = () => {
    return (
      !(isSupplierRequestPossible() && isOrderedPossible()) && (
        <span>
          {!isOrderedPossible() ? 'Cochez un produit (ou Autre...)' : ''}
          <br style={{ display: isOrderedPossible() ? 'none' : '' }} />
          {!isSupplierRequestPossible() ? 'Choisissez un Fournisseur' : ''}
        </span>
      )
    );
  };

  const handleAddSupplier = () => {
    setOpenAddSupplier(true);
  };

  const handleCloseAddSupplier = (refresh: boolean) => {
    setOpenAddSupplier(false);
    if (refresh) refreshGarage();
  };

  const handleOpenSupplierRequest = (request: SupplierRequestType) => {
    if (request) {
      if (request === SupplierRequestType.WEB) {
        if (localGarage?.suppliers && form.supplier?.value) {
          const foundedSupplier: Supplier = localGarage.suppliers.filter((s) => s.id === form.supplier?.value)[0];
          if (localEvent?.vehicle?.plate) {
            navigator.permissions.query({ name: 'clipboard-write' as PermissionName }).then((result) => {
              if (result.state === 'granted' || result.state === 'prompt') {
                // Si la permission est accordée ou nécessitant une interaction
                navigator.clipboard
                  .writeText(localEvent?.vehicle?.plate!)
                  .then(() => {
                    dispatch(
                      setSnackbar({
                        open: true,
                        message: "La plaque d'immatriculation a été copiée dans le presse-papiers",
                        severity: 'success',
                      }),
                    );
                  })
                  .catch((err) => {
                    dispatch(
                      setSnackbar({
                        open: true,
                        message: 'Erreur lors de la copie dans le presse-papiers',
                        severity: 'error',
                      }),
                    );
                  });
              } else {
                dispatch(
                  setSnackbar({
                    open: true,
                    message: "L'accès au presse-papiers est refusé.",
                    severity: 'warning',
                  }),
                );
              }
            });
          }
          // on a besoin du focus sur la page movalibPro pour le clipboard
          setTimeout(() => {
            const handle = window.open(foundedSupplier.orderPortalUrl, '_blank');
          }, 500);
        }
      } else {
        setSupplierRequestType(request);
        setOpenSupplierRequest(true);
      }
    }
  };

  const handleOpenOrdered = () => {
    // Si présence d'un commentaire commande on le set par défaut sur le form
    setForm((prevForm) => ({
      ...prevForm,
      comment: {
        value: localEvent.orderComment,
      },
    }));
    setOpenOrdered(true);
  };

  const handleCloseOrdered = () => {
    setForm((prevForm) => ({
      ...prevForm,
      comment: {
        value: '',
      },
    }));
    setOpenOrdered(false);
  };

  const handleCloseSupplierRequest = () => {
    setDataPurchaseOrder(undefined);
    supplierModified.current = undefined;
    setOpenSupplierRequest(false);
  };

  const handleCommentValidation = (): boolean => {
    if (
      (form.checkedProducts?.value as string[]).length === 0 &&
      (form.others?.value as string[]).length === 0 &&
      form.comment
    ) {
      const newForm: EventForm = { ...form };
      newForm.comment = validateField(form.comment, (value) => !!value, 'Champ obligatoire');
      setForm(newForm);

      if (!newForm.comment.isValid) {
        return false; // Validation échouée, on retourne false
      }
    }
    return true; // Validation réussie
  };

  const getOthers = (): SupplierRequestOthersDTO[] => {
    return (
      form.others?.value
        ?.map((product: string): SupplierRequestOthersDTO | undefined => {
          if (product.includes(PART_KEYWORD)) {
            return {
              type: ProductType.PART,
              product: product.replace(PART_KEYWORD, ''),
            };
          }
          if (product.includes(CONSUMABLE_KEYWORD)) {
            return {
              type: ProductType.CONSUMABLE,
              product: product.replace(CONSUMABLE_KEYWORD, ''),
            };
          }
          return undefined;
        })
        .filter((item: SupplierRequestOthersDTO | undefined): item is SupplierRequestOthersDTO => !!item) || []
    );
  };
  const targetRef = useRef();
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState<any>(undefined);
  const handleConfirmSupplierRequest = () => {
    // Si aucun produits en sélection, on contrôle la présence d'un commentaire
    if (!handleCommentValidation()) {
      return;
    }

    // Envoi d'une demande au grossiste (appel API pour envoi d'un email)
    if (garageId && event.id && form.checkedProducts?.value && form.supplier?.value && form.orderPreference?.value) {
      setLoading(true);

      const others = getOthers();

      // On crée la requête pour l'API
      const query = {
        type: supplierRequestType,
        supplierId: form.supplier?.value,
        orderPreference: form.orderPreference?.value,
        products: form.checkedProducts?.value,
        others,
        comment: form.comment?.value,
      };

      if (supplierRequestType === SupplierRequestType.ORDER) {
        CoreGarageService.sendSupplierRequest(garageId, event.id, query)
          .then((response) => {
            if (response.success) {
              dispatch(
                setSnackbar({
                  open: true,
                  message: response.data ?? 'Transmission de la demande effectuée avec succès',
                  severity: 'success',
                }),
              );
              setOpenSupplierRequest(false);
              // On refresh l'event pour afficher le nouvel état des produits
              refreshEvent();
            } else {
              dispatch(
                setSnackbar({
                  open: true,
                  message: response.error ?? 'Transmission de la demande impossible',
                  severity: 'error',
                }),
              );
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        const foundedSupplier = localGarage?.suppliers?.filter((s) => s.id === form.supplier?.value)[0];
        const productChecked: [{ name: string; frenchApplication: string }] = [] as any;
        form.checkedProducts?.value.map((productId: string, index: number) =>
          productChecked.push({
            name: localEvent?.products?.filter((p) => p.id === productId)[0].name ?? '',
            frenchApplication: getApplicationShortLabel(getProduct(productId)?.application) as string,
          }),
        );
        setDataPurchaseOrder({
          garageContactPhone: garage.contactPhone,
          garageContactEmail: garage.contactEmail,
          garageAddress: `${garage.address.streetName} ${garage.address.postalCode} ${garage.address.cityName}`,
          garageName: garage.name,
          eventStartDate: format(localEvent.start as Date, "EEEE d MMMM yyyy 'à' HH'h'mm", { locale: fr }),
          eventVehicleLabel: `${localEvent.vehicle?.brand} ${localEvent.vehicle?.model} ${localEvent.vehicle?.version}`,
          eventVehiclePlate: formatFrenchVehiclePlate(localEvent.vehicle?.plate),
          customerID: foundedSupplier?.customerId,
          requestComment: query.comment,
          requestOrderPreference: query.orderPreference,
          requestProducts: productChecked,
          others: query.others,
          eventPrestations: localEvent?.prestations,
        });

        setOpenSupplierRequest(false);
      }
    }
  };
  const [loadingPdf, setLoadingPdf] = useState(false);
  useEffect(() => {
    if (dataPurchaseOrder && !openSupplierRequest) {
      setLoadingPdf(true);
      generatePDF(targetRef, {
        filename: `Bon_de_commande_${localEvent?.vehicle?.plate ?? 'IMMATRICULATION'}_${format(localEvent?.start as Date, 'dd-MM-yyyy')}`,
      }).finally(() => {
        setLoadingPdf(false);
      });
    }
  }, [dataPurchaseOrder, openSupplierRequest]);

  const handleConfirmOrdered = () => {
    // Si aucun produits en sélection, on contrôle la présence d'un commentaire
    if (!handleCommentValidation()) {
      return;
    }

    // Appel API pour passage des produits en ORDERED et dépôt du commentaire
    if (garageId && event.id && form.checkedProducts?.value) {
      setLoading(true);

      const others = getOthers();

      // On crée la requête pour l'API
      const query = {
        products: form.checkedProducts?.value,
        others,
        comment: form.comment?.value,
      };

      CoreGarageService.setOrderedEvent(garageId, event.id, query)
        .then((response) => {
          if (response.success) {
            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? 'Enregistrement de la commande effectuée avec succès',
                severity: 'success',
              }),
            );
            setOpenOrdered(false);
            // On refresh l'event pour afficher le nouvel état des produits
            refreshEvent();
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? 'Enregistrement de la demande impossible',
                severity: 'error',
              }),
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleNewQuote = () => {
    // On commence par supprimer du formulaire le devis existant
    handleChange('quote', '');
    setForm((prevForm) => ({ ...prevForm, quoteAmount: { ...prevForm['quoteAmount'], value: '' } }));

    setShowSendNewQuoteAction(true);
  };

  const handleSendNewQuote = () => {
    if (!(form.quoteAmount?.value !== '') && !form.quote?.value) {
      dispatch(
        setSnackbar({
          open: true,
          message: 'Merci indiquer le montant du nouveau devis OU de joindre le document',
          severity: 'error',
        }),
      );
      return;
    }

    setLoading(true);

    // Préparation de la requête
    const formData = new FormData();

    // Ajouter le devis à FormData si présent et si paramétre garage actif
    if (localGarage?.customerQuoteActive) {
      if (form.quote?.value) {
        formData.append('quote', form.quote.value);
      }

      if (form.quoteAmount?.value) {
        formData.append('quoteAmount', form.quoteAmount.value);
      }
    }

    // Transmission du nouveau devis au client
    CoreGarageService.sendNewEventQuote(garageId, event.id, formData)
      .then((response) => {
        if (response.success) {
          // Fermeture de la boite de dialogue (avec refresh des events)
          onClose(true);

          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'Devis envoyé au client avec succès',
              severity: 'success',
            }),
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Echec lors de l'envoi du devis au client",
              severity: 'error',
            }),
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setShowSendNewQuoteAction(true);
      });
  };

  const getSupplierLabel = (supplierId: number): ReactNode => {
    if (localGarage?.suppliers && supplierId) {
      // On récupère le premier supplier qui match avec l'id demandé
      const foundedSupplier: Supplier = localGarage.suppliers.filter((s) => s.id === supplierId)[0];
      return foundedSupplier ? (
        <>
          <b>{foundedSupplier.name}</b> ({foundedSupplier.email})
        </>
      ) : (
        <>Inconnu</>
      );
    }

    return <>Inconnu</>;
  };

  const getGarageVehicleById = (id: number): VehicleGarage => {
    return garage?.vehicles?.find((vehicle) => vehicle.id === id) as VehicleGarage;
  };
  const getSupplierPhoneNumber = (supplierId: number): ReactNode => {
    if (localGarage?.suppliers && supplierId) {
      const foundedSupplier: Supplier = localGarage.suppliers.filter((s) => s.id === supplierId)[0];
      return foundedSupplier.phoneNumber ? (
        <b>{formatPhoneNumber(foundedSupplier.phoneNumber)}</b>
      ) : (
        <b>N° téléphone</b>
      );
    }
    return <>N° téléphone</>;
  };

  const getSupplierCustomerId = (supplierId: number): ReactNode => {
    if (localGarage?.suppliers && supplierId) {
      const foundedSupplier: Supplier = localGarage.suppliers.filter((s) => s.id === supplierId)[0];
      return foundedSupplier ? (
        foundedSupplier.customerId ? (
          <b>{foundedSupplier.customerId}</b>
        ) : (
          <Typography
            onClick={() => {
              supplierModified.current = foundedSupplier;
              setOpenAddSupplier(true);
            }}
            variant='body1'
            sx={{ animation: 'blink 1.5s infinite', cursor: 'pointer', alignItems: 'center', display: 'flex' }}
            color={theme.palette.warning.dark}
          >
            <AddIcon />
            &nbsp;SAISIR LE NUMERO CLIENT
          </Typography>
        )
      ) : (
        <>Inconnu</>
      );
    }

    return <>Inconnu</>;
  };

  const getProduct = (productId: string): Product | undefined => {
    if (localEvent?.products && productId) {
      // On récupère le premier product qui match avec l'id demandé
      const foundedProduct: Product = localEvent.products.filter((p) => p.id === productId)[0];
      return foundedProduct;
    }
    return undefined;
  };

  const getProductLabel = (productId: string): ReactNode => {
    if (localEvent?.products && productId) {
      // On récupère le premier product qui match avec l'id demandé
      const foundedProduct: Product = localEvent.products.filter((p) => p.id === productId)[0];
      return foundedProduct ? <b>{foundedProduct.name}</b> : <>Inconnu</>;
    }

    return <>Inconnu</>;
  };

  const getAddQuoteContainer = (): ReactNode => {
    return (
      <Grid item>
        <input
          accept='image/*, application/pdf'
          type='file'
          style={{ display: 'none' }}
          ref={quoteInputRef}
          id='raised-button-quote'
          disabled={localEvent.state === EventState.REJECTED && !showSendNewQuoteAction}
          onChange={(e) => handleChange('quote', e.target.files ? e.target.files[0] : '')}
        />
        <label htmlFor='raised-button-quote'>
          <Button
            size='small'
            component='span'
            color='inherit'
            startIcon={<AttachFile />}
            disableRipple
            disabled={localEvent.state === EventState.REJECTED && !showSendNewQuoteAction}
            sx={{ backgroundColor: theme.palette.grey[200] }}
          >
            Joindre
          </Button>
        </label>
      </Grid>
    );
  };
  const getQuoteGrid = (): ReactNode => {
    return (
      <Grid container>
        <Grid
          item
          xs={1}
          sx={{ mt: 2, mb: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center' }}
        >
          <QuoteIcon
            sx={{ margin: 'auto', color: MyEventDialog.CREATE === type ? '' : theme.palette.grey[500] }}
            className={MyEventDialog.CREATE === type ? 'styled-icon' : ''}
          />
        </Grid>
        <Grid item xs={12} md={11} sx={{ mt: 2, mb: 1, ml: { xs: 1, md: 0 } }}>
          <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
            {(!form.quote?.value?.id || localEvent.state === EventState.ACCEPTED) && (
              <Grid item xs={7} md={6}>
                <TextField
                  id='event-quote'
                  label='Montant du devis'
                  value={form.quoteAmount?.value}
                  onChange={(e) => handleChange('quoteAmount', e.target.value)}
                  variant='outlined'
                  size='small'
                  type='number'
                  fullWidth
                  error={Boolean(form.quoteAmount?.error)}
                  helperText={form.quoteAmount?.error}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Euro />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& input': { fontWeight: 'bold' }, // CSS pour modifier le style dans l'input
                  }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={!form.quote?.value?.id || localEvent.state === EventState.ACCEPTED ? 5 : 11}
              sx={{ textAlign: 'left', pl: 4 }}
            >
              <>
                {form.quote?.value && (
                  <Grid container>
                    <Grid item xs={12}>
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {form.quote.value instanceof File ? (
                          <Typography>{truncateText((form.quote.value as File).name, 25)}</Typography>
                        ) : (
                          <Link
                            color={darken(theme.palette.info.main, 0.2)}
                            href={(form.quote.value as Document).fileSignedUrl}
                            target='_blank'
                            rel='noopener'
                          >
                            <Typography>
                              Devis du{' '}
                              {formatDateByCountryCode(
                                (form.quote.value as Document).creationDate,
                                'fr',
                                DateFormatTypes.SHORT_FORMAT_DATE,
                              )}
                            </Typography>
                          </Link>
                        )}

                        {/** On peut supprimer un devis uniquement s'il n'est pas encore enregistré dans AWS */}
                        {form.quote.value instanceof File && (
                          <IconButton onClick={(e) => handleChange('quote', undefined)} sx={{ ml: 1 }}>
                            <Tooltip title='Supprimer le devis'>
                              <CloseIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                )}
                {!form.quote?.value && getAddQuoteContainer()}
              </>
            </Grid>
            {(localEvent.quoteId || localEvent.quoteAmount) &&
              MyEventDialog.DETAILS === type &&
              !showSendNewQuoteAction && (
                <Grid item xs={12} sx={{ mt: 2, py: 0, px: 1 }}>
                  {localEvent.state === EventState.ACCEPTED && (
                    <Alert sx={{ py: 0, px: 1 }} severity='info' color='warning' className='styled-warning-alert'>
                      En attente réponse client ...
                    </Alert>
                  )}
                  {localEvent.state === EventState.REJECTED && (
                    <Alert sx={{ mt: 1, py: 0, px: 1 }} severity='warning' color='error'>
                      Devis refusé
                      <Button
                        color='inherit'
                        variant='outlined'
                        size='small'
                        onClick={(e) => handleNewQuote()}
                        style={flexStart}
                        sx={{ mt: 2, textTransform: 'none' }}
                      >
                        <AddIcon sx={{ fontSize: '1rem' }} />
                        &nbsp;
                        <Typography sx={{ fontSize: '0.9rem' }}>Nouveau devis</Typography>
                      </Button>
                    </Alert>
                  )}
                  {localEvent.state === EventState.SCHEDULED && (
                    <Alert sx={{ mt: 1, py: 0, px: 1 }}>Devis accepté par le client !</Alert>
                  )}
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handlePostEventQuoteId = () => {
    if (form.quoteId) {
      updateEvent();
    }
  };

  const handlePostEventNotes = () => {
    if (form.notes) {
      updateEvent();
    }
  };

  const handleCancelCustomerReminder = () => {
    setForm((prevForm) => ({ ...prevForm, comment: { ...prevForm['comment'], value: '', error: '' } }));
    setOpenCustomerReminder(false);
  };

  const handleConfirmCustomerReminder = () => {
    let newForm: EventForm = form;
    let newField: MovaFormField;

    // Validator 'comment'
    if (newForm.comment?.value && !validateText(newForm.comment?.value)) {
      newField = { value: form.comment?.value, error: "Le message n''est pas valide." };
    } else if (form.comment?.value === '') {
      newField = { value: form.comment?.value, error: "La saisie d'un message est obligatoire." };
    } else {
      newField = { value: form.comment?.value, error: '' };
    }
    newForm = { ...newForm, ...{ comment: newField } };

    setForm(newForm);

    if (!Boolean(newField.error)) {
      // Nous pouvons soumettre la demande de relance client et fermer la boite de dialogue
      setLoading(true);

      CoreGarageService.sendCustomerReminder(garageId, event.id, form.comment?.value)
        .then((response) => {
          if (response.success) {
            setOpenCustomerReminder(false);

            // On ne ferme pas la dialog box mais provoque juste le refresh des events
            if (onRefresh) onRefresh();

            setForm((prevForm) => ({ ...prevForm, comment: { ...prevForm['comment'], value: '', error: '' } }));

            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? 'Relance client envoyée avec succès',
                severity: 'success',
              }),
            );
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? 'Envoi de la relance client impossible',
                severity: 'error',
              }),
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSendVehicleAvailable = () => {
    if (garage.id && event.id) {
      setLoading(true);

      // Téléchargement du document
      CoreGarageService.sendAppointmentVehicleAvailable(garage.id, event.id)
        .then((response) => {
          if (response.success) {
            // Affichage notification utilisateur
            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? 'Le SMS de fin de rendez-vous a bien été envoyé',
                severity: 'success',
              }),
            );

            // On recharge l'event local et on appelle le callback de refresh des events
            refreshEvent();
            if (onRefresh) onRefresh();
          } else {
            dispatch(
              setSnackbar({
                open: true,
                message: response.error ?? "Erreur lors de l'envoi du SMS",
                severity: 'error',
              }),
            );
          }
        })
        .catch((error) => {
          Logger.info(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCancelNotifyDepositVehicleChange = () => {
    setOpenDepositVehicleChangeDialog(false);
  };

  const handleNotifyDepositVehicleChange = () => {
    updateEvent();
    setOpenDepositVehicleChangeDialog(false);
  };

  /**
   * Style des titres du bloc commandes du rdv
   */
  const getOrderTitleStyle = () => ({
    marginRight: theme.spacing(1),
    borderRadius: 20,
    borderBottom: `3px solid ${getEventStateColor(localEvent, theme)}`,
    borderRight: '100px solid transparent',
    fontSize: '0.9rem',
    marginBottom: theme.spacing(2),
  });

  const getOrderTitleStyleCut = () => ({
    borderRadius: 20,
    borderBottom: `3px solid ${getEventStateColor(localEvent, theme)}`,
    borderRight: '30px solid transparent',
    fontSize: '0.9rem',
    paddingLeft: 6,
  });

  // En dehors du return JSX du composant
  const depositWarningMessage = useMemo(() => {
    if (form.vehicleDepositPreference?.value === VehicleDepositPreference.SAME_DAY && form.vehicleDepositDate?.value) {
      const vehicleDepositDate = new Date(form.vehicleDepositDate.value);
      const dayOfWeek = vehicleDepositDate.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();

      // Trouver les horaires du garage pour le jour de la semaine concerné
      const garageSchedule = garage.schedules.find((schedule) => schedule.dayOfWeek === dayOfWeek);

      if (garageSchedule && garageSchedule.intervals.length > 0) {
        // Obtenir l'heure de début de l'intervalle le plus tôt
        const earliestIntervalStart = new Date(`1970-01-01T${garageSchedule.intervals[0].startTime}Z`);

        // Comparer les heures
        if (
          vehicleDepositDate.getHours() < earliestIntervalStart.getUTCHours() ||
          (vehicleDepositDate.getHours() === earliestIntervalStart.getUTCHours() &&
            vehicleDepositDate.getMinutes() < earliestIntervalStart.getUTCMinutes())
        ) {
          return (
            <Typography sx={{ fontSize: '0.7rem', color: theme.palette.warning.dark }}>
              Attention, l'heure de dépôt est en dehors de vos horaires d'ouvertures
            </Typography>
          );
        }
      }
    }
    return null;
  }, [form.vehicleDepositPreference?.value, form.vehicleDepositDate?.value, garage.schedules]);

  const getDepositVehicleFields = (width?: string, showDate?: boolean): ReactNode => {
    return (
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Grid container direction='column' style={flexCenter} sx={{ height: '100%' }}>
            <GarageIcon sx={{ color: theme.palette.grey[700] }} className=' styled-icon' />
          </Grid>
        </Grid>
        <Grid container sx={{ width: width ?? '90%' }} style={flexCenter}>
          {showDate && (
            <Grid item xs={12} md={11}>
              <DateTimePickerGroup
                dateLabel='Dépôt du véhicule'
                timeLabel='Heure'
                value={form.vehicleDepositDate?.value}
                onChange={(newValue) => handleChange('vehicleDepositDate', newValue)}
                sx={{ justifyContent: 'center', width: '100%', pl: 1 }}
              />
            </Grid>
          )}

          {!showDate && (
            <>
              <Grid item xs={12} md={11}>
                <Typography
                  color={theme.palette.text.secondary}
                  sx={{ fontFamily: 'Caveat', fontSize: '1.3rem', lineHeight: '1.3rem', pb: 2, pl: 1 }}
                >
                  <b style={flexStart}>À quelle heure votre client doit-il déposer son véhicule ?</b>
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ pl: { xs: 1, md: 0 } }}>
                <FormControl fullWidth required error={Boolean(form.vehicleDepositPreference?.error)}>
                  <InputLabel id='vehicle-deposit-preference'>Dépôt du véhicule</InputLabel>
                  <Select
                    labelId='vehicle-deposit-preference'
                    id='vehicle-select'
                    value={form.vehicleDepositPreference?.value}
                    onChange={(e) => handleChange('vehicleDepositPreference', e.target.value)}
                    label='Dépôt du véhicule'
                    variant='outlined'
                    required
                    size='small'
                    sx={{ fontSize: '0.9rem' }}
                  >
                    <MenuItem key={1} value={VehicleDepositPreference.SAME_DAY} sx={{ fontSize: '0.9rem' }}>
                      LE JOUR MÊME
                    </MenuItem>
                    <MenuItem key={2} value={VehicleDepositPreference.DAY_BEFORE} sx={{ fontSize: '0.9rem' }}>
                      <PrevIcon sx={{ fontSize: '1.1rem', mr: 0.5 }} />
                      &nbsp;LA VEILLE
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {Boolean(form.vehicleDepositPreference?.error) ? form.vehicleDepositPreference?.error : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ pl: 3 }}>
                <FormControl fullWidth required>
                  <TimePicker
                    minutesStep={DEFAULT_EVENT_TIMEPICKER_STEP}
                    label='Heure'
                    name='vehicleDepositDate'
                    value={form.vehicleDepositDate?.value}
                    formatDensity='dense'
                    onChange={(newValue) => handleChange('vehicleDepositDate', newValue)}
                    slotProps={{
                      textField: {
                        required: true,
                        size: 'small',
                        sx: {
                          padding: 0,
                        },
                        variant: 'outlined',
                        error: Boolean(form.vehicleDepositDate?.error),
                        helperText: Boolean(form.vehicleDepositDate?.error) ? form.vehicleDepositDate?.error : '',
                      },
                      actionBar: {
                        sx: { display: 'none' },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              {/* MOVALIB-16 : on contrôle plus finement la cohérence entre la date dépôt véhicule et les horaires d'ouverture */}
              <Grid item xs={12}>
                {depositWarningMessage}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  const customDivider = (mt = '0', mb = '0', width = '90%') => {
    return (
      <>
        <div
          style={{
            borderRadius: '20px',
            border: '2px solid #BCD46C',
            width: width,
            height: 0,
            marginTop: mt,
            marginBottom: mb,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </>
    );
  };
  /**
   * Comportement différent selon l'algorithme de détermination des créneaux disponibles
   * Horaires de déptôs : on compare avec l'heure de dépôt du véhicule car elle est éditable
   * Heure fixe : on compare avec l'heure de début d'intervention
   * @returns
   */
  const isVehicleDepositDateUpdated = (): boolean => {
    switch (garage.slotAlgorithm) {
      case SlotAlgorithm.DAY_PERIOD: {
        return localEvent.vehicleDepositDate?.getTime() !== localEvent.vehicleDepositDateRequest?.getTime();
      }
      case SlotAlgorithm.HOUR: {
        return localEvent.start?.getTime() !== localEvent.vehicleDepositDateRequest?.getTime();
      }
    }

    return false;
  };
  const getCurrentLoanVehicle = (): VehicleGarage | undefined => {
    return garage?.vehicles?.find((vehicle) => vehicle.id === localEvent.garageVehicleId);
  };

  /** Checkbox pour affecter une indispo ou une note a toute la journée */
  const handleCheckPlanEventForAllDay = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    toggleEventPlannedForAllDay();
    if (checked) {
      setForm((prevForm) => {
        const startDay = new Date(prevForm.start.value);
        startDay.setHours(0, 0, 0, 0);
        const endDay = new Date(prevForm.end.value);
        endDay.setHours(0, 0, 0, 0);

        // If start & end day are the same, we cannot save, so we add one day to the end day
        if (startDay.getDate() === endDay.getDate()) {
          endDay.setDate(endDay.getDate() + 1);
        }

        return {
          ...prevForm,
          start: { ...prevForm['start'], value: startDay },
          end: { ...prevForm['start'], value: endDay },
        };
      });
    }
  };

  const handleEmployeeChange = (employeeId: string) => {
    if (Number(employeeId) <= 0) {
      setForm((prevForm) => ({ ...prevForm, employee: { ...prevForm['employee'], value: '' } }));
    } else {
      setForm((prevForm) => ({ ...prevForm, employee: { ...prevForm['employee'], value: employeeId } }));
    }
  };

  useEffect(() => {
    if (!repairOrder.loading && currentRepairOrderNumber && currentRepairOrderNumber !== 0) {
      const formattedRepairOrderNumber = String(currentRepairOrderNumber).padStart(6, '0');

      const fileName =
        localEvent.customer?.type === CustomerType.INDIVIDUAL
          ? `OR_${formattedRepairOrderNumber}_${format(localEvent.start!, 'yyyy')}_${localEvent.customer?.lastname?.toUpperCase()}`
          : `OR_${formattedRepairOrderNumber}_${format(localEvent.start!, 'yyyy')}_${localEvent.customer?.companyName?.toUpperCase()}`;

      // 6. Vérifier si l'URL du PDF est bien disponible
      const downloadUrl = repairOrder.url;
      if (!downloadUrl) {
        console.error("L'URL du PDF n'est pas disponible.");
        return;
      }

      // 7. Lancer le téléchargement du fichier PDF avec le bon nom de fichier
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();

      // 8. Supprimer le lien après le téléchargement
      document.body.removeChild(link);

      // Reset le current repair order number
      setCurrentRepairOrderNumber(0);
    }
  }, [repairOrder.loading]);

  const handleDownloadAndApiCall = () => {
    // 1. Appeler l'API juste après le téléchargement
    try {
      if (localEvent.id) {
        GarageService.incrementRepairOrderCounter(dispatch, garageId, localEvent.id)
          .then(() => {
            // 2. Récupération du dernier numéro d'OR en date
            GarageService.getLastRepairOrderCounter(dispatch, garageId, localEvent.id)
              .then((data) => {
                if (data) {
                  const local = localEvent;
                  if(local.notes !== form.notes?.value && form.notes?.value && form.notes?.value !== ''){
                    local.notes = form.notes?.value;
                  }
                  // Update PDF because we change the number
                  updateRepairOrder(
                    <RepairOrder
                      garage={garage}
                      event={local}
                      showInternalNote={isInternalNoteInjected}
                      number={data}
                    />,
                  );
                  setCurrentRepairOrderNumber(data);
                }
              })
              .catch((error) => {
                console.error(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
              });
          })
          .catch((error) => {
            console.error(error);
            dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
          });
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  const displayMessageOnModifyScheduleDialog = () => {
    const mobileInvalid =
      isInvalidMobileNumber(localEvent?.customer?.companyPhoneNumber) &&
      isInvalidMobileNumber(localEvent?.customer?.phoneNumber);

    const emailInvalid = isInvalidEmail(localEvent?.customer?.email);

    let message: string;

    if (mobileInvalid) {
      message = 'Nous lui envoyons un e-mail,';
    } else if (emailInvalid) {
      message = 'Nous lui envoyons le SMS ci-dessous,';
    } else {
      message = "Nous lui envoyons le SMS ci-dessous ainsi qu'un email,";
    }

    return message;
  };

  const CreateCustomerButton = ({ onClick }: { onClick: () => void }) => (
    <Grid container justifyContent='center' style={{ paddingTop: 8 }}>
      <Button
        variant='contained'
        onClick={onClick}
        size='small'
        sx={{ textTransform: 'none', width: '90%', mt: 1, mb: 0.5 }}
      >
        <CustomerAddIcon style={{ fontSize: 20 }} />
        &nbsp;&nbsp;Créer le client
      </Button>
    </Grid>
  );

  const [currentFocusedUser, setCurrentFocusedUser] = useState<string | null>(null);
  useEffect(() => {
    if (currentFocusedUser !== null) {
      const selectedItem = document.getElementById(currentFocusedUser);
      if (selectedItem) {
        selectedItem.scrollIntoView({
          behavior: 'auto',
          block: 'center', // Faire défiler de manière à ce que l'élément sélectionné soit visible sans dépasser
        });
      }
    }
  }, [currentFocusedUser]);
  const focusSearchCustomer = (e: any) => {
    if (e.code === 'Escape') {
      setAutocompleteOpen(false);
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.code === 'Enter') {
      if (customers.length === 0) {
        initialLastName.current = currentCustomerInput;
        handleOpenAddCustomer();
      }
      if (customers.length === 1) {
        handleChange('customer', customers[0], false);
        setAutocompleteOpen(false);
      }
      if (currentFocusedUser !== null) {
        const customer = customers.find((p) => p.id === currentFocusedUser);
        if (customer) {
          handleChange('customer', customer, false);
          setAutocompleteOpen(false);
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.code === 'ArrowDown' && customers.length > 0) {
      if (currentFocusedUser === null) {
        setCurrentFocusedUser(customers[0].id);
      } else {
        const index = customers.findIndex((p) => p.id === currentFocusedUser);
        if (index < customers.length - 1) {
          setCurrentFocusedUser(customers[index + 1].id);
        } else {
          setCurrentFocusedUser(customers[0].id);
        }
      }

      e.preventDefault();
      e.stopPropagation();
    }
    if (e.code === 'ArrowUp' && customers.length > 0) {
      if (currentFocusedUser === null) {
        setCurrentFocusedUser(customers[0].id);
      } else {
        const index = customers.findIndex((p) => p.id === currentFocusedUser);
        if (index > 0) {
          setCurrentFocusedUser(customers[index - 1].id);
        } else {
          setCurrentFocusedUser(customers[customers.length - 1].id);
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const isOrderedTooltipDisabled = !isOrderedPossible(); // Calcul du statut disabled
  const isBeforeOrEqual = (date1: Date, date2: Date) => {
    return isBefore(date1, date2) || date1.getTime() === date2.getTime(); // Vérifie si date1 est antérieure ou égale à date2
  };
  const onValidDepositDate = () => {
    const isDepositDateBeforeEvent = isBeforeOrEqual(movedDepositDate.current!, new Date(form.start.value));

    if (!isDepositDateBeforeEvent) {
      dispatch(
        setSnackbar({
          open: true,
          message: "L'heure de dépôt doit être antérieure à l'heure du rendez-vous",
          severity: 'warning',
        }),
      );
      return;
    }

    handleValidEditVehicleDeposit();
    toggleEditVehiculeDepositDialogOpened();
  };

  function handleEditGarageVehicle(id: number): void {
    setForm((prevForm) => ({ ...prevForm, garageVehicleId: { ...prevForm['garageVehicleId'], value: id } }));
    const idVehicle = garage?.vehicles?.find((vehicle) => vehicle.id === id)?.vehicle.id;
    CoreGarageService.assignGarageVehicleEvent(garageId, localEvent.id, idVehicle!).then((response) => {
      if (response.success) {
        dispatch(
          setSnackbar({
            open: true,
            message: response.data ?? 'Véhicule assigné avec succès',
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? 'Assignation du véhicule impossible',
            severity: 'error',
          }),
        );
      }
    });
  }

  async function handleDeassingLoanVehicle() {
    setForm((prevForm) => ({ ...prevForm, garageVehicleId: { ...prevForm['garageVehicleId'], value: '' } }));
    await CoreGarageService.deassignGarageVehicleEvent(garageId, localEvent.id).then((response) => {
      if (response.success) {
        dispatch(
          setSnackbar({
            open: true,
            message: response.data ?? 'Véhicule désassigné avec succès',
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? 'Désassignation du véhicule impossible',
            severity: 'error',
          }),
        );
      }
    });
  }

  return (
    <>
      <Dialog
        scroll='body'
        fullWidth={true}
        sx={{ p: 0 }}
        maxWidth={
          MyEventDialog.DETAILS === type &&
          EventState.NEW !== event?.state &&
          EventState.CANCELLED !== event?.state &&
          EventState.COMPLETED !== event?.state &&
          EventState.DONE !== event?.state
            ? 'md'
            : 'sm'
        }
        PaperProps={{ sx: { my: 0 } }}
        onClose={(event, reason) => handleOnClose(reason)}
        open={open}
      >
        <Box>
          <Toolbar
            disableGutters
            variant='dense'
            sx={{
              display: 'block',
              minHeight: 3,
              backgroundColor:
                MyEventDialog.CREATE === type
                  ? theme.palette.grey[200]
                  : event?.state === EventState.NEW
                    ? lighten('#00BF08', 0.3)
                    : theme.palette.grey[200],
              //backgroundColor:MyEventDialog.CREATE === type ? theme.palette.grey[200] :
              //  event?.state === EventState.NEW ? lighten('#00BF08', 0.3) : getEventStateColor(event, theme),
              //border: event?.state === EventState.NEW ? '10px solid #00BF08' : 'none',
              p: 0,
            }}
          >
            <DialogTitle padding={1}>
              {MyEventDialog.DETAILS === type && (
                <Grid container>
                  <Grid item xs={11} style={showVehicleCard ? flexLeftRow : flexCenter} sx={{ pl: `${100 / 12}%` }}>
                    {MyEventDialog.DETAILS === type && (
                      <Fragment>
                        {!showVehicleCard && (
                          <strong>
                            <Typography
                              style={flexCenter}
                              variant='subtitle1'
                              color={theme.palette.text.primary}
                              sx={{
                                borderRadius: 4,
                                borderRight: `7px solid ${getEventStateColor(event, theme)}`,
                                borderLeft: `7px solid ${getEventStateColor(event, theme)}`,
                                color:
                                  localEvent.state === EventState.NEW
                                    ? getEventStateColor(event, theme)
                                    : darken(getEventStateColor(event, theme), 0.1),
                              }}
                            >
                              <div style={{ display: event.type === EventType.APPOINTMENT ? 'flex' : 'none' }}>
                                {/* <EventStateIcon event={event} sx={{ ml: 2, mr: 1 }} /> */}
                                <Typography variant='h6' sx={{ mr: 2, ml: 2, textTransform: 'uppercase' }}>
                                  <b>{getEventStateLabel(event)}</b>
                                </Typography>
                              </div>
                            </Typography>
                          </strong>
                        )}
                        {showVehicleCard && (
                          <Grid item xs={12} md={7}>
                            <Typography sx={{ display: 'flex' }} variant='subtitle1' color={theme.palette.text.primary}>
                              <b>Fiche véhicule</b>
                            </Typography>
                            <Typography
                              sx={{ display: { xs: 'none', md: 'flex' } }}
                              variant='subtitle1'
                              color={theme.palette.text.primary}
                            >
                              {event.vehicle?.brand && `${event.vehicle.brand} `}
                              {event.vehicle?.model && `${event.vehicle.model} `}
                              {event.vehicle?.version && `${event.vehicle.version}`}
                            </Typography>
                          </Grid>
                        )}
                      </Fragment>
                    )}
                  </Grid>
                  <Grid item xs={1} style={flexEnd}>
                    <IconButton sx={{ mr: 0 }} size='small' aria-label='close' onClick={() => handleOnClose('')}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}

              {MyEventDialog.CREATE === type && (
                <Grid container>
                  <Grid item xs={11} style={flexCenter}>
                    <Typography
                      py={0}
                      my={0}
                      sx={{
                        pl: '34px',
                        color: theme.palette.text.primary,
                        textAlign: 'center',
                      }}
                    >
                      <span>
                        AJOUTER
                        <b>
                          {form.type.value === EventType.APPOINTMENT ||
                          form.type.value === EventType.PENDING_APPOINTMENT
                            ? ' UN RENDEZ-VOUS'
                            : form.type.value === EventType.UNAVAILABILITY
                              ? ' UNE INDISPONIBILITÉ'
                              : ' UNE NOTE'}
                        </b>
                      </span>
                    </Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={() => handleOnClose('')}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </DialogTitle>
          </Toolbar>

          <DialogContent
            sx={{
              p: 0,
              maxHeight: 'calc(var(--fullHeight) - 68px - 54px - 64px)',
              position: 'relative',
              overflowX: 'hidden',
            }}
          >
            {showVehicleCard && selectedVehicle && (
              <Grid container style={flexCenterRow}>
                <VehicleFullCard
                  currentUser={connectedUser.current!}
                  currentUpload={currentUpload}
                  vehicle={selectedVehicle}
                  onError={handleOnVehicleError}
                  onUploadDocument={handleOnVehicleUploadDocument}
                  onDeleteDocument={handleOnVehicleDeleteDocument}
                  onUpdate={handleOnVehicleUpdate}
                  editMode={vehicleEditMode}
                  appType={MovaAppType.GARAGE}
                />
              </Grid>
            )}

            {!showVehicleCard && (
              <Grid
                container
                sx={{
                  position: 'relative',
                }}
              >
                {/* #######################################
                                COLONNE 1 : Informations RDV
                            * ####################################### */}
                <Grid
                  item
                  xs={12}
                  md={
                    EventState.NEW !== event?.state &&
                    EventState.CANCELLED !== event?.state &&
                    EventState.COMPLETED !== event?.state &&
                    EventState.DONE !== event?.state
                      ? 6
                      : 12
                  }
                  sx={{
                    pr: { xs: 0, md: 1 },
                    borderRight: {
                      xs: 'none',
                      md: event.state === 'ACCEPTED' || event.state === 'SCHEDULED' ? '3px solid #F5F5F5' : 'none',
                    },
                  }}
                >
                  {/*     {MyEventDialog.DETAILS === type && EventState.NEW !== event?.state &&
                                    <Container style={flexCenter}>
                                        <Chip
                                            size='medium'
                                            color='default'
                                            sx={{ borderColor: getEventStateColor(event, theme), color: theme.palette.text.secondary}}
                                            label={<Typography variant='body2'><b>Informations RDV</b></Typography>}
                                            variant="outlined" />
                                    </Container>
                                } */}
                  {MyEventDialog.DETAILS === type && EventState.NEW === localEvent?.state && (
                    <Grid container>
                      <Grid item xs={12} sx={{ textAlign: 'center', pt: 0 }}>
                        <Typography variant='h6' sx={{ mb: 1 }}>
                          <strong>{localEvent.title}</strong>
                        </Typography>
                        {localEvent.state === EventState.NEW && localEvent.notes && (
                          <Typography variant='body2' sx={{ mb: 1 }}>
                            <strong>
                              {localEvent.origin === 'MOVALIB_PRO' ? 'Notes internes' : 'Commentaire du client'}
                            </strong>{' '}
                            :{' '}
                            <span
                              style={{ lineHeight: '0.7' }}
                              dangerouslySetInnerHTML={{ __html: base64ToUtf8(event.notes ?? '') }}
                            />
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  {/* **************************************************
                                    LIGNE (DETAILS), titre du RDV
                                * ***************************************************/}
                  {EventState.NEW !== localEvent?.state && !showVehicleCard && (
                    <Grid
                      container
                      sx={{ textAlign: 'center', mb: garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD ? 1 : 2 }}
                    >
                      <Grid item xs={1} style={flexCenter}>
                        <ColorPicker selectedColor={selectedColor} handleChangeColor={handleChangeColor} />
                      </Grid>
                      <Grid item xs={11} style={flexCenter}>
                        {MyEventDialog.DETAILS === type && (
                          <Fragment>
                            <Typography
                              variant='h6'
                              sx={{ backgroundColor: 'white', m: 0, fontSize: { xs: '1rem', md: '1.25rem' } }}
                            >
                              <strong>{localEvent.title}</strong>
                            </Typography>
                          </Fragment>
                        )}
                      </Grid>
                      {[EventState.SCHEDULED, EventState.COMPLETED].includes(event.state) && (
                        <>
                          <Grid item xs={1} style={flexCenter} />
                          <Grid item xs={11} style={flexCenter}>
                            <Button
                              variant='text'
                              size='small'
                              sx={{ textTransform: 'inherit' }}
                              onClick={togglePrestationsDialogOpenned}
                            >
                              <Typography
                                variant='body1'
                                sx={{
                                  color: theme.palette.primary.dark,
                                  textDecoration: 'underline',
                                }}
                              >
                                Gérer les prestations
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}

                  {/* **************************************************
                                    LIGNE 1 (DETAILS), créneau horaire
                                * ***************************************************/}
                  {MyEventDialog.DETAILS === type && (
                    <Grid container sx={{ mt: 0.25 }} padding={0}>
                      {/** Affichage, choix de l'employé */}
                      <EmployeeChoice
                        localEvent={localEvent}
                        garage={garage}
                        type={type}
                        refreshEvent={refreshEvent}
                        employee={event.resourceId ? String(event.resourceId) : ''}
                        onChange={handleEmployeeChange}
                      />
                      {MyEventDialog.DETAILS === type && EventType.APPOINTMENT === event?.type && (
                        <>
                          {/* On affiche l'horaire de dépôt du véhicule s'il est différente de celui du début de l'intervention et si garage à l'heure de dépôt */}

                          {!(showVehicleCard || editMode) && (
                            <>
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  mt: 1,
                                  display: { xs: 'none', md: 'flex' },
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Grid container direction='column' style={flexCenter} sx={{ height: '100%' }}>
                                  <EventScheduleIcon sx={{ color: theme.palette.grey[500] }} />
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={11} direction='column' sx={{ mt: 1 }}>
                                <Typography variant='body1' sx={{ pl: 1 }}>
                                  <b>Intervention</b>
                                </Typography>
                                <Grid container>
                                  <Grid item xs={8} style={flexLeftRow}>
                                    <Typography variant='body1' sx={{ pl: 1 }}>
                                      {/** on utilise localEvent qui est une copie local de 'event' passé en prop, car nous pouvons modifier l'event sans fermer la popup */}
                                      {getFormatedEventDate(localEvent?.start, localEvent?.end)}
                                    </Typography>
                                  </Grid>
                                  {EventState.DONE !== event.state && EventState.CANCELLED !== event.state && (
                                    <Grid item xs={4} style={flexCenterRow}>
                                      <Button
                                        color='inherit'
                                        aria-label='edit-schedule'
                                        onClick={() => handleEditEvent()}
                                        disableElevation
                                        sx={{ ml: 1, alignItems: 'center' }}
                                        variant='contained'
                                        size='small'
                                        startIcon={<EditIcon />}
                                      >
                                        Modifier
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          )}

                          {editMode && !showVehicleCard && (
                            <Box sx={{ width: '100%' }}>
                              <Grid container>
                                <Grid item xs={11} sx={{ mt: 2 }}>
                                  <DateTimePickerGroup
                                    dateLabel='Début intervention'
                                    timeLabel='Heure'
                                    value={form.start.value}
                                    onChange={(newValue) => handleChange('start', newValue)}
                                    marginBottom={2}
                                    sx={{ justifyContent: 'center', width: '82%', pl: '7%' }}
                                  />
                                  <DateTimePickerGroup
                                    dateLabel='Fin intervention'
                                    timeLabel='Heure'
                                    value={form.end.value}
                                    onChange={(newValue) => handleChange('end', newValue)}
                                    sx={{ justifyContent: 'center', width: '82%', pl: '7%' }}
                                  />
                                </Grid>
                                <Grid item xs={12} style={flexCenterRow} sx={{ mt: 2, mb: 2, gap: 2 }}>
                                  <>
                                    <Button
                                      aria-label='validate-edit-schedule'
                                      onClick={() => handleValidEditEvent()}
                                      sx={{ ml: 1 }}
                                      variant='contained'
                                      size='small'
                                      startIcon={<CheckIcon />}
                                      disableElevation
                                    >
                                      Valider
                                    </Button>
                                    <Button
                                      color='inherit'
                                      aria-label='cancel-edit-schedule'
                                      onClick={() => handleCancelEditEvent()}
                                      sx={{ ml: 1 }}
                                      variant='contained'
                                      size='small'
                                      startIcon={<CloseIcon />}
                                      disableElevation
                                    >
                                      Annuler
                                    </Button>
                                  </>
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                        </>
                      )}
                    </Grid>
                  )}
                  {isEditVehiculeDepositDialogOpened && (
                    <ConfirmationDialog
                      open
                      title='Modification heure de dépôt véhicule'
                      onClose={() => toggleEditVehiculeDepositDialogOpened()}
                      onClickBtnClose={() => {
                        updateEvent();
                        toggleEditVehiculeDepositDialogOpened();
                      }}
                      onConfirm={() => {
                        onValidDepositDate();
                      }}
                      confirmLabel='Modifier'
                      closeLabel="Conserver l'heure de dépôt initiale"
                      message={<ModifyDropOffVehicle event={localEvent} movedDepositDate={movedDepositDate} />}
                    />
                  )}

                  {/* **************************************************
                                    LIGNE 2 (DETAILS), véhicule concerné
                                * ***************************************************/}
                  {MyEventDialog.DETAILS === type && EventType.APPOINTMENT === event?.type && (
                    <Grid container sx={{ mt: 1 }}>
                      {!editVehicle && (
                        <>
                          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Tooltip title='Véhicule réceptionné ?' placement='left' arrow>
                              <Checkbox
                                checked={localEvent.vehicleReceived}
                                icon={
                                  <i
                                    className='fas fa-solid fa-car'
                                    style={{
                                      color: theme.palette.grey[500],
                                      marginLeft: '2px',
                                      marginTop: '5.5px',
                                      fontSize: '1.2rem',
                                    }}
                                  />
                                }
                                checkedIcon={
                                  <i
                                    className='fas fa-solid fa-car-on '
                                    style={{ color: '#FFD43B', fontSize: '1.4rem' }}
                                  />
                                }
                                onChange={handleCheckVehicleReceived}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item xs={11}>
                            <Button
                              variant='text'
                              color='inherit'
                              size='small'
                              sx={{
                                height: '100%',
                                width: '100%',
                                flexDirection: 'rows',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontSize: '0.8rem',
                                paddingRight: 0,
                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Empêche la soumission du formulaire
                                if (localEvent.vehicle?.id) {
                                  handleShowVehicleCard();
                                } else {
                                  // Missing event vehicle, request add by plate
                                  handleChange('vehicleId', -1);
                                }
                              }}
                            >
                              <Tooltip
                                title={localEvent.vehicle?.id ? 'Voir la fiche véhicule' : ''}
                                placement='top-start'
                                arrow
                              >
                                <Box
                                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flex: '66%' }}
                                >
                                  {localEvent.vehicle?.id ? (
                                    vehicleLabel.split(' ').map((word, index) => {
                                      if (index === 0) {
                                        return (
                                          <b key={index} style={{ marginRight: '8px', fontSize: '0.9rem' }}>
                                            {word}
                                          </b>
                                        );
                                      }
                                      return `${word} `;
                                    })
                                  ) : (
                                    <Typography
                                      style={flexCenter}
                                      variant='body1'
                                      sx={{ animation: 'blink 1.5s infinite' }}
                                      color={theme.palette.warning.dark}
                                    >
                                      <AddIcon />
                                      &nbsp;SAISIR LA PLAQUE
                                    </Typography>
                                  )}
                                </Box>
                              </Tooltip>
                              {EventState.DONE !== event.state &&
                                EventState.CANCELLED !== event.state &&
                                localEvent.vehicle?.id && (
                                  <Box sx={{ flex: '33%' }}>
                                    <LoadingButton
                                      loading={loadingEditVehicle}
                                      color='inherit'
                                      aria-label='edit-schedule'
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleEditVehicle();
                                      }}
                                      disableElevation
                                      sx={{ alignItems: 'center', ml: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}
                                      variant='contained'
                                      size='small'
                                      startIcon={<EditIcon />}
                                    >
                                      Modifier
                                    </LoadingButton>
                                  </Box>
                                )}
                            </Button>
                          </Grid>

                          {localEvent.prestations &&
                            localEvent.vehicle &&
                            localEvent.vehicle.tireSize !== undefined &&
                            (localEvent.prestations.map((p) => p.code).includes(PrestationCode.TIRE) ||
                              localEvent.prestations.map((p) => p.code).includes(PrestationCode.FLAT)) && (
                              <Grid container sx={{ mt: 1, mb: 1 }}>
                                <Grid item xs={1} style={flexCenterCol}>
                                  <img
                                    src={TirePicture}
                                    style={{
                                      position: 'relative',
                                      width: '50%',
                                      opacity: 0.4,
                                      zIndex: 200,
                                    }}
                                    alt='Icone Pneumatique'
                                  />
                                </Grid>
                                <Grid item xs={11} sx={{ pl: 1 }}>
                                  <Typography variant='body2'>
                                    {localEvent.vehicle.tireDiameter !== null ? (
                                      <b>{formatVehicleTire(localEvent.vehicle.tireSize)}</b>
                                    ) : (
                                      'Non renseigné'
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                        </>
                      )}
                      {/* Affichage du formlauire de modification u vehicule */}
                      {editVehicle && !showVehicleCard && (
                        <Box sx={{ width: '100%' }}>
                          <Grid container>
                            <Grid item xs={12} style={flexCenterRow}>
                              <FormControl
                                variant='standard'
                                sx={{ maxWidth: '70%' }}
                                fullWidth
                                disabled={!form.customer?.value}
                              >
                                <InputLabel id='vehicle-select-label'>Choix véhicule</InputLabel>
                                <Select
                                  labelId='vehicle-select-label'
                                  id='vehicle-select'
                                  value={form.vehicleId?.value}
                                  onChange={(e) => handleChange('vehicleId', e.target.value)}
                                  label='Véhicule'
                                >
                                  {form.customer?.value &&
                                    (form.customer.value as Customer).vehicles.map((vehicle: Vehicle, index) => (
                                      <MenuItem key={index} value={vehicle.id}>
                                        <div style={{ textAlign: 'left' }}>
                                          <strong>{formatFrenchVehiclePlate(vehicle.plate)}</strong>
                                          <br />
                                          <span style={{ fontSize: '12px' }}>
                                            {vehicle.brand} {vehicle.model}
                                          </span>
                                        </div>
                                      </MenuItem>
                                    ))}
                                  <MenuItem
                                    key={-1}
                                    value={-1}
                                    sx={{
                                      display:
                                        form.customer?.value && (form.customer.value as Customer).vehicles
                                          ? 'block'
                                          : 'none',
                                      backgroundColor: theme.palette.grey[200],
                                    }}
                                    onClick={() => handleChange('vehicleId', -1)}
                                  >
                                    <div
                                      style={{
                                        textAlign: 'left',
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'center',
                                        fontSize: '13px',
                                      }}
                                    >
                                      <AddIcon />
                                      &nbsp;SAISIR LA PLAQUE
                                    </div>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} style={flexCenterRow} sx={{ mt: 2, mb: 2, gap: 2 }}>
                              <>
                                <Button
                                  aria-label='validate-edit-vehicle'
                                  onClick={() => onChangeVehicle()}
                                  sx={{ ml: 1 }}
                                  variant='contained'
                                  size='small'
                                  startIcon={<CheckIcon />}
                                  disableElevation
                                >
                                  Valider
                                </Button>
                                <Button
                                  color='inherit'
                                  aria-label='cancel-edit-vehicle'
                                  onClick={() => setEditVehicle(false)}
                                  sx={{ ml: 1 }}
                                  variant='contained'
                                  size='small'
                                  startIcon={<CloseIcon />}
                                  disableElevation
                                >
                                  Annuler
                                </Button>
                              </>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                  )}
                  {/* **************************************************
                                    LIGNE 3 (DETAILS), infos client
                                * ***************************************************/}
                  {MyEventDialog.DETAILS === type && EventType.APPOINTMENT === event?.type && (
                    <Grid container>
                      <Grid
                        item
                        xs={1}
                        sx={{
                          mt: 1,
                          display: { xs: 'none', md: 'flex' },
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {localEvent.customer?.type === CustomerType.INDIVIDUAL && (
                          <CustomerIcon sx={{ color: theme.palette.grey[500] }} />
                        )}
                        {localEvent.customer?.type === CustomerType.PROFESSIONAL && (
                          <BusinessIcon sx={{ color: theme.palette.grey[500] }} />
                        )}{' '}
                      </Grid>
                      <Grid item xs={12} md={11} sx={{ mt: 1 }}>
                        <Fragment>
                          <Tooltip
                            title={localEvent.customer?.id ? 'Voir la fiche client' : ''}
                            placement='top-start'
                            arrow
                          >
                            <Button
                              color='inherit'
                              size='small'
                              disableRipple
                              sx={{
                                fontSize: '0.9rem',
                                fontWeight: 'bold',
                                verticalAlign: 'super',
                                textTransform: 'none',
                                mr: 2,
                                pl: 1,
                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Empêche la soumission du formulaire
                                if (localEvent?.customer) {
                                  handleOpenEditCustomer(localEvent.customer);
                                }
                              }}
                            >
                              {localEvent.customer?.type === CustomerType.INDIVIDUAL
                                ? `${localEvent.customer?.lastname?.toUpperCase()} ${localEvent.customer?.firstname}`
                                : `${localEvent.customer?.companyName?.toUpperCase()}`}
                            </Button>
                          </Tooltip>
                          <Box sx={{ pl: 1, display: 'flex', alignItems: 'center' }}>
                            {localEvent.customer?.companyPhoneNumber && (
                              <Typography
                                sx={{ color: theme.palette.primary.dark, fontSize: 16 }}
                                component='a'
                                href={`tel:${localEvent.customer?.companyPhoneNumber}`}
                                onClick={handleClickPhoneNumber}
                              >
                                {formatPhoneNumber(localEvent.customer?.companyPhoneNumber)}
                              </Typography>
                            )}

                            {localEvent.customer?.phoneNumber &&
                              localEvent.customer?.companyPhoneNumber &&
                              '\u00A0\u00A0/\u00A0\u00A0'}

                            {localEvent.customer?.phoneNumber && (
                              <Typography
                                sx={{ color: theme.palette.primary.dark, fontSize: 16 }}
                                component='a'
                                href={`tel:${localEvent.customer?.phoneNumber}`}
                                onClick={handleClickPhoneNumber}
                              >
                                {formatPhoneNumber(localEvent.customer?.phoneNumber)}
                              </Typography>
                            )}
                          </Box>
                        </Fragment>
                      </Grid>
                    </Grid>
                  )}
                  {garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD &&
                    !showVehicleCard &&
                    !editVehicleDeposit &&
                    localEvent.vehicleDepositDate &&
                    MyEventDialog.DETAILS === type && (
                      <Grid container>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            mt: 1,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Grid container direction='column' style={flexCenter}>
                            <GarageIcon sx={{ color: theme.palette.grey[500] }} />
                          </Grid>
                        </Grid>
                        <Grid item xs={7} direction='column' sx={{ mt: 1, mb: 1, pl: 1 }}>
                          <Typography variant='body1' sx={{ mb: 0.5 }}>
                            <b>Dépôt du véhicule</b>
                          </Typography>
                          <Typography variant='body1'>
                            {formatDateByCountryCode(
                              localEvent?.vehicleDepositDate,
                              'FR',
                              DateFormatTypes.LONG_FORMAT_DATETIME_LITERAL,
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} md={4} style={flexCenterRow}>
                          {EventState.DONE !== event.state && EventState.CANCELLED !== event.state && (
                            <Button
                              color='inherit'
                              aria-label='edit-vehicle-deposit'
                              onClick={() => handleVehicleEdit()}
                              disableElevation
                              sx={{ alignItems: 'center', ml: 3 }}
                              variant='contained'
                              size='small'
                              startIcon={<EditIcon />}
                            >
                              Modifier
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  {editVehicleDeposit && !showVehicleCard && garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD && (
                    <>
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        {getDepositVehicleFields('88%', true)}
                      </Grid>
                      <Grid item xs={12} style={flexCenterRow} sx={{ mt: 1, mb: 2, gap: 2 }}>
                        <>
                          <Button
                            aria-label='validate-edit-vehicle-deposit'
                            onClick={() => handleValidEditVehicleDeposit()}
                            sx={{ ml: 1 }}
                            disabled={
                              form?.vehicleDepositDate?.value.getTime() === localEvent.vehicleDepositDate?.getTime() &&
                              localEvent.state !== EventState.NEW
                            }
                            variant='contained'
                            size='small'
                            startIcon={<CheckIcon />}
                            disableElevation
                          >
                            Valider
                          </Button>
                          <Button
                            color='inherit'
                            aria-label='cancel-edit-vehicle-deposit'
                            onClick={() => handleCancelEditVehicleDeposit()}
                            sx={{ ml: 1 }}
                            variant='contained'
                            size='small'
                            startIcon={<CloseIcon />}
                            disableElevation
                          >
                            Annuler
                          </Button>
                        </>
                      </Grid>
                    </>
                  )}
                  {/* *****************************************************
                                    LIGNE (DETAILS), Véhicule de prêt
                                * ******************************************************/}
                  {garage.loanerVehicleActive &&
                    MyEventDialog.DETAILS === type &&
                    EventType.APPOINTMENT === localEvent?.type && (
                      <Grid container>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            mt: 1,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Grid container direction='column' style={flexCenter}>
                            <CarRentalRoundedIcon sx={{ color: theme.palette.grey[500] }} />
                          </Grid>
                        </Grid>
                        <Grid item xs={10} direction='column' sx={{ mt: 1, mb: 1, pl: 1 }}>
                          <Tooltip
                            title={availableVehicles.length === 0 ? 'Aucun véhicule de prêt disponible' : ''}
                            placement='top'
                            arrow
                          >
                            <span>
                              <FormControl size='small' fullWidth variant='outlined'>
                                <InputLabel
                                  id='loan-vehicle-select-id'
                                  shrink={Boolean(form.garageVehicleId?.value)}
                                  sx={{ display: 'flex', flexDirection: 'row', backgroundColor: 'white', px: 1 }}
                                >
                                  {isMobile ? 'VP ...' : 'Choix du véhicule de prêt...'}
                                  {event.garageVehicleRequest === true &&
                                    typeof form?.garageVehicleId?.value !== typeof 666 && (
                                      <Typography
                                        variant='body2'
                                        sx={{ animation: 'blink 1.5s infinite', pl: 1 }}
                                        color={theme.palette.primary.main}
                                      >
                                        <b>&nbsp;&nbsp; DEMANDE CLIENT </b>
                                      </Typography>
                                    )}
                                </InputLabel>
                                <Select
                                  variant='outlined'
                                  label='Choix du véhicule de prêt...'
                                  labelId='loan-vehicle-select-id'
                                  id='loan-vehicle-select-id'
                                  disabled={availableVehicles.length === 0}
                                  value={form.garageVehicleId?.value}
                                  onChange={(e) => handleEditGarageVehicle(e.target.value as number)}
                                  renderValue={(value) => {
                                    const currentValue = getGarageVehicleById(value);
                                    if (currentValue) {
                                      return (
                                        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', diplay: 'block' }}>
                                          <strong>
                                            {' '}
                                            VP{currentValue.index}
                                            {' - '}
                                            {currentValue.vehicle.brand} {currentValue.vehicle.model}
                                          </strong>
                                          &nbsp;/&nbsp;{formatFrenchVehiclePlate(currentValue.vehicle.plate)}
                                        </Box>
                                      );
                                    }
                                  }}
                                >
                                  {localEvent.garageVehicleId && (
                                    <MenuItem
                                      key={`loanVehicle-${getGarageVehicleById(localEvent.garageVehicleId!).id}`}
                                      value={getGarageVehicleById(localEvent.garageVehicleId!).id}
                                    >
                                      <strong>
                                        {' '}
                                        VP{getGarageVehicleById(localEvent.garageVehicleId!).index}
                                        {' - '}
                                        {getGarageVehicleById(localEvent.garageVehicleId!).vehicle.brand}{' '}
                                        {getGarageVehicleById(localEvent.garageVehicleId!).vehicle.model}
                                      </strong>{' '}
                                      /{' '}
                                      {formatFrenchVehiclePlate(
                                        getGarageVehicleById(localEvent.garageVehicleId!).vehicle.plate,
                                      )}
                                    </MenuItem>
                                  )}
                                  {availableVehicles
                                    .sort((a: VehicleGarage, b: VehicleGarage) => a.index - b.index)
                                    .map((vehicle) => (
                                      <MenuItem key={`loanVehicle-${vehicle.id}`} value={vehicle.id}>
                                        <strong>
                                          {' '}
                                          VP{vehicle.index} - {vehicle.vehicle.brand} {vehicle.vehicle.model}
                                        </strong>
                                        &nbsp;/&nbsp;{formatFrenchVehiclePlate(vehicle.vehicle.plate)}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </span>
                          </Tooltip>
                        </Grid>
                        {
                          <Grid item xs={1} sx={{ pl: 3 }} style={flexCenterRow}>
                            {EventState.DONE !== event.state && EventState.CANCELLED !== event.state && (
                              <IconButton
                                onClick={() => handleDeassingLoanVehicle()}
                                disabled={typeof form?.garageVehicleId?.value !== typeof 666}
                                size='small'
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </Grid>
                        }
                      </Grid>
                    )}

                  {/* *****************************************************
                                    LIGNE (DETAILS), liste des opérations & produits
                                * ******************************************************/}
                  {MyEventDialog.DETAILS === type && EventType.APPOINTMENT === localEvent?.type && (
                    <Grid container>
                      <Grid
                        item
                        xs={1}
                        sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center' }}
                      >
                        <OperationsIcon sx={{ color: theme.palette.grey[500] }} />
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Typography variant='button'>
                          <b>Liste des opérations</b>
                        </Typography>
                        {localEvent.operations && localEvent.operations.length > 0 ? (
                          <>
                            {localEvent.operations
                              .slice() // créer une copie pour ne pas muter l'original
                              .sort((a, b) => a.description.localeCompare(b.description))
                              .map((operation, index) => (
                                <Typography variant='body2' key={index}>
                                  - {operation.description}
                                </Typography>
                              ))}
                          </>
                        ) : (
                          <Typography variant='body2'>Aucune opération définie</Typography>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  {/* *****************************************************
                                    LIGNE (DETAILS), notes
                                 * ******************************************************/}
                  {MyEventDialog.DETAILS === type && EventState.NEW !== event.state && (
                    <Grid container>
                      {localEvent?.documents?.filter((doc) => doc.type !== DocumentType.USER_APPOINTMENT_QUOTE)
                        ?.length !== 0 && <ListChipDocumentEvent refreshEvent={refreshEvent} localEvent={localEvent} />}
                      <Grid
                        item
                        xs={1}
                        sx={{
                          mt: 1,
                          display: { xs: 'none', md: 'flex' },
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <NotesIcon sx={{ color: theme.palette.grey[500] }} />
                      </Grid>
                      <Grid item xs={12} md={11} sx={{ mt: 1 }}>
                        <CustomTextarea
                          value={form.notes?.value || ''}
                          onChange={(e) => handleChange('notes', e)}
                          onBlur={() => handlePostEventNotes()}
                          error={form.notes?.error}
                          helperText={
                            !form.notes?.error && (
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <PrivateIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                                Non visible par le client
                              </Box>
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* ***************************************************************************
                                    Consultation de l'éventuel devis client / Input de celui ci si besoin
                                * ****************************************************************************/}
                  {MyEventDialog.DETAILS === type &&
                    EventType.APPOINTMENT === event?.type &&
                    event?.state !== EventState.NEW &&
                    event?.state !== EventState.ACCEPTED &&
                    event?.state !== EventState.REJECTED &&
                    ((localEvent.quoteId && form.quote?.value) || localEvent.quoteAmount ? (
                      <Grid container sx={{ mt: 1 }} style={flexLeftRow}>
                        <Grid item xs={1} style={flexCenterCol}>
                          <QuoteIcon sx={{ color: theme.palette.grey[500], margin: 'auto' }} />
                        </Grid>
                        <Grid item xs={11} sx={{ textAlign: 'left' }}>
                          <Grid container>
                            {form.quote?.value && (
                              <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Link
                                    color={darken(theme.palette.info.main, 0.2)}
                                    href={(form.quote.value as Document).fileSignedUrl}
                                    target='_blank'
                                    rel='noopener'
                                  >
                                    <Typography>
                                      Devis client du{' '}
                                      {formatDateByCountryCode(
                                        (form.quote.value as Document).creationDate,
                                        'fr',
                                        DateFormatTypes.SHORT_FORMAT_DATE,
                                      )}
                                    </Typography>
                                  </Link>
                                </div>
                              </Grid>
                            )}
                            {localEvent.quoteAmount && (
                              <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography sx={{ lineHeight: 1 }}>
                                    <span>
                                      Montant du devis : <b>{formatEurosCurrency(localEvent.quoteAmount)}</b>
                                      &nbsp; T.T.C
                                    </span>
                                  </Typography>
                                </div>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      getQuoteGrid()
                    ))}

                  {/* ***************************************************************************
                                    LIGNE 7 (DETAILS), éventuelle facture (avec possibilité d'en ajouter une)
                                * ****************************************************************************/}
                  {MyEventDialog.DETAILS === type && event?.state === EventState.DONE && (
                    <Grid container>
                      <Grid item xs={1} sx={{ mt: 2, mb: 2 }} style={flexCenterCol}>
                        <InvoiceIcon sx={{ color: theme.palette.grey[500], margin: 'auto' }} />
                      </Grid>
                      {eventInvoice !== undefined ? (
                        <Grid item xs={11} sx={{ textAlign: 'left', mt: 2, mb: 2 }} style={flexLeftRow}>
                          <Typography>
                            <Link
                              color={darken(theme.palette.info.main, 0.2)}
                              href={eventInvoice.fileSignedUrl}
                              target='_blank'
                              rel='noopener'
                            >
                              Facture client du{' '}
                              {formatDateByCountryCode(
                                eventInvoice.creationDate,
                                'fr',
                                DateFormatTypes.SHORT_FORMAT_DATE,
                              )}
                            </Link>
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                          {/* Input caché de type "file" */}
                          <div>
                            <input
                              accept='image/*, application/pdf'
                              type='file'
                              style={{ display: 'none' }}
                              ref={invoiceInputRef}
                              id='raised-button-invoice'
                              onChange={(e) => handleFileChange(e, DocumentType.VEHICLE_MAINTENANCE_INVOICE)}
                            />
                            <label htmlFor='raised-button-invoice'>
                              <Button
                                size='small'
                                color='inherit'
                                component='span'
                                sx={{ opacity: 0.8, backgroundColor: theme.palette.grey[100] }}
                              >
                                Ajouter Facture
                              </Button>
                            </label>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  )}

                  {/* *****************************************************
                                        LIGNE (DETAILS), éventuel n° de devis
                                * ******************************************************/}
                  {MyEventDialog.DETAILS === type && !localGarage?.customerQuoteActive && isActiveEvent(localEvent) && (
                    <Grid container>
                      <Grid item xs={1} sx={{ mt: 2, mb: 1 }} style={flexCenterCol}>
                        <QuoteIcon sx={{ color: theme.palette.grey[500], margin: 'auto' }} />
                      </Grid>
                      <Grid item xs={11} sx={{ mt: 2, mb: 1 }}>
                        <TextField
                          id='event-quote'
                          label='N° de devis'
                          value={form.quoteId?.value}
                          onChange={(e) => handleChange('quoteId', e.target.value)}
                          onBlur={(e) => handlePostEventQuoteId()}
                          variant='outlined'
                          size='small'
                          fullWidth
                          sx={{
                            '& input': { fontWeight: 'bold' }, // CSS pour modifier le style dans l'input
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {MyEventDialog.DETAILS === type &&
                    localGarage?.customerQuoteActive &&
                    EventState.NEW === event?.state &&
                    getQuoteGrid()}
                </Grid>

                {/* ###################################
                                COLONNE 2
                            * ################################### */}
                {MyEventDialog.DETAILS === type &&
                  EventState.NEW !== event?.state &&
                  EventState.CANCELLED !== event?.state &&
                  EventState.COMPLETED !== event?.state &&
                  EventState.DONE !== event?.state && (
                    <Grid item xs={12} md={6} sx={{ px: 1 }}>
                      {/* ###################################
                                        CONTENT BLOC DEVIS
                                    * ################################### */}
                      {(EventState.ACCEPTED === event?.state || EventState.REJECTED === event?.state) &&
                        localGarage?.supplierOrderActive && (
                          <>
                            <Container style={flexCenter} sx={{ pt: { xs: 2, md: 0 } }}>
                              <Chip
                                size='medium'
                                color='default'
                                sx={{
                                  borderColor: getEventStateColor(event, theme),
                                  color: theme.palette.text.secondary,
                                }}
                                label={
                                  <Typography variant='body2'>
                                    <b>Devis client</b>
                                  </Typography>
                                }
                                variant={'outlined'}
                              />
                            </Container>
                            {getQuoteGrid()}
                          </>
                        )}
                      {/* ###################################
                                        CONTENT BLOC COMMANDE
                                    * ################################### */}
                      {EventState.ACCEPTED !== event?.state &&
                        EventState.REJECTED !== event?.state &&
                        localGarage?.supplierOrderActive && (
                          <>
                            <Container style={flexCenter} sx={{ pt: { xs: 2, md: 0 } }}>
                              <Chip
                                size='medium'
                                color='default'
                                sx={{
                                  borderColor: getEventStateColor(event, theme),
                                  color: theme.palette.text.secondary,
                                }}
                                label={
                                  <Typography variant='body2'>
                                    <b>Commande</b>
                                  </Typography>
                                }
                                variant={'outlined'}
                              />
                            </Container>
                            <Grid container sx={{ pt: 2 }}>
                              {/* Eventuel commentaire sur la commande */}
                              {(localEvent.state === EventState.SCHEDULED ||
                                localEvent.state === EventState.ACCEPTED) && (
                                <Grid item xs={12} style={flexCenter} sx={{ mb: 1 }}>
                                  <Typography
                                    variant='body2'
                                    color={theme.palette.secondary.dark}
                                    sx={{ fontStyle: 'italic', fontSize: '14px' }}
                                  >
                                    {localEvent.orderComment}
                                  </Typography>
                                </Grid>
                              )}

                              <Grid item xs={12} style={getOrderTitleStyle()}>
                                <Typography
                                  variant='button'
                                  sx={{ pl: 1, color: darken(getEventStateColor(localEvent, theme), 0.1) }}
                                >
                                  <b>1. PIÈCES</b>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ pb: 1 }}>
                                {/* LES PIECES DETACHEES */}
                                {localEvent.products &&
                                  localEvent.products.length > 0 &&
                                  localEvent.products
                                    .filter((p) => p.type === ProductType.PART)
                                    // On filtre les doublons produit selon l'id
                                    .filter(
                                      (product, index, self) =>
                                        index ===
                                        self.findIndex(
                                          (p) =>
                                            p.name.trim().toLowerCase() === product.name.trim().toLowerCase() &&
                                            p.application === product.application,
                                        ),
                                    )
                                    .slice() // créer une copie pour ne pas muter l'original
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((product) => (
                                      <Grid container key={product.id + product.application} columnSpacing={2}>
                                        <Grid
                                          item
                                          xs={
                                            (event.state === EventState.SCHEDULED ||
                                              event.state === EventState.ACCEPTED) &&
                                            product.orderState !== OrderState.NEW
                                              ? 7
                                              : 12
                                          }
                                          style={flexLeftRow}
                                        >
                                          {(event.state === EventState.SCHEDULED ||
                                            event.state === EventState.ACCEPTED) && (
                                            <Checkbox
                                              size='small'
                                              sx={{
                                                p: 0.5,
                                                backgroundColor: 'transparent',
                                              }}
                                              checked={form.checkedProducts?.value.includes(product.id)}
                                              onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                                checked: boolean,
                                              ) => handleCheckProduct(event, checked, product.id)}
                                            />
                                          )}
                                          <Typography variant='body2'>
                                            {product.name} {getApplicationShortLabel(product.application)}
                                          </Typography>
                                        </Grid>
                                        {(event.state === EventState.SCHEDULED ||
                                          event.state === EventState.ACCEPTED) && (
                                          <Grid item xs={5} style={flexLeftRow}>
                                            <Typography
                                              variant='body2'
                                              color={theme.palette.secondary.dark}
                                              sx={{ fontStyle: 'italic', fontSize: '12px' }}
                                            >
                                              {getProductOrderState(product.orderState)}
                                            </Typography>
                                          </Grid>
                                        )}
                                      </Grid>
                                    ))}
                                {/* Champs AUTRES ... */}
                                {[EventState.SCHEDULED, EventState.ACCEPTED].includes(event.state) && (
                                  <Grid container columnSpacing={2}>
                                    <Grid item xs={isOtherPartFieldVisible ? 4 : 12} style={flexLeftRow}>
                                      <Checkbox
                                        size='small'
                                        sx={{
                                          p: 0.5,
                                          backgroundColor: 'transparent',
                                        }}
                                        checked={isOtherPartFieldVisible}
                                        onChange={handleCheckOtherPart}
                                      />
                                      <Typography variant='body2'>Autre...</Typography>
                                    </Grid>
                                    {isOtherPartFieldVisible && (
                                      <Grid item xs={8}>
                                        <TextField
                                          id='other-part'
                                          size='small'
                                          autoFocus
                                          placeholder='Précisez...'
                                          onChange={handleChangeOtherPart}
                                          variant='standard'
                                          sx={{ width: '95%' }}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                              </Grid>
                              <Grid item xs={12} style={getOrderTitleStyle()}>
                                <Typography
                                  variant='button'
                                  color='white'
                                  sx={{ pl: 1, color: darken(getEventStateColor(localEvent, theme), 0.1) }}
                                >
                                  <b>2. CONSOMMABLES</b>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ pb: 1 }}>
                                {/* LES CONSOMMABLES */}
                                {localEvent.products &&
                                  localEvent.products.length > 0 &&
                                  localEvent.products
                                    .filter((p) => p.type === ProductType.CONSUMABLE)
                                    // On filtre les doublons produit selon l'id
                                    .filter(
                                      (product, index, self) =>
                                        index ===
                                        self.findIndex(
                                          (p) => p.name.trim().toLowerCase() === product.name.trim().toLowerCase(),
                                        ),
                                    )
                                    .slice() // créer une copie pour ne pas muter l'original
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((product) => (
                                      <Grid container key={product.id} columnSpacing={2}>
                                        <Grid
                                          item
                                          xs={
                                            (event.state === EventState.SCHEDULED ||
                                              event.state === EventState.ACCEPTED) &&
                                            product.orderState !== OrderState.NEW
                                              ? 7
                                              : 12
                                          }
                                          style={flexLeftRow}
                                        >
                                          {(event.state === EventState.SCHEDULED ||
                                            event.state === EventState.ACCEPTED) && (
                                            <Checkbox
                                              size='small'
                                              sx={{
                                                p: 0.5,
                                                backgroundColor: 'transparent',
                                              }}
                                              onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                                checked: boolean,
                                              ) => handleCheckProduct(event, checked, product.id)}
                                            />
                                          )}
                                          <Typography variant='body2'>{product.name}</Typography>
                                        </Grid>
                                        {(event.state === EventState.SCHEDULED ||
                                          event.state === EventState.ACCEPTED) && (
                                          <Grid item xs={5} style={flexLeftRow}>
                                            <Typography
                                              variant='body2'
                                              color={theme.palette.secondary.dark}
                                              sx={{ fontStyle: 'italic', fontSize: '12px' }}
                                            >
                                              {getProductOrderState(product.orderState)}
                                            </Typography>
                                          </Grid>
                                        )}
                                      </Grid>
                                    ))}
                                {/* Champs AUTRES ... */}
                                {[EventState.SCHEDULED, EventState.ACCEPTED].includes(event.state) && (
                                  <Grid container columnSpacing={2}>
                                    <Grid item xs={isOtherConsumableFieldVisible ? 4 : 12} style={flexLeftRow}>
                                      <Checkbox
                                        size='small'
                                        sx={{
                                          p: 0.5,
                                          backgroundColor: 'transparent',
                                        }}
                                        checked={isOtherConsumableFieldVisible}
                                        onChange={handleCheckOtherConsumable}
                                      />
                                      <Typography variant='body2'>Autre...</Typography>
                                    </Grid>
                                    {isOtherConsumableFieldVisible && (
                                      <Grid item xs={8}>
                                        <TextField
                                          id='other-consumable'
                                          size='small'
                                          autoFocus
                                          placeholder='Précisez...'
                                          onChange={handleChangeOtherConsumable}
                                          variant='standard'
                                          sx={{ width: '95%' }}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                              </Grid>

                              {/** Les éléments 3 et 4 de la commande ne sont affichés que pour ce statut */}
                              {event.state === EventState.SCHEDULED && (
                                <>
                                  <Grid item xs={12} style={flexStart}>
                                    <Tooltip
                                      placement='right'
                                      title={isOrderedTooltipDisabled ? 'Cochez un produit (ou Autre...)' : ''}
                                      arrow
                                    >
                                      {/* Le 'span' permet à la Tooltip de fonctionner même si le Button est disabled */}
                                      <span>
                                        <Button
                                          size='small'
                                          variant='text'
                                          color='inherit'
                                          startIcon={<FactCheckIcon />}
                                          disabled={!isOrderedPossible()}
                                          onClick={(e) => handleOpenOrdered()}
                                          sx={{ opacity: 1, px: 1 }}
                                        >
                                          COMMANDE PASSÉE
                                        </Button>
                                      </span>
                                    </Tooltip>
                                  </Grid>
                                  <Grid container sx={{ mt: 2, mb: 2, justifyContent: 'center', display: 'flex' }}>
                                    <Grid item xs={12} sx={{ mr: 2, mb: 3 }}>
                                      <Typography
                                        variant='button'
                                        sx={{
                                          color: darken(getEventStateColor(localEvent, theme), 0.1),
                                          fontSize: '0.9rem',
                                        }}
                                      >
                                        <Grid container style={flexCenter}>
                                          <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            style={getOrderTitleStyleCut()}
                                            sx={{ mb: { xs: 2, md: 0 } }}
                                          >
                                            <b>3. ENVOI DEMANDE</b>
                                          </Grid>
                                          <Grid item xs={12} md={6}>
                                            <FormControl fullWidth size='small' variant='standard'>
                                              <Select
                                                displayEmpty
                                                size='small'
                                                labelId='supplier-select-label'
                                                id='supplier-select'
                                                value={form.supplier?.value}
                                                onChange={(newValue) => handleChange('supplier', newValue.target.value)}
                                                sx={{
                                                  p: 0,
                                                  color: darken(getEventStateColor(localEvent, theme), 0.1),
                                                }}
                                                IconComponent={(props) => <ExpandMoreIcon {...props} />} // Personnalise la flèche du Select
                                                inputProps={{
                                                  sx: {
                                                    paddingRight: '30px', // Ajoute un padding à droite pour laisser de la place pour la flèche
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    textWrap: 'wrap !important',
                                                  },
                                                }}
                                              >
                                                <MenuItem disabled value=''>
                                                  <Typography
                                                    sx={{
                                                      fontSize: '12px',
                                                    }}
                                                  >
                                                    <em>Fournisseur ...</em>
                                                  </Typography>
                                                </MenuItem>
                                                {(localGarage?.suppliers || []).map((supplier, index) => (
                                                  <MenuItem value={supplier.id}>
                                                    <Typography variant='body2'>{supplier.name}</Typography>
                                                  </MenuItem>
                                                ))}
                                                <MenuItem
                                                  key={-1}
                                                  value={-1}
                                                  sx={{ backgroundColor: theme.palette.grey[200] }}
                                                >
                                                  <div
                                                    style={{
                                                      textAlign: 'left',
                                                      display: 'flex',
                                                      justifyContent: 'left',
                                                      alignItems: 'center',
                                                      fontSize: '13px',
                                                    }}
                                                  >
                                                    <AddIcon />
                                                    &nbsp;AJOUTER
                                                  </div>
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                        </Grid>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pb: 2 }} style={isMobile ? undefined : flexCenter}>
                                      <Grid item xs={isMobile ? 12 : 3} sx={{ mb: isMobile ? 1 : 0 }}>
                                        <Tooltip
                                          placement='top'
                                          title={
                                            <>
                                              {defaultTooltipSupplierDisabledButton()}

                                              {isSupplierRequestPossible() &&
                                                isOrderedPossible() &&
                                                (haveOrderPortail() ? (
                                                  <span>
                                                    {' '}
                                                    La plaque d'immatriculation sera copiée dans le presse-papier
                                                  </span>
                                                ) : (
                                                  <span>Site web fournisseur manquant</span>
                                                ))}
                                            </>
                                          }
                                          arrow
                                        >
                                          <span>
                                            {haveOrderPortail() ? (
                                              <Button
                                                size='small'
                                                variant='text'
                                                color='inherit'
                                                startIcon={<LanguageRoundedIcon />}
                                                disabled={!(isSupplierRequestPossible() && isOrderedPossible())}
                                                onClick={(e) => handleOpenSupplierRequest(SupplierRequestType.WEB)}
                                                fullWidth={isMobile}
                                                sx={{
                                                  opacity: 1,
                                                  backgroundColor: theme.palette.grey[200],
                                                  px: 1,
                                                  minHeight: '48px',
                                                }}
                                              >
                                                SITE WEB
                                              </Button>
                                            ) : !(isSupplierRequestPossible() && isOrderedPossible()) ? (
                                              <Button
                                                size='small'
                                                variant='text'
                                                color='inherit'
                                                startIcon={<LanguageRoundedIcon />}
                                                disabled={true}
                                                fullWidth={isMobile}
                                                sx={{
                                                  opacity: 1,
                                                  backgroundColor: theme.palette.grey[200],
                                                  px: 1,
                                                  minHeight: '48px',
                                                }}
                                              >
                                                SITE WEB
                                              </Button>
                                            ) : (
                                              <Button
                                                size='small'
                                                variant='text'
                                                color='inherit'
                                                startIcon={<LanguageRoundedIcon />}
                                                disabled={!(isSupplierRequestPossible() && isOrderedPossible())}
                                                fullWidth={isMobile}
                                                onClick={(e) => {
                                                  supplierModified.current = localGarage?.suppliers?.find(
                                                    (s) => s.id === form.supplier?.value,
                                                  );
                                                  setOpenAddSupplier(true);
                                                }}
                                                sx={{
                                                  opacity: 1,
                                                  backgroundColor: theme.palette.grey[200],
                                                  px: 1,
                                                  minHeight: '48px',
                                                }}
                                              >
                                                <Typography
                                                  variant='body2'
                                                  sx={{ fontSize: '0.8rem', animation: 'blink 1.5s infinite' }}
                                                >
                                                  SITE WEB
                                                </Typography>
                                              </Button>
                                            )}
                                          </span>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item xs={isMobile ? 12 : 5} sx={{ mb: isMobile ? 1 : 0 }}>
                                        <Tooltip
                                          placement='top'
                                          title={
                                            <>
                                              {defaultTooltipSupplierDisabledButton()}

                                              {isSupplierRequestPossible() &&
                                                isOrderedPossible() &&
                                                (haveSupplierEmail() ? (
                                                  <span> Envoi par mail le bon de commande au fournisseur</span>
                                                ) : (
                                                  <span>Email fournisseur manquant</span>
                                                ))}
                                            </>
                                          }
                                          arrow
                                        >
                                          <span>
                                            {haveSupplierEmail() ? (
                                              <Button
                                                size='small'
                                                variant='text'
                                                color='inherit'
                                                startIcon={<SupplierOrderRequestIcon />}
                                                disabled={!(isSupplierRequestPossible() && isOrderedPossible())}
                                                onClick={(e) => handleOpenSupplierRequest(SupplierRequestType.ORDER)}
                                                fullWidth={isMobile}
                                                sx={{
                                                  opacity: 1,
                                                  backgroundColor: theme.palette.grey[200],
                                                  px: 1,
                                                  minHeight: '48px',
                                                }}
                                              >
                                                BON DE COMMANDE
                                              </Button>
                                            ) : !(isSupplierRequestPossible() && isOrderedPossible()) ? (
                                              <Button
                                                size='small'
                                                variant='text'
                                                color='inherit'
                                                startIcon={<SupplierOrderRequestIcon />}
                                                disabled={true}
                                                fullWidth={isMobile}
                                                sx={{
                                                  opacity: 1,
                                                  backgroundColor: theme.palette.grey[200],
                                                  px: 1,
                                                  minHeight: '48px',
                                                }}
                                              >
                                                BON DE COMMANDE
                                              </Button>
                                            ) : (
                                              <Button
                                                size='small'
                                                variant='text'
                                                color='inherit'
                                                startIcon={<SupplierOrderRequestIcon />}
                                                disabled={!(isSupplierRequestPossible() && isOrderedPossible())}
                                                fullWidth={isMobile}
                                                onClick={(e) => {
                                                  supplierModified.current = localGarage?.suppliers?.find(
                                                    (s) => s.id === form.supplier?.value,
                                                  );
                                                  setOpenAddSupplier(true);
                                                }}
                                                sx={{
                                                  opacity: 1,
                                                  backgroundColor: theme.palette.grey[200],
                                                  px: 1,
                                                  minHeight: '48px',
                                                }}
                                              >
                                                <Typography
                                                  variant='body2'
                                                  sx={{ fontSize: '0.8rem', animation: 'blink 1.5s infinite' }}
                                                >
                                                  BON DE COMMANDE
                                                </Typography>
                                              </Button>
                                            )}
                                          </span>
                                        </Tooltip>
                                      </Grid>

                                      <Grid item xs={isMobile ? 12 : 4}>
                                        <Tooltip
                                          placement='top'
                                          title={
                                            <>
                                              {defaultTooltipSupplierDisabledButton()}
                                              {isSupplierRequestPossible() &&
                                                isOrderedPossible() &&
                                                (haveSupplierPhoneNumber() ? (
                                                  <span>Sur smartphone, déclenche l'appel</span>
                                                ) : (
                                                  <span>Numéro du fournisseur manquant</span>
                                                ))}
                                            </>
                                          }
                                          arrow
                                        >
                                          {/* Le 'span' permet à la Tooltip de fonctionner même si le Button est disabled */}

                                          <span>
                                            {haveSupplierPhoneNumber() ? (
                                              <Button
                                                size='small'
                                                variant='text'
                                                color='inherit'
                                                startIcon={<PhoneIcon />}
                                                fullWidth={isMobile}
                                                disabled={!(isSupplierRequestPossible() && isOrderedPossible())}
                                                onClick={(e) => {
                                                  window.location.href = `tel:${localGarage?.suppliers?.filter((s) => s.id === form?.supplier?.value)[0].phoneNumber}`;
                                                }}
                                                sx={{
                                                  opacity: 1,
                                                  backgroundColor: theme.palette.grey[200],
                                                  px: 1,
                                                  minHeight: '48px',
                                                }}
                                              >
                                                {getSupplierPhoneNumber(form?.supplier?.value!)}
                                              </Button>
                                            ) : !(isSupplierRequestPossible() && isOrderedPossible()) ? (
                                              <Button
                                                size='small'
                                                variant='text'
                                                color='inherit'
                                                startIcon={<PhoneIcon />}
                                                disabled={true}
                                                fullWidth={isMobile}
                                                sx={{
                                                  opacity: 1,
                                                  backgroundColor: theme.palette.grey[200],
                                                  px: 1,
                                                  minHeight: '48px',
                                                }}
                                              >
                                                N° téléphone
                                              </Button>
                                            ) : (
                                              <Button
                                                size='small'
                                                variant='text'
                                                color='inherit'
                                                startIcon={<PhoneIcon />}
                                                disabled={!(isSupplierRequestPossible() && isOrderedPossible())}
                                                fullWidth={isMobile}
                                                onClick={(e) => {
                                                  supplierModified.current = localGarage?.suppliers?.find(
                                                    (s) => s.id === form.supplier?.value,
                                                  );
                                                  setOpenAddSupplier(true);
                                                }}
                                                sx={{
                                                  opacity: 1,
                                                  backgroundColor: theme.palette.grey[200],
                                                  px: 1,
                                                  minHeight: '48px',
                                                }}
                                              >
                                                <Typography
                                                  variant='body2'
                                                  sx={{ fontSize: '0.8rem', animation: 'blink 1.5s infinite' }}
                                                >
                                                  N° téléphone
                                                </Typography>
                                              </Button>
                                            )}
                                          </span>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: isMobile ? 1 : 0 }}>
                                      <Tooltip placement='top' title={defaultTooltipSupplierDisabledButton()} arrow>
                                        <span>
                                          <LoadingButton
                                            loading={loadingPdf}
                                            size='small'
                                            variant='text'
                                            color='inherit'
                                            startIcon={<FileDownload />}
                                            disabled={!(isSupplierRequestPossible() && isOrderedPossible())}
                                            onClick={(e) => handleOpenSupplierRequest(SupplierRequestType.ORDER_PDF)}
                                            sx={{
                                              opacity: 1,
                                              backgroundColor: theme.palette.grey[200],
                                              px: 1,
                                              minHeight: '48px',
                                            }}
                                            fullWidth
                                          >
                                            Télécharger le bon de commande
                                          </LoadingButton>
                                        </span>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </>
                        )}
                    </Grid>
                  )}

                {/* **************************************************************************************
                                LIGNE 1  (CREATE), Type d'événement à créer
                                MVP : On ne l'affiche pas pour le moment, puisque nous ne pouvons créer que des RDV
                            * ***************************************************************************************/}
                {MyEventDialog.CREATE === type && (
                  /* headers - footer - 2 * padding dialog */
                  <Grid container sx={{ p: 0 }}>
                    <Grid item xs={12} sx={{ textAlign: 'left' }}>
                      <StyledEventTypeToggleButtonGroup
                        eventType={form.type.value}
                        sx={{ mb: 0, mt: 0.5 }}
                        style={flexCenter}
                        size='small'
                        value={form.type.value}
                        exclusive
                        onChange={(e: any, value: null) => {
                          if (value !== null) handleChange('type', value);
                        }}
                        aria-label='event-type'
                      >
                        <ToggleButton
                          sx={{ mb: '8px ! important', padding: 'Opx', minHeight: '56px' }}
                          size='small'
                          value={EventType.APPOINTMENT}
                          selected={form.type.value === EventType.APPOINTMENT}
                          aria-label='appointment type'
                        >
                          <span>
                            <b>Rendez-vous</b>
                            <Typography sx={{ fontSize: '0.7rem', textTransform: 'none' }}>✓ Confirmé</Typography>
                          </span>
                        </ToggleButton>
                        <ToggleButton
                          sx={{ mb: '8px ! important' }}
                          size='small'
                          value={EventType.PENDING_APPOINTMENT}
                          selected={form.type.value === EventType.PENDING_APPOINTMENT}
                          aria-label='appointment type'
                        >
                          <span>
                            <b>Rendez-vous</b>
                            <Typography sx={{ fontSize: '0.7rem', textTransform: 'none' }}>À confirmer ...</Typography>
                          </span>
                        </ToggleButton>
                        <ToggleButton
                          sx={{ mb: '8px ! important', minHeight: '56px', minWidth: '70px' }}
                          size='small'
                          value={EventType.UNAVAILABILITY}
                          selected={form.type.value === EventType.UNAVAILABILITY}
                          aria-label='unavailability type'
                        >
                          <b>Indispo</b>
                        </ToggleButton>
                        <ToggleButton
                          sx={{ mb: '8px ! important', minHeight: '56px', minWidth: '70px' }}
                          size='small'
                          value={EventType.NOTE}
                          selected={form.type.value === EventType.NOTE}
                          aria-label='note type'
                        >
                          <b>Note</b>
                        </ToggleButton>
                      </StyledEventTypeToggleButtonGroup>
                    </Grid>
                    {form.type.value === EventType.PENDING_APPOINTMENT && (
                      <Grid item xs={12} sx={{ m: 1.5 }}>
                        <Typography
                          color={theme.palette.text.secondary}
                          sx={{ fontFamily: 'CarterOne', fontSize: '0.9rem', lineHeight: '1.3rem' }}
                        >
                          <b style={flexCenter}>Rendez-vous en attente que vous devrez confirmer ensuite.</b>
                        </Typography>
                        {garage?.teamManagementActive && (
                          <Typography
                            color={theme.palette.primary.dark}
                            sx={{ fontSize: '0.9rem', textAlign: { xs: 'center', md: 'start' } }}
                            style={flexCenter}
                          >
                            Utile si vous attendez un acompte de votre client avant de valider le rendez-vous.
                          </Typography>
                        )}
                      </Grid>
                    )}
                    {form.type.value === EventType.UNAVAILABILITY && (
                      <Grid item xs={12} sx={{ m: 1.5 }}>
                        <Typography
                          color={theme.palette.text.secondary}
                          sx={{ fontFamily: 'CarterOne', fontSize: '0.9rem', lineHeight: '1.3rem' }}
                        >
                          <b style={flexCenter}>
                            Vos clients ne pourront plus prendre rendez-vous en ligne sur ce créneau
                          </b>
                        </Typography>
                        {garage?.teamManagementActive && (
                          <Typography
                            color={theme.palette.primary.dark}
                            sx={{ fontSize: '0.9rem', textAlign: { xs: 'center', md: 'start' } }}
                            style={flexCenter}
                          >
                            L'indisponibilité est globale sauf si vous lui affectez un réparateur.
                          </Typography>
                        )}
                      </Grid>
                    )}
                    {form.type.value === EventType.NOTE && (
                      <Grid item xs={12} sx={{ m: 1.5 }}>
                        <Typography
                          color={theme.palette.text.secondary}
                          sx={{ fontFamily: 'CarterOne', fontSize: '0.9rem', lineHeight: '1.3rem' }}
                        >
                          <b style={flexCenter}>La note fonctionne comme un post-it !</b>
                        </Typography>
                        {garage?.teamManagementActive && (
                          <Typography
                            color={theme.palette.primary.dark}
                            sx={{ fontSize: '0.9rem', textAlign: { xs: 'center', md: 'start' } }}
                            style={flexCenter}
                          >
                            Elle est globale sauf si vous lui affectez un réparateur.
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                )}

                {/* *******************************************
                                LIGNE 2  (CREATE), Choix du créneau horaire
                            * **********************************************/}
                {MyEventDialog.CREATE === type && (
                  <>
                    <Grid container sx={{ mt: 1, display: 'flex' }}>
                      <Grid
                        item
                        xs={1}
                        sx={{
                          height: '100%',
                          display: { xs: 'none', md: 'flex' },
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <EventScheduleIcon className='styled-icon' />
                      </Grid>
                      <Grid item xs={12} md={11} sx={{ mt: 1 }}>
                        <DateTimePickerGroup
                          dateLabel={
                            form.type.value === EventType.APPOINTMENT ||
                            form.type.value === EventType.PENDING_APPOINTMENT
                              ? 'Début intervention'
                              : 'Début'
                          }
                          timeLabel='Heure'
                          value={form.start.value}
                          onChange={(newValue) => handleChange('start', newValue)}
                          sx={{ width: { xs: '90%', md: '70%' }, mb: 2, ml: { xs: 1, md: 0 } }}
                          disableTimePicker={form.type.value !== EventType.APPOINTMENT && isEventPlannedForAllDay}
                        />
                        <DateTimePickerGroup
                          dateLabel={
                            form.type.value === EventType.APPOINTMENT ||
                            form.type.value === EventType.PENDING_APPOINTMENT
                              ? 'Fin intervention'
                              : 'Fin'
                          }
                          timeLabel='Heure'
                          value={form.end.value}
                          onChange={(newValue) => handleChange('end', newValue)}
                          sx={{ width: { xs: '90%', md: '70%' }, ml: { xs: 1, md: 0 } }}
                          disableTimePicker={
                            (form.type.value === EventType.UNAVAILABILITY || form.type.value === EventType.NOTE) &&
                            isEventPlannedForAllDay
                          }
                        />
                      </Grid>
                    </Grid>
                    {(form.type.value === EventType.UNAVAILABILITY || form.type.value === EventType.NOTE) && (
                      <Grid container sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={1} />
                        <Checkbox
                          size='medium'
                          sx={{
                            p: 0.5,
                            backgroundColor: 'transparent',
                          }}
                          checked={isEventPlannedForAllDay}
                          onChange={handleCheckPlanEventForAllDay}
                        />
                        <Typography variant='body1'>Afficher sur toute la journée</Typography>
                      </Grid>
                    )}
                  </>
                )}

                {/** Affichage, choix de l'employé */}
                {MyEventDialog.CREATE === type && garage?.teamManagementActive && (
                  <Grid container sx={{ mt: 2, display: 'flex' }}>
                    {/** Affichage, choix de l'employé */}
                    <EmployeeChoice
                      styledIcon
                      localEvent={localEvent}
                      garage={garage}
                      type={type}
                      refreshEvent={refreshEvent}
                      employee={event.resourceId ? String(event.resourceId) : ''}
                      onChange={handleEmployeeChange}
                      selectedPrestationsId={form?.prestations?.value || []}
                    />
                  </Grid>
                )}

                {/* *****************************************************
                                LIGNE 3 (CREATE), catégorie et pictogramme indicatif
                            * *******************************************************/}
                {MyEventDialog.CREATE === type &&
                  (form.type.value === EventType.APPOINTMENT || form.type.value === EventType.PENDING_APPOINTMENT ? (
                    <>
                      <Grid container sx={{ mt: 2 }}>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            height: '100%',
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <PrestationsIcon className='styled-icon' />
                        </Grid>
                        <Grid item xs={12} md={11} sx={{ ml: { xs: 1, md: 0 }, display: 'flex', alignItems: 'center' }}>
                          <PickerPrestation
                            form={form}
                            handleChange={handleChange}
                            selectedPrestations={selectedPrestations}
                            localGarage={localGarage}
                          />
                        </Grid>
                      </Grid>
                      {localGarage.prestations
                        .filter((p) => (form.prestations?.value || []).includes(p.id))
                        .find((p) => p.code === 'OTHER') && (
                        <Grid container sx={{ mt: 2 }}>
                          <Grid
                            item
                            xs={1}
                            sx={{
                              height: '100%',
                              display: { xs: 'none', md: 'flex' },
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Title sx={{ color: theme.palette.grey[500] }} />
                          </Grid>
                          <Grid item xs={12} md={11} sx={{ ml: { xs: 1, md: 0 } }}>
                            <TextField
                              id='event-other-reason'
                              size='small'
                              label='Autre, précisez la prestation...'
                              name='otherReason'
                              error={Boolean(form.otherReason?.error)}
                              helperText={form.otherReason?.error}
                              value={form.otherReason?.value}
                              required
                              onChange={(e) => handleChange('otherReason', e.target.value)}
                              variant='standard'
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid container sx={{ mt: 2 }}>
                      <Grid
                        item
                        xs={1}
                        sx={{
                          height: '100%',
                          display: { xs: 'none', md: 'flex' },
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <PrestationsIcon className='styled-icon' />
                      </Grid>
                      <Grid xs={11}>
                        <TextField
                          id='event-title'
                          label='Titre'
                          value={form.title.value}
                          required={EventType.APPOINTMENT !== form.type.value}
                          onChange={(e) => handleChange('title', e.target.value)}
                          variant='standard'
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  ))}

                {/* **************************************************
                                LIGNE 4 (CREATE), choix du client et du véhicule et vehicule de prêts
                            * ***************************************************/}
                {MyEventDialog.CREATE === type && (
                  <>
                    <Grid
                      container
                      sx={{
                        mt: 2,
                        alignItems: 'flex-end',
                        display:
                          form.type.value === EventType.APPOINTMENT || form.type.value === EventType.PENDING_APPOINTMENT
                            ? 'flex'
                            : 'none',
                      }}
                    >
                      <Grid
                        item
                        xs={1}
                        sx={{
                          height: '100%',
                          display: { xs: 'none', md: 'flex' },
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CustomerSearchIcon className='styled-icon' />
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ pr: { xs: 0, md: 2 }, ml: { xs: 1, md: 0 } }}>
                        <FormControl error={Boolean(form.customer?.error)} fullWidth>
                          <Autocomplete<Customer>
                            onOpen={() => setAutocompleteOpen(true)}
                            onClose={() => setAutocompleteOpen(false)}
                            onKeyDownCapture={(e) => focusSearchCustomer(e)}
                            open={autocompleteOpen}
                            openOnFocus
                            value={form.customer?.value || null}
                            onChange={(event: any, newValue: Customer | null) => {
                              handleChange('customer', newValue, false);
                            }}
                            onInputChange={(event, newInputValue) => {
                              handleCustomerInputChange(newInputValue);
                            }}
                            id='grouped-customers'
                            filterOptions={customFilterOptions}
                            options={customers?.sort((a, b) =>
                              getCustomerFirstLetter(b).localeCompare(getCustomerFirstLetter(a)),
                            )}
                            groupBy={(customer) => getCustomerFirstLetter(customer)}
                            getOptionLabel={(customer) => {
                              if (customer.type === CustomerType.PROFESSIONAL && customer.companyName) {
                                return customer.companyName?.toUpperCase(); // Affiche le nom de l'entreprise pour un professionnel
                              }
                              const label =
                                `${customer.lastname?.toUpperCase() || ''} ${customer.firstname || ''}` ||
                                customer.phoneNumber ||
                                '';
                              return label;
                            }}
                            renderOption={(props, customer, { index }) => (
                              <>
                                <Grid
                                  container
                                  component='li'
                                  {...props}
                                  sx={{
                                    borderColor: currentFocusedUser === customer.id ? 'black' : 'none',
                                    borderStyle: 'solid',
                                    borderWidth: currentFocusedUser === customer.id ? '1px' : '0px',
                                  }}
                                >
                                  {/* Colonne de 10 avec deux lignes */}
                                  <Grid id={customer.id} item xs={11}>
                                    {/* Première ligne - Nom et prénom */}
                                    <Typography variant='body1'>
                                      {customer.type === CustomerType.INDIVIDUAL && (
                                        <>
                                          {customer.lastname?.toUpperCase()} {customer.firstname}
                                        </>
                                      )}
                                      {customer.type === CustomerType.PROFESSIONAL && (
                                        <>
                                          <BusinessIcon sx={{ fontSize: '0.9rem', mr: 0.5 }} />{' '}
                                          {customer.companyName?.toUpperCase()}
                                        </>
                                      )}
                                    </Typography>

                                    {/* Deuxième ligne - Téléphone */}
                                    <Typography
                                      variant='body2'
                                      color='textSecondary'
                                      style={flexStart}
                                      sx={{ pt: 0.5 }}
                                    >
                                      <PhoneIcon sx={{ fontSize: '0.9rem', mr: 0.5 }} />
                                      {customer.type === CustomerType.PROFESSIONAL && customer.companyPhoneNumber && (
                                        <>
                                          {formatPhoneNumber(customer.companyPhoneNumber)}
                                          {customer.phoneNumber && ` / ${formatPhoneNumber(customer.phoneNumber)}`}
                                        </>
                                      )}
                                      {customer.type === CustomerType.INDIVIDUAL &&
                                        customer.phoneNumber &&
                                        formatPhoneNumber(customer.phoneNumber)}
                                    </Typography>
                                  </Grid>

                                  {/* Colonne de 2 avec une ligne */}
                                  <Grid item xs={1} display='flex' justifyContent='center' alignItems='center'>
                                    <IconButton
                                      onClick={(event) => {
                                        event.stopPropagation(); // Empêche la propagation du clic vers l'option de l'Autocomplete
                                        handleOpenEditCustomer(customer);
                                      }}
                                      size='small'
                                      sx={{
                                        border: `2px solid ${theme.palette.primary.main}`,
                                        //backgroundColor: theme.palette.primary.main,
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                            ListboxComponent={(listboxProps) => {
                              const { children, ...rest } = listboxProps;

                              return (
                                <ul {...rest}>
                                  {/* Rendre toutes les options */}
                                  {children}

                                  {/* Ajouter le bouton à la fin de la liste */}
                                  <li key='create-customer-button'>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 1 }}>
                                      <CreateCustomerButton onClick={handleOpenAddCustomer} />
                                    </Box>
                                  </li>
                                </ul>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Recherche Client *'
                                variant='standard'
                                placeholder='Nom, prénom, numéro...'
                                error={Boolean(form.customer?.error)}
                                onChange={(e) => setCurrentCustomerInput(e.target.value)}
                              />
                            )}
                            renderGroup={(params) => (
                              <li key={params.key}>
                                <StyledGroupHeader>{params.group}</StyledGroupHeader>
                                <StyledGroupItems>{params.children}</StyledGroupItems>
                              </li>
                            )}
                            noOptionsText={
                              <Grid container style={flexCenter}>
                                <Grid item xs={12}>
                                  <Typography style={flexCenter} sx={{ mb: 1 }}>
                                    Aucun résultat
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <CreateCustomerButton onClick={handleOpenAddCustomer} />
                                </Grid>
                              </Grid>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5} sx={{ ml: { xs: 1, md: 0 } }}>
                        {form.vehiclePlate && form.vehiclePlate.value !== undefined ? (
                          <Fragment>
                            <InputLabel id='vehicle-select-label' sx={{ fontSize: '0.8rem', marginBottom: '5px' }}>
                              Choix véhicule
                            </InputLabel>
                            <Typography sx={{ lineHeight: 1 }}>
                              <div style={{ textAlign: 'left' }}>
                                <strong>{form.vehiclePlate.value}</strong>
                                <br />
                                <span style={{ fontSize: '12px' }}>{vehicleDescription}</span>
                              </div>
                            </Typography>
                          </Fragment>
                        ) : (
                          <FormControl variant='standard' fullWidth disabled={!form.customer?.value}>
                            <InputLabel id='vehicle-select-label'>Choix véhicule</InputLabel>
                            <Select
                              labelId='vehicle-select-label'
                              id='vehicle-select'
                              value={form.vehicleId?.value}
                              onChange={(e) => handleChange('vehicleId', e.target.value)}
                              label='Véhicule'
                            >
                              {form.customer?.value &&
                                (form.customer.value as Customer).vehicles.map((vehicle: Vehicle, index) => (
                                  <MenuItem key={index} value={vehicle.id}>
                                    <div style={{ textAlign: 'left' }}>
                                      <strong>{formatFrenchVehiclePlate(vehicle.plate)}</strong>
                                      <br />
                                      <span style={{ fontSize: '12px' }}>
                                        {vehicle.brand} {vehicle.model}
                                      </span>
                                    </div>
                                  </MenuItem>
                                ))}
                              <MenuItem
                                key={-1}
                                value={-1}
                                sx={{
                                  display:
                                    form.customer?.value && (form.customer.value as Customer).vehicles
                                      ? 'block'
                                      : 'none',
                                  backgroundColor: theme.palette.grey[200],
                                }}
                                onClick={() => handleChange('vehicleId', -1)}
                              >
                                <div
                                  style={{
                                    textAlign: 'left',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center',
                                    fontSize: '13px',
                                  }}
                                >
                                  <AddIcon />
                                  &nbsp;SAISIR LA PLAQUE
                                </div>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                    {garage.loanerVehicleActive && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          ml: { xs: 1, md: 0 },
                          display: form.type.value === EventType.APPOINTMENT ? 'flex' : 'none',
                          alignItems: 'center',
                          mt: 2,
                        }}
                      >
                        <Grid
                          item
                          xs={1}
                          sx={{
                            height: '100%',
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CarRentalRoundedIcon className='styled-icon' />
                        </Grid>
                        <Grid item xs={11} md={10}>
                          <Tooltip
                            title={availableVehicles.length === 0 ? 'Aucun véhicule de prêt disponible' : ''}
                            placement='top'
                            arrow
                          >
                            <span>
                              <FormControl fullWidth variant='standard'>
                                <InputLabel id='loan-vehicle-select'>Véhicle de prêt</InputLabel>
                                <Select
                                  labelId='loan-vehicle-select'
                                  size='small'
                                  label='Véhicule de prêt'
                                  id='loan-vehicle-select-id'
                                  disabled={availableVehicles.length === 0}
                                  value={form.garageVehicleId?.value}
                                  onChange={
                                    (e) => handleChange('garageVehicleId', e.target.value) // Mettre à jour la valeur sélectionnée
                                  }
                                >
                                  {availableVehicles
                                    .sort((a: VehicleGarage, b: VehicleGarage) => a.index - b.index)
                                    .map((vehicle) => (
                                      <MenuItem key={`loanVehicle-${vehicle.id}`} value={vehicle.id}>
                                        <strong>
                                          {' '}
                                          VP{vehicle.index} - {vehicle.vehicle.brand} {vehicle.vehicle.model}
                                        </strong>{' '}
                                        &nbsp;/&nbsp;{formatFrenchVehiclePlate(vehicle.vehicle.plate)}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </span>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={1} sx={{ alignSelf: 'flex-end' }}>
                          <IconButton
                            disabled={
                              availableVehicles.length === 0 || typeof form?.garageVehicleId?.value !== typeof 666
                            }
                            onClick={() => handleChange('garageVehicleId', '')}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}

                {/* *******************************************************
                                LIGNE (CREATE), Choix dépôt du véhicule (si applicable)
                            * ********************************************************/}
                {MyEventDialog.CREATE === type &&
                  garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD &&
                  form.type.value === EventType.APPOINTMENT &&
                  getDepositVehicleFields()}

                {/* *****************************************************
                                LIGNE 5 (CREATE), notes
                            * ******************************************************/}
                {MyEventDialog.CREATE === type && (
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        mt: 2,
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NotesIcon className='styled-icon' />
                    </Grid>
                    {MyEventDialog.CREATE === type && (
                      <Grid item xs={11} sx={{ mt: 2 }}>
                        <CustomTextarea
                          value={form.notes?.value || ''}
                          onChange={(e) => handleChange('notes', e)}
                          error={form.notes?.error}
                          helperText={
                            !form.notes?.error && (
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <PrivateIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                                Non visible par le client
                              </Box>
                            )
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                )}

                {/* *****************************************************
                                LIGNE 6 (CREATE), éventuel numéro de devis
                            * ******************************************************/}
                {EventType.APPOINTMENT === form.type.value &&
                  MyEventDialog.CREATE === type &&
                  localGarage?.customerQuoteActive &&
                  getQuoteGrid()}

                {EventType.APPOINTMENT === form.type.value &&
                  MyEventDialog.CREATE === type &&
                  !localGarage?.customerQuoteActive && (
                    <Grid
                      container
                      sx={{
                        alignItems: 'center',
                        display: event?.state === EventState.NEW ? 'none' : 'inherit',
                      }}
                    >
                      <Grid item xs={1} sx={{ mt: 3 }} style={flexCenterCol}>
                        <QuoteIcon
                          sx={{ margin: 'auto' }}
                          className={MyEventDialog.CREATE === type ? 'styled-icon' : ''}
                        />
                      </Grid>

                      {MyEventDialog.CREATE === type && (
                        <Grid item xs={11} sx={{ mt: 3 }}>
                          <TextField
                            id='event-quote-number'
                            label='N° de devis'
                            variant='outlined'
                            value={form.quoteId?.value}
                            onChange={(e) => handleChange('quoteId', e.target.value)}
                            fullWidth
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
              </Grid>
            )}

            {/* *********************
                        ALERTES éventuelles
                    * ***********************/}

            {MyEventDialog.DETAILS === type &&
              form.type.value === EventType.APPOINTMENT &&
              event.state === EventState.NEW &&
              !showVehicleCard && (
                <Alert
                  severity={
                    isInvalidMobileNumber(event.customer?.companyPhoneNumber) &&
                    isInvalidMobileNumber(event.customer?.phoneNumber)
                      ? 'warning'
                      : 'success'
                  }
                  sx={{ mx: 2, mt: 2, borderRadius: 20 }}
                  icon={<SmsIcon />}
                  className={
                    isInvalidMobileNumber(event.customer?.companyPhoneNumber) &&
                    isInvalidMobileNumber(event.customer?.phoneNumber)
                      ? 'styled-warning-alert'
                      : 'styled-alert'
                  }
                >
                  {isInvalidMobileNumber(event.customer?.companyPhoneNumber) &&
                  isInvalidMobileNumber(event.customer?.phoneNumber)
                    ? 'Le format du numéro ne permet pas l’envoi du SMS de confirmation'
                    : 'Votre client recevra automatiquement votre réponse par SMS'}

                  {/* Détection d'un changement d'heure de dépôt du véhicule à la création */}
                  {MyEventDialog.DETAILS === type &&
                    form.type.value === EventType.APPOINTMENT &&
                    event.state === EventState.NEW &&
                    !showVehicleCard &&
                    isVehicleDepositDateUpdated() && (
                      <>
                        , vous avez modifié l'heure{' '}
                        {garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD ? (
                          <>de dépôt du véhicule</>
                        ) : (
                          <>du rendez-vous</>
                        )}
                        , nous lui indiquerons 😉
                      </>
                    )}
                </Alert>
              )}

            <Box
              sx={{
                backgroundColor: {
                  md: event.state === EventState.NEW ? 'white' : theme.palette.grey[100],
                  xs: 'white',
                },
                display: 'flex',
                position: { md: 'sticky', xs: 'relative' },
                bottom: '0',
                paddingBottom: { xs: 1, md: 0 },
                transform: 'translateX(-16px) translateY(8px)',
                width: { md: 'calc(100% + 32px)', xs: '100vw' },
                flexDirection: { xs: 'column', md: 'row' }, // En colonne sur les petits écrans, en ligne à partir de md
                justifyContent: { xs: 'center', md: 'flex-end' }, // Centré pour les petits écrans, aligné à droite pour md+
                alignItems: 'center', // Pour centrer verticalement les boutons
                gap: 2, // Espace entre les boutons
                borderTop: event.state === EventState.NEW ? `1px solid ${theme.palette.grey[200]}` : '1px solid white',
                zIndex: 200,
              }}
            >
              {MyEventDialog.CREATE !== type &&
                [
                  EventState.SCHEDULED,
                  EventState.COMPLETED,
                  EventState.ACCEPTED,
                  EventState.REJECTED,
                  EventState.DONE,
                ].includes(event.state) &&
                !showVehicleCard && (
                  <Button
                    color='inherit'
                    disabled={currentUpload}
                    variant={isMobile ? 'contained' : 'text'}
                    startIcon={<AttachFile />}
                    sx={{ width: { xs: '80%', md: 'auto' } }}
                    onClick={toggleShowConfirmUploadDocument}
                  >
                    {currentUpload && (
                      <Typography variant='body2' sx={{ animation: 'blink 1.5s infinite' }}>
                        importation en cours...
                      </Typography>
                    )}
                    {!currentUpload && 'Ajouter un document'}
                  </Button>
                )}
              {[EventState.SCHEDULED, EventState.COMPLETED].includes(event.state) && !showVehicleCard && (
                <Button
                  color='inherit'
                  variant={isMobile ? 'contained' : 'text'}
                  startIcon={<FileDownload />}
                  sx={{ width: { xs: '80%', md: 'auto' } }}
                  onClick={() => {lostFocus(); toggleShowConfirmDownloadRepairOrder()}}
                >
                  Imprimer l'OR
                </Button>
              )}
              {EventState.COMPLETED === event.state && !showVehicleCard && (
                <Button
                  color={isMobile ? 'secondary' : 'inherit'}
                  variant={isMobile ? 'contained' : 'text'}
                  startIcon={<Square color='secondary' />}
                  sx={{ width: { xs: '80%', md: 'auto' } }}
                  onClick={() => handlePartsAvailability(false)}
                >
                  Pièces non dispo
                </Button>
              )}

              {EventState.ACCEPTED === event.state &&
                !showVehicleCard &&
                ((form.quote?.value && (form.quote.value as Document).state === DocumentState.PENDING) ||
                  form.quoteAmount) && (
                  <>
                    <Button
                      color='inherit'
                      variant={isMobile ? 'contained' : 'text'}
                      sx={{ width: { xs: '80%', md: 'auto' } }}
                      onClick={() => setOpenCustomerReminder(true)}
                    >
                      Relancer le client
                    </Button>
                    <Button
                      color='inherit'
                      variant={isMobile ? 'contained' : 'text'}
                      sx={{ width: { xs: '80%', md: 'auto' } }}
                      onClick={toggleConfirmAcceptQuoteOpened}
                    >
                      Devis accepté
                    </Button>
                  </>
                )}
            </Box>
          </DialogContent>

          {!showVehicleCard && event && event.state !== EventState.DONE && (
            <DialogActions
              sx={{
                borderTop: 1,
                backgroundColor:
                  MyEventDialog.CREATE === type ? theme.palette.grey[200] : getEventStateColor(event, theme),
                borderColor: theme.palette.grey[200],
                p: 1,
              }}
            >
              {MyEventDialog.DETAILS === type && EventType.APPOINTMENT === event?.type && (
                <Grid container xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Grid item xs={2} style={flexStart}>
                    {EventState.CANCELLED !== event.state && (
                      <Button
                        variant='contained'
                        color='error'
                        sx={{ mt: '2px', minWidth: '110px', ml: 1 }}
                        aria-label='delete'
                        onClick={() => handleDeleteEvent()}
                      >
                        {(EventState.NEW === event.state && localEvent.origin) === 'MOVALIB' ? 'Refuser' : 'Annuler'}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={10} style={flexEnd}>
                    {EventState.NEW === event.state && (
                      <MyLoadingButton
                        type={ButtonType.SUBMIT}
                        onSubmit={handleAcceptEventRequest}
                        className={form.quote?.value || form.quoteAmount?.value ? 'pink-button' : ''}
                        label={form.quote?.value || form.quoteAmount?.value ? 'ENVOYER DEVIS' : 'CONFIRMER'}
                        sx={{
                          backgroundColor:
                            form.quote?.value || form.quoteAmount?.value
                              ? PALETTE_THIRD_COLOR_MAIN
                              : theme.palette.primary.main,
                        }}
                      />
                    )}

                    {EventState.REJECTED === event.state && showSendNewQuoteAction && (
                      <Button variant='contained' color='inherit' sx={{ mr: 2 }} onClick={() => handleSendNewQuote()}>
                        Envoyer le devis
                      </Button>
                    )}
                    {[EventState.SCHEDULED, EventState.COMPLETED].includes(event.state) && showSendNewQuoteAction && (
                      <Button
                        variant='contained'
                        color='inherit'
                        sx={{ mr: 2, backgroundColor: PALETTE_THIRD_COLOR_MAIN }}
                        className='pink-button'
                        onClick={handleSubmitQuote}
                      >
                        Envoyer le devis
                      </Button>
                    )}
                    {EventState.SCHEDULED === event.state && (
                      <Button
                        variant='contained'
                        color='primary'
                        sx={{ mr: 2 }}
                        onClick={() => handlePartsAvailability(true)}
                      >
                        Pièces dispo
                      </Button>
                    )}
                    {EventState.COMPLETED === event.state && (
                      <Button
                        variant='contained'
                        color='inherit'
                        sx={{ mr: 1, backgroundColor: 'white' }}
                        onClick={(e) => {
                          e.preventDefault(); // Empêche la soumission du formulaire
                          if (localEvent.vehicle?.id) {
                            setOpenUploadInvoice(true);
                          } else {
                            // Missing event vehicle, request add by plate
                            handleChange('vehicleId', -1);
                          }
                        }}
                      >
                        Clôturer
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
              {MyEventDialog.DETAILS === type &&
                (EventType.UNAVAILABILITY === event?.type ||
                  (EventType.APPOINTMENT === event?.type && EventState.CANCELLED === event?.state)) && (
                  <Fragment>
                    <Button
                      variant='contained'
                      color='error'
                      sx={{ mt: '2px' }}
                      aria-label='delete'
                      onClick={() => handleDeleteEvent()}
                    >
                      Supprimer
                    </Button>
                  </Fragment>
                )}
              {MyEventDialog.CREATE === type && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <LoadingButton
                    variant='contained'
                    loading={loading}
                    onClick={handleOpenConfirmSubmitEvent}
                    startIcon={form.quote?.value || form.quoteAmount?.value ? <QuoteIcon /> : <SaveOutlined />}
                    className={form.quote?.value || form.quoteAmount?.value ? 'pink-button' : ''}
                    sx={{
                      backgroundColor:
                        form.quote?.value || form.quoteAmount?.value
                          ? PALETTE_THIRD_COLOR_MAIN
                          : theme.palette.primary.main,
                    }}
                  >
                    {form.quote?.value || form.quoteAmount?.value ? 'Envoyer devis' : 'Enregistrer'}
                  </LoadingButton>
                  {(form.type?.value === EventType.APPOINTMENT ||
                    form.type?.value === EventType.PENDING_APPOINTMENT) && (
                    <Tooltip title='Capacité totale atteinte ? Cliquez pour forcer le rendez-vous' enterDelay={750}>
                      <Chip
                        label={
                          <Box style={flexCenter}>
                            <OverbookedIcon style={{ fontSize: '1.1rem', paddingRight: 4 }} /> SURCHARGER
                          </Box>
                        }
                        variant='outlined'
                        sx={{
                          backgroundColor: form.overbooked?.value ? lighten(theme.palette.error.light, 0.2) : 'inherit',
                        }}
                        className={form.overbooked?.value ? 'overbooked-button' : ''}
                        onClick={(e) => handleChange('overbooked', !form.overbooked?.value)}
                      />
                    </Tooltip>
                  )}
                </Box>
              )}
            </DialogActions>
          )}
        </Box>

        {openConfirmDelete && (
          <ConfirmationDialog
            open={openConfirmDelete}
            onClose={handleCloseConfirmDelete}
            onConfirm={handleConfirmDelete}
            message={
              event?.type === EventType.UNAVAILABILITY
                ? 'Êtes-vous sûr de vouloir supprimer cette indisponibilité ?'
                : event?.state === EventState.DONE || event?.state === EventState.CANCELLED
                  ? 'Êtes-vous sûr de vouloir supprimer ce rendez-vous de votre agenda ?'
                  : event?.state === EventState.NEW && localEvent.origin === 'MOVALIB'
                    ? 'Êtes-vous sûr de vouloir refuser ce rendez-vous ?'
                    : 'Êtes-vous sûr de vouloir annuler ce rendez-vous ?'
            }
          />
        )}

        {openNotifyDelete && (
          <ConfirmationDialog
            title='Prévenir le client'
            open={openNotifyDelete}
            onClose={() => handleConfirmDeleteNotify(false)}
            onConfirm={() => handleConfirmDeleteNotify(true)}
            message={"Voulez-vous prévenir le client de l'annulation du rendez-vous par mail ?"}
          />
        )}

        {openUploadInvoice && (
          <UploadDialog
            open={openUploadInvoice}
            //onClose={handleCloseUploadInvoice}
            onConfirm={handleConfirmUploadInvoice}
            onCancel={handleCancelUploadInvoice}
            title={'Clôture du rendez-vous'}
            yesLabel={<>JOINDRE&nbsp;LA&nbsp;FACTURE</>}
            //noLabel={<>CLÔTURER&nbsp;<b>SANS</b>&nbsp;FACTURE</>}
            message={
              <>
                <Grid container style={flexCenter}>
                  <Typography variant='h6' sx={{ mt: 2 }} style={flexCenter}>
                    <DoneIcon style={{ fontSize: 34, marginRight: 14, color: theme.palette.primary.main }} />
                    Vous pouvez prévenir le client par SMS que son véhicule est prêt si besoin 😉 !
                  </Typography>
                  <Tooltip
                    title={
                      isInvalidMobileNumber(localEvent.customer?.phoneNumber) &&
                      isInvalidMobileNumber(localEvent.customer?.phoneNumber)
                        ? 'Numéro de téléphone invalide'
                        : ''
                    }
                    placement='top'
                  >
                    <span style={{ ...flexCenter, width: '100%' }}>
                      <Button
                        component='span'
                        color='primary'
                        variant='outlined'
                        sx={{ mr: 2, mt: 3, width: '50%', color: theme.palette.primary.dark }}
                        onClick={handleSendVehicleAvailable}
                        disabled={
                          localEvent.vehicleAvailableNotified ||
                          (isInvalidMobileNumber(localEvent.customer?.phoneNumber) &&
                            isInvalidMobileNumber(localEvent.customer?.phoneNumber))
                        }
                      >
                        {!localEvent.vehicleAvailableNotified ? <b>Envoyer SMS fin de rdv</b> : <b>SMS envoyé</b>}
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
                <Alert style={flexCenter} severity='success' sx={{ mt: 4 }}>
                  <Typography variant='body1' style={{ textAlign: 'center' }}>
                    Merci de&nbsp;<b>joindre la facture</b>&nbsp; pour clôturer le rendez-vous, <br />
                    elle sera automatiquement transmise au client.
                  </Typography>
                </Alert>
              </>
            }
          />
        )}

        {openSupplierRequest && (
          <ConfirmationDialog
            confirmLabel={supplierRequestType === SupplierRequestType.ORDER ? 'OUI' : 'TÉLÉCHARGER'}
            closeLabel={supplierRequestType === SupplierRequestType.ORDER ? 'NON' : 'ANNULER'}
            title={
              <>
                {supplierRequestType === SupplierRequestType.ORDER && (
                  <>
                    <SupplierOrderRequestIcon sx={{ mr: 1 }} /> ENVOI DU{' '}
                  </>
                )}
                {supplierRequestType === SupplierRequestType.ORDER_PDF && (
                  <>
                    <SupplierOrderDownloadRequestIcon sx={{ mr: 1 }} /> TÉLÉCHARGEMENT DU{' '}
                  </>
                )}
                BON DE <b>&nbsp;&nbsp;COMMANDE</b>
              </>
            }
            open={openSupplierRequest}
            onClose={handleCloseSupplierRequest}
            onConfirm={handleConfirmSupplierRequest}
            message={
              <>
                <Grid container sx={{ textAlign: 'left' }}>
                  {supplierRequestType === SupplierRequestType.ORDER && (
                    <>
                      <Grid item xs={5}>
                        <Typography variant='subtitle1'>Destinataire :</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant='subtitle1'>{getSupplierLabel(form.supplier?.value)}</Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={5}>
                    <Typography variant='subtitle1' sx={{ py: 1 }}>
                      N° client :
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant='subtitle1' sx={{ py: 1 }}>
                      {getSupplierCustomerId(form.supplier?.value)}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <Typography variant='subtitle1'>Gamme souhaitée :</Typography>
                  </Grid>
                  <Grid item xs={7} sx={{ mt: 1 }}>
                    <FormControl fullWidth size='small' variant='outlined'>
                      <Select
                        displayEmpty
                        size='small'
                        labelId='orderPreference-select-label'
                        id='orderPreference-select'
                        value={form.orderPreference?.value}
                        onChange={(newValue) => handleChange('orderPreference', newValue.target.value)}
                      >
                        <MenuItem disabled value=''>
                          <Typography sx={{ fontSize: '12px' }}>
                            <em>Gamme ... </em>
                          </Typography>
                        </MenuItem>
                        <MenuItem value={OrderPreference.LOW_RANGE}>
                          <Typography variant='body2'>Low cost</Typography>
                        </MenuItem>
                        <MenuItem value={OrderPreference.MID_RANGE}>
                          <Typography variant='body2'>Intermédiaire</Typography>
                        </MenuItem>
                        <MenuItem value={OrderPreference.HIGH_RANGE}>
                          <Typography variant='body2'>Haut de gamme</Typography>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5} sx={{ pt: 2 }}>
                    <Typography variant='subtitle1'>Produits demandés :</Typography>
                  </Grid>
                  <Grid item xs={7} sx={{ pt: 2 }}>
                    {(form.checkedProducts?.value as string[]).length > 0 ||
                    (form.others?.value as string[]).length > 0 ? (
                      <>
                        {(form.checkedProducts?.value as string[]).map((productId: string, index: number) => (
                          <Typography variant='body1' key={index}>
                            <b>
                              {getProductLabel(productId)}{' '}
                              {getApplicationShortLabel(getProduct(productId)?.application)}
                            </b>
                          </Typography>
                        ))}
                        {(form.others?.value as string[]).map((product: string, index: number) => (
                          <Typography variant='body1' key={index + 100}>
                            <b>{product}</b>
                          </Typography>
                        ))}
                      </>
                    ) : (
                      <Typography variant='body1' key={999}>
                        Aucun produit sélectionné. <br /> <b>Inscrivez votre liste ci-dessous</b>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <br />
                <TextField
                  id='supplier-comment'
                  label='Commentaire fournisseur ...'
                  value={form.comment?.value}
                  onChange={(e) => handleChange('comment', e.target.value, true)}
                  multiline
                  required={(form.checkedProducts?.value as string[]).length === 0}
                  rows={2}
                  variant='outlined'
                  error={Boolean(form.comment?.error)}
                  helperText={form.comment?.error}
                  fullWidth
                  sx={{ mb: 3 }}
                />

                <Alert
                  sx={{ textAlign: 'center', fontSize: '1rem' }}
                  style={flexCenter}
                  className='styled-alert'
                  icon={<img src={DeployedCodeHistory} alt='DeployedCodeHistory' />}
                >
                  <Typography>
                    {supplierRequestType === SupplierRequestType.ORDER ? (
                      <>
                        Confirmez-vous la transmission de cette <b>commande</b> ?
                      </>
                    ) : (
                      <>Validez pour lancer le téléchargement du PDF.</>
                    )}
                  </Typography>
                </Alert>
              </>
            }
          />
        )}
        <Box
          ref={targetRef}
          style={{
            position: 'absolute',
            top: '-10000px',
            left: '-10000px',
          }}
        >
          <PurchaseOrder param={dataPurchaseOrder} />
        </Box>

        {openOrdered && (
          <ConfirmationDialog
            title={
              <>
                <FactCheckIcon sx={{ mr: 1 }} />
                COMMANDE <b>&nbsp;&nbsp;PASSÉE</b>
              </>
            }
            open={openOrdered}
            onClose={handleCloseOrdered}
            onConfirm={handleConfirmOrdered}
            message={
              <>
                <Grid container sx={{ textAlign: 'left' }}>
                  <Grid item xs={5} sx={{ pt: 2 }}>
                    <Typography variant='subtitle1'>Produits commandés :</Typography>
                  </Grid>
                  <Grid item xs={7} sx={{ pt: 2 }}>
                    {(form.checkedProducts?.value as string[]).length > 0 ||
                    (form.others?.value as string[]).length > 0 ? (
                      <>
                        {(form.checkedProducts?.value as string[]).map((productId: string, index: number) => (
                          <Typography variant='body1' key={index}>
                            <b>
                              {getProductLabel(productId)}{' '}
                              {getApplicationShortLabel(getProduct(productId)?.application)}
                            </b>
                          </Typography>
                        ))}
                        {(form.others?.value as string[]).map((product: string, index: number) => (
                          <Typography variant='body1' key={index + 100}>
                            <b>{product}</b>
                          </Typography>
                        ))}
                      </>
                    ) : (
                      <Typography variant='body1' key={999}>
                        Aucun produit sélectionné. <br /> <b>Inscrivez votre liste ci-dessous</b>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <br />
                <TextField
                  id='supplier-comment'
                  label='Notes commande ...'
                  value={form.comment?.value}
                  onChange={(e) => handleChange('comment', e.target.value, true)}
                  multiline
                  required={
                    (form.checkedProducts?.value as string[]).length === 0 &&
                    (form.others?.value as string[]).length === 0
                  }
                  rows={2}
                  variant='outlined'
                  error={Boolean(form.comment?.error)}
                  helperText={form.comment?.error}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <Grid item xs={12}>
                  <Alert
                    sx={{ textAlign: 'center', fontSize: '1rem', mt: 1 }}
                    style={flexCenter}
                    className='styled-alert'
                    icon={<img src={DeployedCodeHistory} alt='DeployedCodeHistory' />}
                  >
                    <Typography>
                      Vous confirmez avoir <b>passé commande</b> pour ce rendez-vous ?
                    </Typography>
                  </Alert>

                  <Typography
                    variant='body2'
                    style={flexCenter}
                    sx={{ mt: 3, flexDirection: 'column' }}
                    color={theme.palette.text.disabled}
                  >
                    Après confirmation nous ajouterons l'indication :&nbsp;
                    <Box sx={{ pt: 1 }}>
                      <img src={DeployedCodeHistory} alt='DeployedCodeHistory' style={{ opacity: 0.5 }} />
                      <b>&nbsp;PIÈCE(S) COMMANDÉE(S)</b>
                    </Box>
                  </Typography>
                </Grid>
              </>
            }
          />
        )}
      </Dialog>

      {isPrestationsDialogOpenned && (
        <Dialog
          scroll='body'
          fullWidth
          sx={{ p: 0 }}
          maxWidth='md'
          onClose={(event, reason) => handleOnClose(reason)}
          open={isPrestationsDialogOpenned}
        >
          <EventPrestationListDialog
            onClose={handleOnClose}
            event={localEvent}
            garage={garage}
            onRefresh={onRefresh}
            availablePrestations={garage.prestations}
          />
        </Dialog>
      )}

      {openAddVehiclePlate && (
        <AddVehicleDialog
          open={openAddVehiclePlate}
          onClose={handleCloseAddVehicleDialog}
          onConfirm={handleConfirmVehicleDialog}
        />
      )}

      {openEditCustomer && selectedCustomer && selectedCustomer.type === CustomerType.INDIVIDUAL && (
        <CustomerDialog
          open={openEditCustomer}
          onClose={handleCloseEditCustomer}
          customer={selectedCustomer}
          garageId={garageId}
        />
      )}

      {openEditCustomer && selectedCustomer && selectedCustomer.type === CustomerType.PROFESSIONAL && (
        <BusinessCustomerDialog
          open={openEditCustomer}
          onClose={handleCloseEditCustomer}
          customer={selectedCustomer}
          garageId={garageId}
        />
      )}

      {openAddCustomer && (
        <AddCustomer
          open={openAddCustomer}
          onClose={handleCloseAddCustomer}
          garageId={garageId}
          initialName={initialLastName.current}
        />
      )}

      {openAddSupplier && localGarage && localGarage.id && (
        <AddOrEditSupplierDialog
          open={openAddSupplier}
          garageId={localGarage.id}
          onClose={handleCloseAddSupplier}
          supplier={supplierModified.current}
        />
      )}

      {openApplicationChoice && focusedPrestation && (
        <ConfirmationDialog
          autoFocus={false}
          open={openApplicationChoice}
          onClose={handleCancelApplicationChoice}
          showConfirm={focusedPrestation?.multipleApplication}
          confirmLabel='CONFIRMER'
          onConfirm={() => confirmApplicationsChoice(undefined)}
          closeLabel='Annuler'
          title={<>{focusedPrestation?.name} - Application</>}
          message={
            <StyledToggleButtonGroup
              orientation={isMobile ? 'vertical' : 'horizontal'}
              size='small'
              style={flexCenter}
              value={selectedApplications}
              //exclusive
              onChange={(e, value: PartsApplicationType[]) => {
                handleMultipleApplicationsChoice(value);
              }}
              aria-label='application-choice'
            >
              {(focusedPrestation?.operations || [])
                .filter(
                  (o, index, array) =>
                    o.application && array.findIndex((op) => op.application === o.application) === index,
                )
                .sort((a, b) => {
                  const labelA = getApplicationShortLabel(a.application);
                  const labelB = getApplicationShortLabel(b.application);
                  return labelB.localeCompare(labelA);
                })
                .map((o, index) => (
                  <ToggleButton
                    autoFocus={index === 0}
                    size='large'
                    style={flexCenter}
                    key={index + 100}
                    sx={{ width: 100 }}
                    value={o.application ?? ''}
                  >
                    {getApplicationShortLabel(o.application)}
                  </ToggleButton>
                ))}
            </StyledToggleButtonGroup>
          }
        />
      )}
      {isShowConfirmUploadDocument && (
        <UploadDocumentDialog
          refreshEvent={refreshEvent}
          currentUpload={currentUpload}
          setCurrentUpload={setCurrentUpload}
          handleOnVehicleUploadDocument={handleOnVehicleUploadDocument}
          localEvent={localEvent}
          isShowConfirmUploadDocument={isShowConfirmUploadDocument}
          toggleShowConfirmUploadDocument={toggleShowConfirmUploadDocument}
        />
      )}

      {isShowConfirmDownloadRepairOrder && (
        <ConfirmationDialog
          open={isShowConfirmDownloadRepairOrder}
          onClose={onCloseConfirmDownloadRepairOrder}
          closeLabel='Annuler'
          title="Télécharger l'ordre de réparation"
          message={
            <Grid container sx={{ justifyContent: 'center' }}>
              <Grid xs={12} sx={{ mb: 2 }}>
                <Typography variant='subtitle1'>Vous êtes sur le point de télécharger l'ordre de réparation</Typography>
              </Grid>
              <Grid xs={9} sx={{ mb: 2 }}>
                <FormControl error={!localEvent.notes} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='medium'
                        disabled={!localEvent.notes}
                        checked={isInternalNoteInjected}
                        onChange={toggleInternalNoteInjectedProxy}
                      />
                    }
                    label='Inclure la note interne'
                    sx={{ m: 0 }}
                  />
                  {!localEvent.notes && (
                    <FormHelperText>
                      Veuillez saisir une note interne si vous souhaitez l'inclure dans l'OR
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          }
          confirmBtnComponent={
            <LoadingButton
              onClick={handleDownloadAndApiCall}
              rel='noopener noreferrer'
              loading={repairOrder.loading}
              variant='contained'
              color='primary'
              sx={{ ml: 1 }}
            >
              Télécharger
            </LoadingButton>
          }
        />
      )}

      {openCustomerReminder && (
        <ConfirmationDialog
          open={openCustomerReminder}
          onClose={handleCancelCustomerReminder}
          onConfirm={handleConfirmCustomerReminder}
          closeLabel='Annuler'
          title='Confirmation'
          confirmLabel='Envoyer la relance'
          message={
            <Grid container>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant='subtitle1'>
                  Quel <b>email</b> souhaitez-vous adresser au client ?
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                {localEvent.customer?.email ? (
                  <Typography variant='subtitle1' color={darken(theme.palette.primary.main, 0.2)}>
                    Email client disponible ! ({localEvent.customer?.email})
                  </Typography>
                ) : (
                  <Typography variant='subtitle1' color={darken(theme.palette.warning.main, 0.2)}>
                    Email client non disponible ...
                  </Typography>
                )}
              </Grid>
              <Grid xs={12}>
                <TextField
                  id='reminder-message'
                  label='Votre message ...'
                  value={form.comment?.value}
                  onChange={(e) => handleChange('comment', e.target.value, true)}
                  multiline
                  rows={2}
                  variant='outlined'
                  error={Boolean(form.comment?.error)}
                  helperText={form.comment?.error}
                  fullWidth
                  required
                  sx={{ mb: 3 }}
                />
              </Grid>
              <Grid xs={12}>
                {localEvent.customerReminders && localEvent.customerReminders > 0 ? (
                  <Alert color='warning' variant='filled' sx={{ textAlign: 'center', p: 0 }} style={flexCenter}>
                    <Typography variant='h6'>
                      Vous avez déjà relancé le client <b>{localEvent.customerReminders}</b> fois.
                    </Typography>
                  </Alert>
                ) : (
                  <Alert
                    color='info'
                    variant='outlined'
                    severity='info'
                    sx={{ textAlign: 'center', p: 0, borderColor: PALETTE_THIRD_COLOR_MAIN }}
                    style={flexCenter}
                  >
                    <Typography variant='h6'>Vous n'avez pas encore relancé le client.</Typography>
                  </Alert>
                )}
              </Grid>
            </Grid>
          }
        />
      )}

      {openDepositVehicleChangeDialog && (
        <ConfirmationDialog
          open={openDepositVehicleChangeDialog}
          onClose={handleCancelNotifyDepositVehicleChange}
          closeLabel='Annuler'
          title='Prévenir le client ?'
          confirmBtnComponent={
            <Button
              onClick={handleNotifyDepositVehicleChange}
              color='inherit'
              autoFocus
              sx={{ ml: 1 }}
              disabled={
                form.updateSchedulingAlert?.value === true &&
                isInvalidEmail(localEvent.customer?.email) &&
                isInvalidMobileNumber(localEvent?.customer?.phoneNumber) &&
                isInvalidMobileNumber(localEvent?.customer?.companyPhoneNumber)
              }
            >
              <b>{form.updateSchedulingAlert?.value === true ? 'Enregistrer et prévenir le client' : 'Enregistrer'}</b>
            </Button>
          }
          message={
            <Grid container>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant='subtitle1'>
                  Vous avez modifié{' '}
                  {garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD ? (
                    <b>l'heure de dépôt du véhicule</b>
                  ) : (
                    <b>l'heure du rendez-vous</b>
                  )}
                  , deux possibilités :
                </Typography>
              </Grid>
              {form.updateSchedulingAlert && (
                <Grid xs={12} sx={{ mb: 2 }}>
                  <ToggleButtonGroup
                    size='small'
                    orientation={isMobile ? 'vertical' : 'horizontal'}
                    value={form.updateSchedulingAlert.value}
                    exclusive
                    onChange={(e: any, value: null) => {
                      if (value !== null) handleChange('updateSchedulingAlert', value);
                    }}
                    aria-label='update-scheduling-alert'
                    sx={{ gap: 3 }}
                  >
                    <StyledToggleButton
                      size='small'
                      sx={{ width: '230px' }}
                      customSelectedBackgroundColor={theme.palette.warning.main}
                      customHoverColor={theme.palette.warning.light}
                      value={false}
                      selected={form.updateSchedulingAlert.value === false}
                      aria-label='update scheduling alert'
                    >
                      <b>Vous prévenez le client</b>
                    </StyledToggleButton>
                    <StyledToggleButton
                      size='small'
                      sx={{ width: '230px' }}
                      customSelectedBackgroundColor={theme.palette.primary.main}
                      customHoverColor={theme.palette.primary.light}
                      value={true}
                      selected={form.updateSchedulingAlert.value === true}
                      aria-label='update scheduling no alert'
                    >
                      <b>Nous prévenons le client</b>
                    </StyledToggleButton>
                  </ToggleButtonGroup>

                  {form.updateSchedulingAlert.value === false && (
                    <Alert
                      color='warning'
                      variant='outlined'
                      sx={{ textAlign: 'center', p: 0, mt: 1 }}
                      style={flexCenter}
                      className='styled-warning-alert'
                    >
                      {localEvent.start && form.start && (
                        <Typography variant='subtitle1'>
                          Le client est avec vous ou vous préférez lui passer un coup de téléphone. <br />{' '}
                          <div style={{ marginTop: '8px' }}>
                            {localEvent.customer?.type === CustomerType.INDIVIDUAL ? (
                              <>
                                {localEvent.customer?.firstname} {localEvent.customer?.lastname}
                              </>
                            ) : (
                              <>{localEvent.customer?.companyName}</>
                            )}
                            <br />
                            <b>
                              {localEvent?.customer?.companyPhoneNumber &&
                                formatPhoneNumber(localEvent?.customer?.companyPhoneNumber)}
                              {localEvent?.customer?.phoneNumber && localEvent?.customer?.companyPhoneNumber && ' / '}
                              {localEvent?.customer?.phoneNumber &&
                                formatPhoneNumber(localEvent?.customer?.phoneNumber)}
                            </b>
                          </div>
                        </Typography>
                      )}
                    </Alert>
                  )}

                  {form.updateSchedulingAlert.value === true && (
                    <Alert color='success' variant='outlined' sx={{ textAlign: 'center', borderRadius: '20px', mt: 1 }}>
                      {!(
                        isInvalidMobileNumber(localEvent?.customer?.companyPhoneNumber) &&
                        isInvalidMobileNumber(localEvent?.customer?.phoneNumber)
                      ) || !isInvalidEmail(localEvent?.customer?.email) ? (
                        <Typography variant='subtitle1'>
                          {displayMessageOnModifyScheduleDialog()} pour le prévenir du nouvel horaire de rendez-vous :
                          &nbsp;&nbsp;
                          <b>
                            {formatDateByCountryCode(
                              garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD
                                ? (movedDepositDate.current ?? form.vehicleDepositDate?.value)
                                : form.start?.value,
                              'FR',
                              DateFormatTypes.LONG_FORMAT_DATETIME,
                            )}
                          </b>
                        </Typography>
                      ) : (
                        <Typography variant='subtitle1'>
                          Le client n'a pas de numéro de téléphone ou d'email valide pour être prévenu.
                        </Typography>
                      )}
                      {!(
                        isInvalidMobileNumber(localEvent?.customer?.companyPhoneNumber) &&
                        isInvalidMobileNumber(localEvent?.customer?.phoneNumber)
                      ) && (
                        <>
                          <TextField
                            id='update-scheduling-customer-message'
                            label='Contenu du message ...'
                            value={form.updateSchedulingCustomerMessage?.value}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (newValue.length <= 160) {
                                handleChange('updateSchedulingCustomerMessage', newValue, true);
                              }
                            }}
                            multiline
                            rows={3}
                            autoFocus
                            variant='outlined'
                            error={Boolean(form.updateSchedulingCustomerMessage?.error)}
                            helperText={form.updateSchedulingCustomerMessage?.error}
                            fullWidth
                            sx={{ mt: 2 }}
                            inputProps={{ maxLength: 160 }}
                          />
                          <FormHelperText>
                            Votre client ne peux pas répondre à ce SMS (sens unique).
                            <br />
                            <b>{160 - form.updateSchedulingCustomerMessage?.value?.length}</b> caractères restants
                          </FormHelperText>
                        </>
                      )}
                    </Alert>
                  )}
                </Grid>
              )}
            </Grid>
          }
        />
      )}

      {openSubmitEventConfirmDialog && (
        <ConfirmationDialog
          open={openSubmitEventConfirmDialog}
          onClose={handleCancelConfirmSubmitEvent}
          closeLabel='Annuler'
          onConfirm={handleConfirmSubmitEvent}
          confirmLabel='Confirmer'
          title='Prévenir le client'
          message={
            <Grid container sx={{ justifyContent: 'center' }}>
              <Grid xs={12} sx={{ mb: 3 }}>
                <Typography variant='h6' sx={{ fontSize: '1.1rem' }}>
                  Souhaitez-vous prévenir{' '}
                  <b>
                    {(form.customer?.value as Customer)?.companyName} {(form.customer?.value as Customer)?.firstname}{' '}
                    {(form.customer?.value as Customer)?.lastname}
                  </b>{' '}
                  ?
                </Typography>
              </Grid>
              <Grid xs={12} sx={{ mb: 2 }}>
                {form.submitEventAlert && (
                  <ToggleButtonGroup
                    orientation={isMobile ? 'vertical' : 'horizontal'}
                    size='small'
                    value={form.submitEventAlert.value}
                    exclusive
                    aria-label='submit-event-alert'
                    sx={{ gap: 3 }}
                  >
                    <StyledToggleButton
                      size='small'
                      sx={{ width: '120px' }}
                      customSelectedBackgroundColor={theme.palette.grey[300]}
                      customHoverColor={theme.palette.grey[300]}
                      value={NotificationType.NONE}
                      onClick={() => handleSendMethod(NotificationType.NONE)}
                      selected={form.submitEventAlert.value.includes(NotificationType.NONE)}
                      aria-label='submit event NO alert'
                    >
                      <b>Non</b>
                    </StyledToggleButton>

                    {isInvalidMobileNumber(form.customer?.value?.companyPhoneNumber) &&
                    isInvalidMobileNumber(form.customer?.value?.phoneNumber) ? (
                      <Tooltip title='Le format du numéro enregistré ne permet pas l’envoi de SMS'>
                        <span>{displayBtnSendBySms(true, form)}</span>
                      </Tooltip>
                    ) : (
                      <>{displayBtnSendBySms(false, form)}</>
                    )}

                    {isInvalidEmail(form.customer?.value?.email) ? (
                      <Tooltip title='Email non renseigné ou invalide, envoi email impossible'>
                        <span>{displayBtnSendByEmail(true, form)}</span>
                      </Tooltip>
                    ) : (
                      <>{displayBtnSendByEmail(false, form)}</>
                    )}
                  </ToggleButtonGroup>
                )}
              </Grid>

              <Grid xs={12} sx={{ mb: 3 }}>
                <Typography variant='h6' sx={{ fontSize: '1.1rem' }}>
                  <i
                    className='fas fa-solid fa-car-on'
                    style={{ color: theme.palette.grey[500], fontSize: '1.4rem', marginRight: '8px' }}
                  />
                  Avez-vous réceptionné le véhicule ?
                </Typography>
              </Grid>

              <Grid xs={12} sx={{ mb: 2 }}>
                {form.isVehicleReceived && (
                  <ToggleButtonGroup
                    orientation={isMobile ? 'vertical' : 'horizontal'}
                    size='small'
                    value={form.isVehicleReceived.value}
                    exclusive={true}
                    aria-label='submit-isVehicleReceived-alert'
                    sx={{ gap: 3 }}
                  >
                    <StyledToggleButton
                      size='small'
                      sx={{ width: '120px' }}
                      customSelectedBackgroundColor={theme.palette.grey[300]}
                      customHoverColor={theme.palette.grey[300]}
                      value={false}
                      onClick={() => {
                        handleChange('isVehicleReceived', false);
                      }}
                      selected={form.isVehicleReceived.value === false}
                      aria-label='submit isVehicleReceived NO alert'
                    >
                      <b>Non</b>
                    </StyledToggleButton>
                    {displayBtnIsVehicleReceived(false, form)}
                  </ToggleButtonGroup>
                )}
              </Grid>
            </Grid>
          }
        />
      )}
      {openConflictLoanVehicleDialog && (
        <ConflictLoanVehicleDialog open={openConflictLoanVehicleDialog} onClose={oncloseConflictLoanVehicleDialog} />
      )}

      {isConfirmAcceptQuoteOpened && (
        <ConfirmationDialog
          open
          showConfirm
          onClose={toggleConfirmAcceptQuoteOpened}
          title="Forcer l'acceptation du devis"
          message='Vous confirmez l’acceptation du devis par le client ?'
          confirmLabel='Confirmer'
          closeLabel='Annuler'
          onConfirm={handleAcceptQuote}
        />
      )}
    </>
  );
};

export default MyCalendarEventDialog;
