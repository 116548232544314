import { AddRounded, ChevronLeft, ChevronRight, EventNote, MoreTime, SearchRounded, Visibility } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { type FunctionComponent, useCallback, useEffect, useState } from 'react';
import type { ToolbarProps } from 'react-big-calendar';
import './MyCalendar.css';
import { Event, type Garage } from '@movalib/movalib-commons';
import { LoadingButton } from '@mui/lab';
import { CalendarHolidayDialog } from '../../dialogs/calendar/CalendarHolidayDialog';
import { CalendarToolbarSearchDialog } from '../../dialogs/calendar/CalendarToolbarSearchDialog';
import { DEFAULT_EVENT_STATE, DEFAULT_EVENT_TYPE } from '../../helpers/Constants';
import { findEarliestScheduleTime } from '../../helpers/DateUtils';
import { PeriodType } from '../../helpers/Enums';
import {
  createCookie,
  formatLocalDateToISOString,
  getEndOf,
  getStartOf,
  isDemoEnv,
  isEnvProd,
  readCookie,
  updateServiceWorker,
} from '../../helpers/Tools';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import { useGenerateEvents } from '../../query/event/EventQuery';
import theme from '../../theme';
import { MyEventDialog } from './MyCalendarTypes';
import MenuIcon from '@mui/icons-material/Menu';
import { useSwipeNavigation } from './HammerEvent';
import FilterListIcon from '@mui/icons-material/FilterList';
type MyCalendarToolBarProps = {
  garage?: Garage;
  garageId: string;
  selectedEvent?: Event;
  onSelectEvent: (event: Event, dialogType: MyEventDialog) => void;
  onRefresh: () => void;
  calendarRef: any;
};

type CombinedProps = MyCalendarToolBarProps & ToolbarProps<Event, object>;
export enum FilterCalendar {
  SEE_FULL_WEEK = 'seeFullWeek',
  HIDE_CANCELLED_EVENTS = 'hideCancelledEvents',
}

const MyCalendarToolBar: FunctionComponent<CombinedProps> = ({
  view,
  views,
  label,
  localizer: { messages },
  onNavigate,
  onView,
  garage,
  garageId,
  onSelectEvent,
  onRefresh,
  date,
}) => {
  const { isSearchOpen, toggleSearchOpen } = useBoolState(false, 'searchOpen');
  const { isHolidayPopupOpen, toggleHolidayPopupOpen } = useBoolState(false, 'holidayPopupOpen');
  const { mutate: generateEvents, isPending } = useGenerateEvents();

  // Ordre souhaité des clés de vue
  const viewOrder = ['work_week', 'day', 'agenda', 'week', 'month'];
  const [seeFullWeek, setSeeFullWeek] = useState(readCookie(FilterCalendar.SEE_FULL_WEEK) === 'true');
  const [hideCancelledEvents, setHideCancelledEvents] = useState(readCookie(FilterCalendar.HIDE_CANCELLED_EVENTS) === 'true');
  const handleRefreshEvents = () => {
    // On force la mise à jour du serviceWoker
    if (updateServiceWorker)
    {
      updateServiceWorker();
    }
    // On appelle le callback de refresh si présent
    if (onRefresh)
    {
      onRefresh();
    }
  };
  useSwipeNavigation(onNavigate);

  const handleViewChange = (event: SelectChangeEvent<typeof view>) => {
    const selectedView = event.target.value as typeof view;
    onView(selectedView);
  };

  const handleCreateEvent = () => {
    const tmpStartDate = new Date();
    tmpStartDate.setHours(tmpStartDate.getHours(), 0, 0, 0);
    const tmpEndDate = new Date(tmpStartDate);
    tmpEndDate.setHours(tmpStartDate.getHours() + 1, 0, 0, 0);

    // On crée "une coquille vide"
    const newEvent = new Event(
      '',
      1,
      DEFAULT_EVENT_TYPE,
      '',
      '',
      Number(garageId),
      '',
      DEFAULT_EVENT_STATE,
      undefined,
      tmpStartDate,
      tmpEndDate,
    );

    onSelectEvent(newEvent, MyEventDialog.CREATE);
  };

  const matchesMediaQuery = useMediaQuery('(max-width:1300px)');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const open = Boolean(anchorElFilter);
  const handleClick = (event: any) => {
    setAnchorElFilter(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElFilter(null);
  };

  const toggleSeeFullWeek = () => {
    createCookie(FilterCalendar.SEE_FULL_WEEK, !seeFullWeek ? 'true' : 'false');
    setSeeFullWeek(!seeFullWeek);
    onRefresh();
  }
  const toggleHideCancelledEvents = () => {
    createCookie(FilterCalendar.HIDE_CANCELLED_EVENTS, !hideCancelledEvents ? 'true' : 'false');
    setHideCancelledEvents(!hideCancelledEvents);
    onRefresh();
  }


  const onGenerateEvents = useCallback(() => {
    const [startHour = '00', startMin = '00'] = findEarliestScheduleTime(garage?.schedules || [], 'startTime').split(
      ':',
    );
    const [endHour = '00', endMin = '00'] = findEarliestScheduleTime(garage?.schedules || [], 'endTime').split(':');
    const startDate = getStartOf(date, PeriodType.WEEK);
    const endDate = getEndOf(date, PeriodType.WEEK);

    startDate.setHours(Number(startHour), Number(startMin));
    endDate.setHours(Number(endHour), Number(endMin));

    generateEvents({
      garageId,
      startDate: formatLocalDateToISOString(startDate),
      endDate: formatLocalDateToISOString(endDate),
    });
  }, [date, garage?.schedules, garageId, generateEvents]);

  const getMenuItemStyles = (isSelected: boolean) => {

    return {
      backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.common.white,
      '&:hover': {
        backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.common.white, // Désactive le changement de couleur au survol
      },
    }
  }

  return (
    <div>
      <Toolbar
        disableGutters
        variant='dense'
        sx={{
          justifyContent: 'flex-start',
          color: '#3C4043',
          borderColor: 'transparent',
          my: 1,
          pl: 1,
        }}
      >
        <Grid container sx={{ flexGrow: 1, display: 'contents' }}>
          <Grid>
            {!matchesMediaQuery && (
              <Button
                variant='contained'
                startIcon={<AddRounded />}
                color='inherit'
                onClick={handleCreateEvent}
                sx={{
                  pr: 2,
                  mr: 4,
                  textTransform: 'none',
                  backgroundColor: 'white',
                }}
              >
                Créer
              </Button>
            )}
            <Button
              size='small'
              variant='outlined'
              color='inherit'
              aria-label='navigate today'
              sx={{
                mr: 1,
                textTransform: 'none',
                fontSize: 14,
                borderColor: '#dadce0',
              }}
              onClick={() => onNavigate('TODAY')}
            >
              <span>Aujourd'hui</span>
            </Button>
            { !matchesMediaQuery && <IconButton
              size='small'
              sx={{ mr: 2, display: { xs: 'inline-flex', md: 'none' } }}
              onClick={() => {
                handleCreateEvent();
              }}
            >
              <AddRounded />{' '}
            </IconButton> }
            <IconButton
              size='small'
              sx={{ display: { xs: 'none', md: 'inline-flex' } }}
              onClick={() => onNavigate('PREV')}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              size='small'
              sx={{ display: { xs: 'none', md: 'inline-flex' } }}
              onClick={() => onNavigate('NEXT')}
            >
              <ChevronRight />
            </IconButton>
          </Grid>
          <Grid sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
            <Typography
              variant='h6'
              sx={{
                display: 'inline',
                justifyContent: 'center',
                flexGrow: 1,
                pl: 2,
              }}
            >
              {label}
            </Typography>
          </Grid>
          <Grid>
            {!matchesMediaQuery && (
              <>

                {(!isEnvProd() || isDemoEnv()) && view === 'week' && (
                  <LoadingButton
                    size='small'
                    variant='contained'
                    startIcon={<EventNote />}
                    color='inherit'
                    onClick={onGenerateEvents}
                    sx={{ mx: 1, backgroundColor: theme.palette.common.white }}
                    loading={isPending}
                  >
                    Créer une semaine type
                  </LoadingButton>
                )}
                <Button
                  size='small'
                  variant='contained'
                  startIcon={<MoreTime />}
                  color='inherit'
                  onClick={toggleHolidayPopupOpen}
                  sx={{ mx: 1, backgroundColor: theme.palette.common.white }}
                >
                  Congés
                </Button>
                <Button
                  size='small'
                  sx={{ mx: 1, fontSize: 14, borderColor: '#dadce0' }}
                  variant='outlined'
                  onClick={toggleSearchOpen}
                  color='inherit'
                >
                  <SearchRounded style={{ fontSize: 20 }} />
                  &nbsp;Recherche RDV
                </Button>
              </>
            )}

            {matchesMediaQuery && (
              <>
                <Button
                  size='small'
                  variant='contained'
                  color='inherit'
                  sx={{ backgroundColor: 'transparent', mx: 1 }}
                  aria-controls={openMenu ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={handleClickMenu}
                >
                  <MenuIcon />
                </Button>

                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCreateEvent();
                      handleCloseMenu();
                    }}
                  >
                    <ListItemIcon>
                      <AddRounded />
                    </ListItemIcon>
                    <ListItemText>Creer RDV</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={onGenerateEvents}>
                    <ListItemIcon>
                      <EventNote />
                    </ListItemIcon>
                    <ListItemText>Créer une semaine type</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      toggleHolidayPopupOpen();
                      handleCloseMenu();
                    }}
                  >
                    <ListItemIcon>
                      <MoreTime />
                    </ListItemIcon>
                    <ListItemText>Congés</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      toggleSearchOpen();
                      handleCloseMenu();
                    }}
                  >
                    <ListItemIcon>
                      <SearchRounded />
                    </ListItemIcon>
                    <ListItemText>Recherche RDV</ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Grid>

          <>
            <Button
              size='small'
              variant='contained'
              color='inherit'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              startIcon={ !matchesMediaQuery && <FilterListIcon />}
              onClick={handleClick}
              sx={{mx: 1, backgroundColor: (view === 'week' && seeFullWeek) || hideCancelledEvents ? theme.palette.primary.main : theme.palette.common.white }}
            >
               {!matchesMediaQuery && 'Filtres' }
               {matchesMediaQuery && <FilterListIcon /> }
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElFilter}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                sx: { backgroundColor: 'white' }
              }}
            >
              {view === 'week' && <MenuItem
                sx={getMenuItemStyles(seeFullWeek)}
                onClick={() => toggleSeeFullWeek()}>
                Voir la semaine complète
              </MenuItem>}
              <MenuItem
                sx={getMenuItemStyles(hideCancelledEvents)}
                onClick={() => toggleHideCancelledEvents()}>Cacher les RDV annulés</MenuItem>
            </Menu>
          </>

          {!matchesMediaQuery && <Tooltip title='Actualiser'>
            <IconButton sx={{ mx: 1 }} onClick={handleRefreshEvents}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>}
          <FormControl size='small' sx={{  ml: 'auto', display: { sm: 'block' }, float: { sm: 'right' } }}>
            <Select
              value={view}
              onChange={handleViewChange}
              sx={{
                mx: 1,
                fontSize: 14,
                borderRadius: '25px'
              }}
            >
              {viewOrder
                .filter(
                  (key) =>
                    !(
                      key === 'work_week' &&
                      (!(garage?.teamManagementActive && garage?.employees) || garage?.employees?.length === 0)
                    ),
                )
                .map((viewKey) => {
                  const viewName = messages[viewKey as keyof typeof views];
                  return (
                    <MenuItem key={viewKey} value={viewKey}>
                      {viewName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Toolbar>

      {/** Dialog pour créer une période d'abscence */}
      <CalendarHolidayDialog
        garageId={garageId}
        isDialogOpen={isHolidayPopupOpen}
        onCloseDialog={toggleHolidayPopupOpen}
        refreshEvents={handleRefreshEvents}
      />

      {/** Dialog pour rechercher un rendez-vous */}
      <CalendarToolbarSearchDialog
        garageId={garageId}
        isSearchOpen={isSearchOpen}
        toggleSearchOpen={toggleSearchOpen}
        onSelectEvent={onSelectEvent}
      />
    </div>
  );
};

export default MyCalendarToolBar;
